<div class="vz-formplace">
	<form [formGroup]="fg" (ngSubmit)="logIn()" class="vz-center-container">
		<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl"><span translate>Zaloguj się do swojego profilu</span></div>
		<div class="w-full flex flex-col bg-gray-200 p-3">
			<div class="w-full py-3" *ngIf="sposobLogowania == 'email'">
				<label for="email"><span translate>Podaj swój adres email</span></label>
				<input id="email" class="vz-input-lg" type="email" pInputText formControlName="email" required />
			</div>

			<div class="w-full py-3" *ngIf="sposobLogowania == 'phoneNumber'">
				<label for="phoneNumber" translate>wprowadź swój numer tlefonu</label>
				<div class="flex items-center">
					<p-dropdown class="flex-none" [options]="countryCodes" formControlName="countryCode" optionLabel="label" optionValue="value"></p-dropdown>
					<span class="flex-none ml-2">
						{{fg.get('countryCode').value}}
					</span>
					<input id="phoneNumber" class="vz-input-lg flex-grow ml-2" type="phoneNumber" pInputText formControlName="phoneNumber" required />
				</div>
			</div>
			
			<div class="w-full py-3">
				<label for="password"><span translate>Hasło</span></label>
				<input id="password" class="vz-input-lg" type="password" pInputText formControlName="password" required />
			</div>
			<div class="w-full py-3">
				<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" type="submit"><span translate>Zaloguj</span></button>
			</div>
		</div>
	</form>
</div>