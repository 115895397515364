<h1 class="headerArticleClass" translate>Lista obecnie złożonych wniosków</h1>
<div class="dividerArticleClass"></div>
<app-info slug="lista-wnioskow"></app-info>
<button type="button" [routerLink]="['/console/checker']" class="inline-block px-12 py-5 mt-6 border-2 border-blue-600 text-blue-600 font-large text-xl leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out" translate>Złóż nowy wniosek</button>



<div *ngIf="reports" class="overflow-hidden rounded-lg border border-gray-200 shadow-md my-8">
	<table class="w-full border-collapse bg-white text-left text-sm text-gray-500">
		<thead class="bg-gray-50">

			<tr>
				<th scope="col" class="px-6 py-4 font-medium text-gray-900" translate>Wniosek</th>
				<th scope="col" class="px-6 py-4 font-medium text-gray-900" translate>Status</th>
				<th scope="col" class="px-6 py-4 font-medium text-gray-900" ></th>
				<th scope="col" class="px-6 py-4 font-medium text-gray-900" translate>Edytuj</th>
			</tr>
		</thead>
		<tbody class="divide-y divide-gray-100 border-t border-gray-100">
			<ng-container *ngFor="let r of reports; let i = index">
				<tr class="hover:bg-gray-50">
					<th class="flex gap-3 px-6 py-4 font-normal text-gray-900">
						<div class="text-sm">
							<div class="font-medium text-gray-700"><span translate>Wniosek</span>: <span>{{ r.label | translate }}</span></div>
							<div class="text-gray-400"><span translate>Złożony</span> {{ r.dateInsert | date:'yyyy-MM-dd'}}</div>
						</div>
					</th>
					<td class="px-6 py-4">
						<span class="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
							<span class="h-1.5 w-1.5 rounded-full bg-green-600"></span>
							{{ r.status }}
						</span>
					</td>
					<td class="px-6 py-4 mx-auto text-center"></td>
					<td class="px-6 py-4">
						<div class="flex justify-end gap-4">
							<a x-data="{ tooltip: 'Edite' }" (click)="edit(r)" class="hover:shadow-xl hover:outline hover:outline-2 cursor-pointer">
								<svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
									<path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
								</svg>
							</a>
						</div>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>