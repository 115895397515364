<ng-container *ngIf="wariant == 'prosty'">
	<h1 *ngIf="contentArt?.data[0]?.attributes?.tytul" [ngClass]="headerClass">{{ contentArt.data[0].attributes.tytul }}</h1>
	<div [ngClass]="dividerClass"></div>
	<img style="width: 200px;" *ngIf="contentArt?.data[0]?.attributes?.grafika_tytulowa?.data?.attributes?.url" [src]="this.strapiUrl + contentArt.data[0].attributes.grafika_tytulowa.data.attributes.url" class="img-fluid" [alt]="contentArt?.data[0]?.attributes?.grafika_tytulowa?.data?.attributes.alternativeText" />
	<div [ngClass]="textClass" *ngIf="contentArt?.data[0]?.attributes?.tresc" calss="bold pb-3 text-left text-2xl text-blue-300" [innerHtml]="contentArt?.data[0]?.attributes?.tresc"></div>
</ng-container>





<ng-container *ngIf="wariant == 'elegancki'">
	<div class="container my-24 px-6 mx-auto">
		<section class="mb-32 text-gray-800">
			<img class="w-full md:w-1/2 object-cover object-top rounded-lg mb-6 h-[400px] overflow: hidden;" *ngIf="contentArt?.data[0]?.attributes?.grafika_tytulowa?.data?.attributes?.url" [src]="this.strapiUrl + contentArt.data[0].attributes.grafika_tytulowa.data.attributes.url" [alt]="contentArt?.data[0]?.attributes?.grafika_tytulowa?.data?.attributes.alternativeText" />
			<h1 *ngIf="contentArt?.data[0]?.attributes?.tytul" [ngClass]="headerClass">{{ contentArt.data[0].attributes.tytul }}</h1>
			<div [ngClass]="dividerClass"></div>
			<div [ngClass]="textClass" *ngIf="contentArt?.data[0]?.attributes?.tresc" calss="bold pb-3 text-left text-2xl text-blue-300" [innerHtml]="contentArt?.data[0]?.attributes?.tresc"></div>
		</section>
	</div>
</ng-container>






<ng-container *ngIf="wariant == 'INTRO'">

	<div class="w-full px-6 mx-auto">

		<section class="mb-12 text-gray-800">
			<div class="container mx-auto text-center lg:text-left">
				<div class="grid lg:grid-cols-2 flex items-top">
					<div class="mb-12 lg:mb-0">
						<div class="block min-h-[90vh] rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14" style="background: hsla(0, 0%, 100%, 0.55); backdrop-filter: blur(30px)">
							<h2 *ngIf="contentArt?.data[0]?.attributes?.tytul" class="text-3xl font-bold mb-6">{{ contentArt.data[0].attributes.tytul }}</h2>

							<div [ngClass]="textClass" *ngIf="contentArt?.data[0]?.attributes?.tresc" calss="bold pb-3 text-left text-2xl text-blue-300" [innerHtml]="contentArt?.data[0]?.attributes?.tresc"></div>

						</div>
					</div>

					<div>
						<button (click)="gotoumowa()" style="width: calc(100% - 4em) !important; margin-left: 4em !important;" class="p-element ml-auto p-button p-component mb-2" pButton [label]="przejdzDalej" icon="pi pi-angle-double-right"></button>
						<img style="width: 200px;" *ngIf="contentArt?.data[0]?.attributes?.grafika_tytulowa?.data?.attributes?.url" [src]="this.strapiUrl + contentArt.data[0].attributes.grafika_tytulowa.data.attributes.url" class="w-full rounded-lg shadow-lg" [alt]="contentArt?.data[0]?.attributes?.grafika_tytulowa?.data?.attributes.alternativeText" />
					</div>
				</div>
			</div>
			<button (click)="gotoumowa()" class="w-full p-element ml-auto p-button p-component mt-2 mb-16" pButton [label]="przejdzDalej" icon="pi pi-angle-double-right"></button>
		</section>
	</div>













</ng-container>