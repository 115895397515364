<div class="vz-formplace">
	<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl"><span translate>Odzyskiwanie hasła - krok 2/2</span></div>

	<form [formGroup]="fg" (ngSubmit)="forgotPassword2()" class="vz-center-container">
		<div class="w-full flex flex-col bg-gray-200 p-3">

			<div class="w-full py-3" *ngIf="sposobLogowania == 'email'">
				<label for="email"><span translate>Podaj swój adres email</span></label>
				<input id="email" class="vz-input-lg" type="email" pInputText formControlName="email" required />
			</div>

			<!-- <div class="w-full py-3" *ngIf="sposobLogowania == 'phoneNumber'">
				<label for="phoneNumber"><span translate>Podaj numer telefonu w formacie +48.....</span></label>
				<input id="phoneNumber" class="vz-input-lg" type="phoneNumber" pInputText formControlName="phoneNumber" required />
			</div> -->

			<div class="w-full py-3" *ngIf="sposobLogowania == 'phoneNumber'">
				<label for="phoneNumber" translate>wprowadź swój numer tlefonu</label>
				<div class="flex items-center">
					<p-dropdown class="flex-none" [options]="countryCodes" formControlName="countryCode" optionLabel="label" optionValue="value"></p-dropdown>
					<span class="flex-none ml-2">
						{{fg.get('countryCode').value}}
					</span>
					<input id="phoneNumber" class="vz-input-lg flex-grow ml-2" type="phoneNumber" pInputText formControlName="phoneNumber" required />
				</div>
			</div>

			<div class="w-full py-3">
				<label for="verificationCode"><span translate>Kod weryfikacyjny</span></label>
				<input id="verificationCode" class="vz-input-lg" type="text" pInputText formControlName="verificationCode" required />
			</div>


			<div class="w-full py-3">
				<label for="newpassword1"><span translate>Hasło</span></label>
				<input id="newpassword1" class="vz-input-lg" type="password" pInputText formControlName="newpassword1" required />
			</div>

			<div class="w-full py-3">
				<label for="newpassword2"><span translate>Potwierdź hasło</span></label>
				<input id="newpassword2" class="vz-input-lg" type="password" pInputText formControlName="newpassword2" required />
			</div>
			<br>

			<div class="w-full py-3">
				<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" type="submit" mat-stroked-button color="primary" [disabled]="!fg.valid"><span translate>Change password</span></button>
			</div>

		</div>
	</form>
</div>


