import { ActivatedRoute, Router } from '@angular/router';
import { Field } from '../../../cbbmodels/field';
import { getUserProfile, getReport, getEditedReport } from '../../../cbboffice-rx-store/selectors';
import { Component, OnInit, ɵconvertToBitFlags } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { setReport } from '../../../cbboffice-rx-store/cbbactions';
import { Report } from '../../../cbbmodels/report';

@Component({
	selector: 'app-umowa',
	templateUrl: './umowa.component.html',
	styleUrls: ['./umowa.component.scss']
})
export class UmowaComponent implements OnInit {

	SubForm;
	SubMod;
	SubUsr;
	SubZip;
	editedReport: Report;
	wartosciZProfiluJuzPoszly: boolean = false;
	wartosciZeStoreJuzPoszly: boolean = false;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private state: Store<CbbState>,
	) { }

	form = this.fb.group({
		pos_1_finanzamt: [''],
		pos_2_aufnahme: [''],
		pos_3_beteiligung: [''],
		pos_4_name: [''],
		pos_4_vorname: [''],
		pos_5_geburtsname: [''],
		pos_6_ausgeubter: [''],
		pos_6_geburtsdatum: [''],
		pos_7_strabe: [''],
		pos_8_hausnummer: [''],
		pos_8_hausnummerzusats: [''],
		pos_8_adresserganzung: [''],
		pos_9_postleitzahl: [''],
		pos_9_wohnort: [''],
		pos_10_postleitzahl: [''],
		pos_10_ort: [''],
		pos_10_postfach: [''],
		pos_11_identyfikationsnummer: [''],
		pos_11_religion: [''],
		pos_12_verheiratet: [''],
		pos_12_verwitwet: [''],
		pos_12_geschieden: [''],
		pos_12_dauernd: [''],
		pos_14_name: [''],
		pos_14_vorname: [''],
		pos_15_geburtsname: [''],
		pos_16_ausgeubter: [''],
		pos_16_geburtsdatum: [''],
		pos_17_strabe: [''],
		pos_18_hausnummer: [''],
		pos_18_hausnummerzusatz: [''],
		pos_18_adressergarizung: [''],
		pos_19_postleitzahl: [''],
		pos_19_wohnort: [''],
		pos_20_identyfikationsnummer: [''],
		pos_20_religion: [''],
		pos_21_international: [''],
		pos_21_national: [''],
		pos_21_rufnummer: [''],
		pos_22_international: [''],
		pos_22_national: [''],
		pos_22_rufnummer: [''],
		pos_23_mail: [''],
		pos_24_internetadresse: [''],
		pos_25_tatigkeit: [''],
		pos_26_tatigkeit: [''],
		pos_27_iban: [''],
		pos_28_iban: [''],
		pos_29_bic: [''],
		pos_30_kontoinhaber4: [''],
		pos_30_kontoinhaber14: [''],
		pos_30_abweichende: [''],
		pos_31_bankverbindung: [''],
		pos_32_iban: [''],
		pos_33_iban: [''],
		pos_34_bic: [''],
		pos_35_kontoinhaber4: [''],
		pos_35_kontoinhaber14: [''],
		pos_35_abweichende: [''],
		pos_36_ausgefullte: [''],
		pos_37_firma: [''],
		pos_38_name: [''],
		pos_38_vorname: [''],
		pos_39_strabe: [''],
		pos_40_hausnummer: [''],
		pos_40_hausnummerzusatz: [''],
		pos_40_adresserganzung: [''],
		pos_41_postleitzahl: [''],
		pos_41_ort: [''],
		pos_42_postleitzahl: [''],
		pos_42_ort: [''],
		pos_42_postfach: [''],
		pos_43_international: [''],
		pos_43_national: [''],
		pos_43_rufnummer: [''],
		pos_44_mail: [''],
		pos_45_vollmacht: [''],
		pos_46_vollmacht: [''],
		pos_49_beratung: [''],
		pos_50_firma: [''],
		pos_51_name: [''],
		pos_51_vorname: [''],
		pos_52_strabe: [''],
		pos_53_hausnummer: [''],
		pos_53_hausnummerzusatz: [''],
		pos_53_adresserganzung: [''],
		pos_54_postleitzahl: [''],
		pos_54_ort: [''],
		pos_55_postleitzahl: [''],
		pos_55_ort: [''],
		pos_55_postfach: [''],
		pos_56_international: [''],
		pos_56_national: [''],
		pos_56_rufnummer: [''],
		pos_57_mail: [''],
		pos_58_vollmacht: [''],
		pos_59_vollmacht: [''],
		pos_60_zugezogen: [''],
		pos_61_strabe: [''],
		pos_62_hausnummer: [''],
		pos_62_hausnummerzusatz: [''],
		pos_62_adresserganzung: [''],
		pos_63_postleitzahl: [''],
		pos_63_wohnort: [''],
		pos_64_finanzamt: [''],
		pos_65_steuernummer: [''],
		pos_66_finanzamt: [''],
		pos_67_steuernummer: [''],
		pos_68_bezeichnung: [''],
		pos_69_strabe: [''],
		pos_70_hausnummer: [''],
		pos_70_hausnummerzusatz: [''],
		pos_70_adresserganzung: [''],
		pos_71_postleitzahl: [''],
		pos_71_ort: [''],
		pos_72_postleitzahl: [''],
		pos_72_ort: [''],
		pos_72_postfach: [''],
		pos_73_strabe: [''],
		pos_74_hausnummer: [''],
		pos_74_hausnummerzusatz: [''],
		pos_74_adresserganzung: [''],
		pos_75_postleitzahl: [''],
		pos_75_ort: [''],
		pos_76_international: [''],
		pos_76_national: [''],
		pos_76_rufnummer: [''],
		pos_77_mail: [''],
		pos_78_internetadresse: [''],
		pos_79_tatigkeit: [''],
		pos_80_bezeichnung: [''],
		pos_81_strabe: [''],
		pos_82_hausnummer: [''],
		pos_82_hausnummerzusatz: [''],
		pos_82_adresserganzung: [''],
		pos_83_postleitzahl: [''],
		pos_83_ort: [''],
		pos_84_international: [''],
		pos_84_national: [''],
		pos_84_rufnummer: [''],
		pos_85_bezeichnung: [''],
		pos_86_strabe: [''],
		pos_87_hausnummer: [''],
		pos_87_hausnummerzusatz: [''],
		pos_87_adresserganzung: [''],
		pos_88_postleitzahl: [''],
		pos_88_ort: [''],
		pos_89_international: [''],
		pos_89_national: [''],
		pos_89_rufnummer: [''],
		pos_90_betriebstatten: [''],
		pos_91_ja: [''],
		pos_91_seit: [''],
		pos_91_nein: [''],
		pos_91_eintragung: [''],
		pos_92_handelsregister: [''],
		pos_93_amtsgericht: [''],
		pos_94_ort: [''],
		pos_95_registernummer: [''],
		pos_96_grundungsform: [''],
		pos_96_zum: [''],
		pos_98_firma: [''],
		pos_99_name: [''],
		pos_99_vorname: [''],
		pos_100_strabe: [''],
		pos_101_hausnummer: [''],
		pos_101_hausnummerzusatz: [''],
		pos_101_adresserganzung: [''],
		pos_102_postleitzahl: [''],
		pos_102_ort: [''],
		pos_103_finanzamt: [''],
		pos_103_steuernummer: [''],
		pos_104_identifikationsnummer: [''],
		pos_105_nein: [''],
		pos_105_ja: [''],
		pos_105_tatigkeit: [''],
		pos_106_ort: [''],
		pos_107_vom: [''],
		pos_107_bis: [''],
		pos_108_finanzamt: [''],
		pos_108_steuernummer: [''],
		pos_109_identifikationsnummer: [''],
		pos_110_col1: [''],
		pos_110_col2: [''],
		pos_110_col3: [''],
		pos_110_col4: [''],
		pos_111_col1: [''],
		pos_111_col2: [''],
		pos_111_col3: [''],
		pos_111_col4: [''],
		pos_112_col1: [''],
		pos_112_col2: [''],
		pos_112_col3: [''],
		pos_112_col4: [''],
		pos_113_col1: [''],
		pos_113_col2: [''],
		pos_113_col3: [''],
		pos_113_col4: [''],
		pos_114_col1: [''],
		pos_114_col2: [''],
		pos_114_col3: [''],
		pos_114_col4: [''],
		pos_115_col1: [''],
		pos_115_col2: [''],
		pos_115_col3: [''],
		pos_115_col4: [''],
		pos_116_col1: [''],
		pos_116_col2: [''],
		pos_116_col3: [''],
		pos_116_col4: [''],
		pos_117_col1: [''],
		pos_117_col2: [''],
		pos_117_col3: [''],
		pos_117_col4: [''],
		pos_118_col1: [''],
		pos_118_col2: [''],
		pos_118_col3: [''],
		pos_118_col4: [''],
		pos_119_gewinnermittlungsart: [''],
		pos_119_angaben: [''],
		pos_123_nein: [''],
		pos_123_ja: [''],
		pos_123_beginn: [''],
		pos_124_freistellung: [''],
		pos_125_insgesamt: [''],
		pos_125_familienangehorige: [''],
		pos_125_beschaftigte: [''],
		pos_126_lohnzahlungen: [''],
		pos_127_kalenderjahr: [''],
		pos_128_bezeichnung: [''],
		pos_129_strabe: [''],
		pos_130_hausnummer: [''],
		pos_130_hausnummerzusatz: [''],
		pos_130_adresserganzung: [''],
		pos_131_postleitzahl: [''],
		pos_131_ort: [''],
		pos_132_betriebseroffnung: [''],
		pos_132_folgejahr: [''],
		pos_133_nein: [''],
		pos_133_ja: [''],
		pos_134_gesamtumzatz: [''],
		pos_135_gesamtumzatz: [''],
		pos_136_firma: [''],
		pos_137_strabe: [''],
		pos_138_hausnummer: [''],
		pos_138_hausnummerzusatz: [''],
		pos_138_adresserganzung: [''],
		pos_139_postleitzahl: [''],
		pos_139_ort: [''],
		pos_140_postleitzahl: [''],
		pos_140_ort: [''],
		pos_140_postfach: [''],
		pos_141_rechtsform: [''],
		pos_142_bruchteil1: [''],
		pos_142_bruchteil2: [''],
		pos_143_finanzamt: [''],
		pos_143_steuernummer: [''],
		pos_144_identifikationsnummer: [''],
		pos_145_nein: [''],
		pos_145_ja: [''],
		pos_145_tatigkeit: [''],
		pos_145_nr: [''],
		pos_146_nein: [''],
		pos_146_ja: [''],
		pos_146_tatigkeit: [''],
		pos_146_nr: [''],
		pos_147_nein: [''],
		pos_147_ja: [''],
		pos_147_tatigkeit: [''],
		pos_147_nr: [''],
		pos_148_sollversteuerung: [''],
		pos_149_istversteuerung: [''],
		pos_150_hochgerechnete: [''],
		pos_151_verpflichtung: [''],
		pos_152_ausfuhre: [''],
		pos_153_benotige: [''],
		pos_154_bereits: [''],
		pos_155_nr: [''],
		pos_155_vergabedatum: [''],
		pos_156_erteilung: [''],
		pos_157_bauleistungen: [''],
		pos_158_gebaudereinigungsleistungen: [''],
		pos_159_besondere: [''],
		pos_160_niederlassung: [''],
		pos_161_inland: [''],
		pos_162_versteuerung: [''],
		pos_163_anspruch: [''],
		pos_164_entsprechenden: [''],
		pos_165_besondere: [''],
		pos_166_gesamtbetrag: [''],
		pos_167_umsatze: [''],
		pos_168_moglichkeit: [''],
		pos_169_besondere: [''],
		pos_170_umsatze: [''],
		pos_171_webshop: [''],
		pos_172_webadresse: [''],
		pos_173_marktplatz: [''],
		pos_174_col1: [''],
		pos_174_col2: [''],
		pos_175_col1: [''],
		pos_175_col2: [''],
		pos_176_col1: [''],
		pos_176_col2: [''],
		pos_177_col1: [''],
		pos_177_col2: [''],
		pos_178_col1: [''],
		pos_178_col2: [''],
		pos_179_austellung: [''],
		pos_180_bezeichnung: [''],
		pos_181_strabe: [''],
		pos_182_hausnummer: [''],
		pos_182_hausnummerzusatz: [''],
		pos_182_adresserganzung: [''],
		pos_183_postleitzahl: [''],
		pos_183_ort: [''],
		pos_184_postleitzahl: [''],
		pos_184_ort: [''],
		pos_184_postfach: [''],
		pos_185_finanzamt: [''],
		pos_185_steuernummer: [''],
		pos_186_teilnahmeerklarung: [''],
		pos_186_umwandlung: [''],
		pos_187_vollmacht: [''],
		pos_187_verbindungen: [''],
		pos_188_empfangsvollmacht: [''],
		pos_188_marktplatze: [''],
		pos_189_betriebsstatten: [''],
		pos_189_gesellschaftsvertrag: [''],
		pos_190_checkbox: [''],
		pos_190_text: [''],
	})

	ngOnInit(): void {																								//TODO: wypełniam z profilu domyślne pola -  przenieść to do serweisu, żeby była wspólna metoda dla wszystkich umów
		this.form.disable();
		this.wartosciZProfiluJuzPoszly = false;
		this.wartosciZeStoreJuzPoszly = false;
		this.SubForm = this.state.select(getUserProfile())																//TODO: w ogóle zrobić to gdzie indziej
		this.SubUsr = this.SubForm.subscribe(x => {
			// JEŻELI NIE MA TO PRZEPISUJE
			if (x.firstName.length > 0) this.form.get('pos_4_name')?.setValue(x.firstName);
			if (x.lastName.length > 0) this.form.get('pos_4_vorname')?.setValue(x.lastName);
			if (x.birthDate.length > 0) this.form.get('pos_6_geburtsdatum')?.setValue(x.birthDate);
			if (x.country.length > 0) this.form.get('pos_10_ort')?.setValue(x.country);
			if (x.email.length > 0) this.form.get('pos_23_mail')?.setValue(x.email);
			if (x.local.length > 0) this.form.get('pos_8_hausnummer')?.setValue(x.local);
			if (x.possession.length > 0) this.form.get('pos_8_hausnummerzusats')?.setValue(x.possession);
			if (x.zipCode.length > 0) this.form.get('pos_10_postleitzahl')?.setValue(x.zipCode);
			this.wartosciZProfiluJuzPoszly = true;
			this.pobierzDane();
		})

		this.SubMod = this.state.select(getEditedReport())
			.subscribe((r: Report) => {
				if (r.reportName == '') { this.router.navigate(['console/documents']); return; }
				this.editedReport = r;
				this.wartosciZeStoreJuzPoszly = true;
				this.pobierzDane()
			})
	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
		if (this.SubUsr) this.SubUsr.unsubscribe();
	}

	pobierzDane() {
		if (this.wartosciZProfiluJuzPoszly && this.wartosciZeStoreJuzPoszly) {																							//TODO: zrobić z tego mergeMap
			this.editedReport.fields.forEach(f => {
				this.form.get(f.fieldName)?.setValue(f.fieldValue);
			});
		}
	}

	send() {
		//TODO: TUTAJ ZBADAĆ POPRAWNOŚĆ FORMULARZA I (NIE)PUŚCIĆ DALEJ

		this.form.disable();
		let fields: Field[] = [];
		for (let [key, value] of Object.entries(this.form.getRawValue())) {
			fields.push({ fieldName: key, fieldValue: value, children: [] });  //było pole children - na razie wywalone
		}
		this.state.dispatch(setReport({ rep: { ...this.editedReport, fields: fields, status: 'edited' } }));
	}

	edytuj() {
		this.form.enable();
	}
}
