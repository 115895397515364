<div class="flex flex-col justify-center bg-gray-100">
    <div class="mx-auto w-full" style="max-width: 1200px; min-height: 80vh;">

        <ng-container>
            <app-article [artykul]="'policy'" [wariant]="'elegancki'"></app-article>
        </ng-container>

    </div>
</div>

<app-stopka></app-stopka>