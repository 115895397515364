import { clearCbbState } from './../../../cbboffice-rx-store/cbbactions';
import { AuthCognitoService } from './../auth-cognito.service';
import { Component, ViewChild, HostListener, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { Store } from '@ngrx/store';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { delay, tap } from 'rxjs';

@Component({
	selector: 'viz-auth-auth-panel',
	templateUrl: './auth-panel.component.html',
	styleUrls: ['../scss/styles.scss', './auth-panel.component.scss'],
})
export class AuthPanelComponent implements OnInit, OnDestroy {

	sposobLogowania: string = 'start';
	singinMethod$: any;
	juzwidac: boolean = false;

	@Input() jestemWHome: boolean = true;

	products: any[];
	selectedProduct: any;

	actualView: string = '';
	panelVisible: boolean = false;
	name: string = '';
	vname: string = '';
	changeView$;
	loginState: boolean;

	constructor(
		private authService: AuthCognitoService,
		private router: Router,
		private messageService: MessageService,
		private state: Store<CbbState>,
		private translate: TranslateService
	) {
		this.changeView$ = this.authService.changeViewSub.subscribe((view: string) => {
			this.actualView = view;
			if (view == 'profile') {
				setTimeout(() => {
					this.authService.getUserInfo();
				}, 1111);
			}
		})

		this.authService.userInfo$.subscribe((userInfo: any) => { //TODO: typować
			this.vname = 'Log In';
			if (!userInfo?.userInfo?.UserAttributes) {
				this.loginState = false;
				return;
			}
			let tablica = userInfo.userInfo.UserAttributes;

			let witaj: string = this.translate.instant('Witaj');

			let idx = tablica.findIndex(item => item.Name === 'given_name');
			if (idx > -1) {
				this.vname = witaj + ' ' + tablica[idx].Value;
			}

			this.vname = witaj;
			this.loginState = (tablica.length > 0)
		})

		this.sposobLogowania = this.authService.sposobLogowania;
		this.singinMethod$ = this.authService.onChangeMethodSignIn
			.subscribe((sposob: string) => {
				setTimeout(() => {
					this.sposobLogowania = sposob;
				}, 1);
				this.juzwidac = true;
			});
	}

	ngOnInit(): void {
		this.loginState = this.authService.isLoged();
		if (this.authService.isLogedLocal())
			this.authService.changeView('profile');

		this.vname = this.translate.instant('Witaj');

	}

	ngOnDestroy(): void {
		this.changeView$.unsubscribe();
		if (this.singinMethod$) this.singinMethod$.unsubscribe();
	}

	changeView(view: string) {
		this.authService.togglePanel(view)
		if (view == 'profil') {
			this.router.navigate(['/console'])
			this.authService.togglePanel('profil');
		}
	}

	logout() {
		this.authService.logout()
		// .subscribe(x => {
		this.authService.changeView('login');
		this.router.navigate(['/']);
		this.state.dispatch(clearCbbState());
		// })
	}

	onRowSelect(event) {
		this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
	}

	ustawSposobLogowania(sposob: string, akcja: string) {
		localStorage.setItem('spsblg', sposob);
		this.authService.ChangeMethodSignIn(sposob);
		this.juzwidac = true;
		switch (akcja) {
			case 'login':
				this.changeView('login');
				break;
			case 'register':
				this.changeView('register');
				break;
		}
	}
}
