import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['../scss/styles.scss', './change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

	constructor(
		private authService: AuthCognitoService
	) { }

	fg: FormGroup = new FormGroup({
		oldpassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
		newpassword1: new FormControl('', [Validators.required, Validators.minLength(5)]),
		newpassword2: new FormControl('', [Validators.required, Validators.minLength(5)]),
	})

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
	}

	changeView(view: string) {
		this.authService.changeView(view);
	}

	changePassword() {
		if (this.fg.value.oldpassword.length < 3) {
			this.authService.setMessage('password too short');
			return;
		}

		if (this.fg.value.newpassword1.length < 3) {
			this.authService.setMessage('password too short');
			return;
		}

		if (this.fg.value.newpassword1 != this.fg.value.newpassword2) {
			this.authService.setMessage('the passwords do not match');
			return;
		}

		this.authService.changePassword(this.fg.value.oldpassword, this.fg.value.newpassword1);
	}

}
