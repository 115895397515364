import { clearCbbState } from './../../../cbboffice-rx-store/cbbactions';
import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnInit } from '@angular/core';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { Store } from '@ngrx/store';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['../scss/styles.scss', './logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(
		private auth:AuthCognitoService,
		private state: Store<CbbState>
	) { }

  ngOnInit(): void {
		this.auth.logout();
		this.state.dispatch(clearCbbState());
  }

}
