<div class="flex justify-center bg-gray-800 text-white p-6">
	<div class="w-1/3">
		<ng-container *ngIf="kolumna1">
			<h3 class="font-semibold mb-2" translate>{{ kolumna1.tytul }}</h3>
			<div [innerHTML]="kolumna1.tresc">
			</div>
		</ng-container>
	</div>
	<div class="w-1/3">
		<h3 class="font-semibold mb-2" translate>Ważne linki</h3>
		<ul class="list-none">
			<ng-container *ngFor="let stopka of stopki">
				<li *ngIf="stopka.slug != 'kolumna1' && stopka.slug != 'kolumna3'"><a [routerLink]="['/blog',stopka.slug]" class="text-gray-300 hover:text-white">{{stopka.tytul}}</a></li>
			</ng-container>
		</ul>
	</div>
	<div class="w-1/3">
		<ng-container *ngIf="kolumna3">
			<h3 class="font-semibold mb-2" translate></h3>
			<div [innerHTML]="kolumna3.tresc">
			</div>
		</ng-container>
	</div>
</div>