<div class="flex items-center flex-wrap pt-2 pb-2 align-right">
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-pl mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'pl'}"
      (click)="useLanguage('pl')"
    >&nbsp;</span>
	</div>
  <!-- <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-de mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'de'}"
      (click)="useLanguage('de')"
    >&nbsp;</span>
	</div> -->
  <!-- <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-gb mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'en'}"
      (click)="useLanguage('en')"
    >&nbsp;</span>
	</div> -->
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-lv mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'lv'}"
      (click)="useLanguage('lv')"
    >&nbsp;</span>
	</div>
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-lt mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'lt'}"
      (click)="useLanguage('lt')"
    >&nbsp;</span>
	</div>
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-bg mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'bg'}"
      (click)="useLanguage('bg')"
    >&nbsp;</span>
	</div>
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-cz mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'cz'}"
      (click)="useLanguage('cz')"
    >&nbsp;</span>
	</div>
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-sk mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'sk'}"
      (click)="useLanguage('sk')"
    >&nbsp;</span>
	</div>
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-ua mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'ua'}"
      (click)="useLanguage('ua')"
    >&nbsp;</span>
	</div>
  <div class="mx-2 !shadow-xl">
    <span
      class="flag-icon flag-icon-ro mx-auto my-auto py-1 mt-2 px-3 cienie"
      style="width: 40px; height: 30px;"
			[ngClass]="{'klikniete': lang == 'ro'}"
      (click)="useLanguage('ro')"
    >&nbsp;</span>
	</div>
</div>
