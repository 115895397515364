import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map, tap, toArray } from 'rxjs';
import { Inbox } from '../cbbmodels/inbox';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CbbService {

	tokenJWT:string = '';
	hdr:any;

  constructor(
		private http: HttpClient
	) { }

	find(data:any){							//TODO: wyszukiwarka po data
		return from([
			{
				"id": "11",
				"email": "asdfafd@asdfadsf",
				"address": "asdfasdf",
				"status": "new",
				"reportName": "asdfasdf",
			},
			{
				"id": "22",
				"email": "xxxxx@asdfadsf",
				"address": "xxxxx",
				"status": "edited",
				"reportName": "xxxxx",
			},
			{
				"id": "33",
				"email": "yyyyy@asdfadsf",
				"address": "yyyyy",
				"status": "new",
				"reportName": "yyyyy",
			},
			{
				"id": "44",
				"email": "zzzzz@asdfadsf",
				"address": "zzzzz",
				"status": "new",
				"reportName": "zzzzz",
			},			
		]).pipe(
			toArray()
		);

	}

	getMessages(cognitoUsername: string, params:any){
		this.getHdr();
		let url = environment.ApiUrl + '/cbb/messages';
		return this.http.get(`${url}?cognitoUsername=${cognitoUsername}`,{ headers: this.hdr })
			.pipe(
				tap((data:any) => {
				}),
				map((data:any) => data.messages)
			)
	}

	reply(cognitoUsername: string, inbox:Inbox){
		this.getHdr();
		let url = environment.ApiUrl + '/cbb/sendmessage';
		return this.http.post(url,{cognitoUsername:cognitoUsername,message:inbox},{ headers: this.hdr })
	}

	read(cognitoUsername: string, inbox:Inbox){
		this.getHdr();
		let url = environment.ApiUrl + '/cbb/readedmessage';
		return this.http.post(url,{cognitoUsername:cognitoUsername,message:inbox},{ headers: this.hdr })
	}

	getHdr() {
		this.tokenJWT = localStorage.getItem('cognitoToken');
		this.hdr = {
			'Content-Type': 'application/json',
			'accept': '*/*',
			'Authorization': `Bearer ${this.tokenJWT}`
		};
	}
}
