import { AuthCognitoService } from './auth-cognito/auth-cognito.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CognitoGuard implements CanActivate {

	constructor(
		private readonly authService: AuthCognitoService,
		private router: Router
	) {

	}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
			return this.authService.isAuthenticatedUser()
				.pipe(
					tap((x:boolean) => {
						this.authService.changeView('login');
						if(!x) this.router.navigate(['/']);
					})
				);

  }
  
}
