import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../scss/styles.scss', './profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {

	nazwa:string = '';
	message: string = '';
	messSub$;

  constructor(
		private authService: AuthCognitoService
	) { 
		this.messSub$ = this.authService.message$.subscribe(x => {
			this.message = x 
		});
	}

  ngOnInit(): void {
		this.nazwa = this.authService.getName();
  }

	ngOnDestroy(): void {
		if(this.messSub$) this.messSub$.unsubscribe();	
	}

	changeView(view:string) {
		this.authService.changeView(view);
	}

	getUserInfo() {
		this.authService.getUserInfo();
	}
	//TODO: zaimportować dane do wyświetlenia w profilu z konfiguracji w app.module (linki do <gdzieś tam> i avatara)

}
