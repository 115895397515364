export class DokToSig {
	public uidReport: number;
	public reportName: string;
	public stan: string;
	public label: string;
	public path: string;
	public uid: string;
	public autopodpis?: boolean | undefined;

	constructor(wsad: DokToSig) {
		this.uidReport = wsad.uidReport;
		this.reportName = wsad.reportName;
		this.stan = wsad.stan;
		this.label = wsad.label;
		this.path = wsad.path;
		this.uid = wsad.uid;
		this.autopodpis = (wsad.autopodpis) ? true : false;
	}
}
