<div class="vz-formplace">
	<form [formGroup]="fg" (ngSubmit)="changePassword()" class="vz-center-container">
		<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl"><span translate>Change password</span></div>
		<div class="w-full flex flex-col bg-gray-200 p-3">
			<div class="w-full py-3">
				<label for="oldpassword"><span translate>Stare hasło</span></label>
				<input id="oldpassword" class="vz-input-lg" type="password" pInputText formControlName="oldpassword" required />
			</div>
			<div class="w-full py-3">
				<label for="newpassword1"><span translate>Nowe hasło</span></label>
				<input id="newpassword1" class="vz-input-lg" type="password" pInputText formControlName="newpassword1" required />
			</div>
			<div class="w-full py-3">
				<label for="newpassword2"><span translate>Potwierdź hasło</span></label>
				<input id="newpassword2" class="vz-input-lg" type="password" pInputText formControlName="newpassword2" required />
			</div>
			<div class="w-full py-3">
				<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" type="submit" [disabled]="!fg.valid"><span translate>Zmień hasło</span></button>
			</div>
		</div>
	</form>
</div>
