import { CbbformService } from './../../cbbform.service';
import { Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { getEditedReport, getUserProfile } from '../../../cbboffice-rx-store/selectors';
import { Report } from '../../../cbbmodels/report';
import { map, mergeMap } from 'rxjs';
import { UserConfig } from '../../../cbbmodels/user-config';
import { Field } from '../../../cbbmodels/field';
import { cancellAllFileByReport, setReport } from '../../../cbboffice-rx-store/cbbactions';
import { TranslateService } from '@ngx-translate/core';

export const DATE_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TaxComponent),
	multi: true
};

@Component({
	selector: 'app-tax',
	templateUrl: './tax.component.html',
	styleUrls: ['./tax.component.scss'],
	providers: [DATE_VALUE_ACCESSOR]
})
export class TaxComponent implements OnInit {

	editedReport: Report;
	storeOk: boolean = false;
	subUser$
	SubMod;
	form: FormGroup
	disabled: boolean = false;
	jestformularz: boolean = false;
	user: UserConfig;
	formularzPrzetwarzany: boolean = false;
	wojewodztwa: any[] = [{ label: 'n/d', value: 'n/d' }, { label: 'dolnośląskie', value: 'dolnośląskie' }, { label: 'kujawsko-pomorskie', value: 'kujawsko-pomorskie' }, { label: 'lubelskie', value: 'lubelskie' }, { label: 'lubuskie', value: 'lubuskie' }, { label: 'łódzkie', value: 'łódzkie' }, { label: 'małopolskie', value: 'małopolskie' }, { label: 'mazowieckie', value: 'mazowieckie' }, { label: 'opolskie', value: 'opolskie' }, { label: 'podkarpackie', value: 'podkarpackie' }, { label: 'podlaskie', value: 'podlaskie' }, { label: 'pomorskie', value: 'pomorskie' }, { label: 'śląskie', value: 'śląskie' }, { label: 'świętokrzyskie', value: 'świętokrzyskie' }, { label: 'warmińsko-mazurskie', value: 'warmińsko-mazurskie' }, { label: 'wielkopolskie', value: 'value:wielkopolskie' }, { label: 'zachodniopomorskie', value: 'zachodniopomorskie' }];
	lata: any[] = [];
	taknie: any[] = [{ label: 'tak', value: 'tak' }, { label: 'nie', value: 'nie' }];
	stany_cywilne: any[] = [{ label: 'małżeństwo', value: 'małżeństwo' }, { label: 'związek partnerski', value: 'związek partnerski' }, { label: 'samotny', value: 'samotny' }, { label: 'rozwiedziony', value: 'rozwiedziony' }, { label: 'wdowiec', value: 'wdowiec' }, { label: 'wdowa', value: 'wdowa' }];
	religie: any[] = [];
	lands: any[] = [{ label: 'Polska', value: 'Polska' }, { label: 'Niemcy', value: 'Niemcy' }, { label: 'Inny', value: 'Inny' }];
	osoby: any[] = [{ label: 'Osoba fizyczna', value: 'Osoba fizyczna' }, { label: 'Osoba nie fizyczna', value: 'Osoba nie fizyczna' }];
	czyzlecam: any[] = [{ label: 'chcę odpłątnie to zlecić', value: 'chcę odpłątnie to zlecić' }, { label: 'Załatwię to sam', value: 'Załatwię to sam' }];		//TODO: uwzględnić język
	innyland: string;
	prowadzisz_gospodarstwo_domowe_w_pl: boolean = false;
	prowadzisz_gospodarstwo_domowe_w_de: boolean = false;
	Zapisz: string = 'Zapisz';
	Anuluj: string = 'Anuluj';
	wybierz: string = 'wybierz';
	czynamzlecasz: string = 'czy nam zlecasz?';
	tak: string = 'tak';
	nie: string = 'nie';

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private state: Store<CbbState>,
		private translate: TranslateService,
		private cbbform: CbbformService
	) {
		this.form = this.cbbform.getForm('rozliczeniePodatkowe');
		let tenRok = new Date().getFullYear();
		this.lata.push('');
		for (let i = tenRok; i >= tenRok - 10; i--) {
			this.lata.push({ label: i.toString(), value: i.toString() });
		}
		
	}

	ngOnInit(): void {
		this.storeOk = false;
		let lang = this.translate.currentLang;
		console.log('lang',lang);
		

		this.Zapisz = this.translate.instant('Zapisz');
		this.Anuluj = this.translate.instant('Anuluj');
		this.wybierz = this.translate.instant('wybierz');
		this.czynamzlecasz = this.translate.instant('czy nam zlecasz?');
		this.religie = this.cbbform.getReligie(lang);
		this.taknie = this.cbbform.getTakNie(lang);
		this.tak = this.translate.instant('tak');
		this.nie = this.translate.instant('nie');
		
		this.stany_cywilne = this.cbbform.getStanyCywilne(lang);
		this.osoby = this.cbbform.getOsoby(lang);
		this.czyzlecam = this.cbbform.getCzyZlecam(lang);
		

		this.subUser$ = this.state.select(getUserProfile())
		this.SubMod = this.state.select(getEditedReport())
			.pipe(
				mergeMap(rep => {
					return this.subUser$.pipe(
						map(user => { return { user: user, report: rep } })
					)
				})
			)
			.subscribe((wsad: { user, report }) => {
				if (!this.formularzPrzetwarzany) {
					if (wsad.report?.reportName == '') { this.router.navigate(['console/documents']); return; }
					this.editedReport = wsad.report;

					if (this.editedReport.status == 'signed' || this.editedReport.status == 'archive' || this.editedReport.status == 'confirmed') {
						this.turn('off');
					} else this.turn('on');

					this.user = wsad.user;
					this.pobierzDane()
				}
			})
	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
	}

	pobierzDane() {
		this.form = this.cbbform.setDefaultFormValue(this.form, this.user);
		if (!Array.isArray(this.editedReport.fields)) return;
		this.editedReport.fields.forEach(f => {
			if (f.fieldName != 'dzieci' && f.fieldName != 'pracodawcy' && f.fieldName != 'rachunki' && f.fieldName != 'adres_zamieszkania_w_niemczech') {

				this.form.get(f.fieldName)?.setValue((f.fieldValue == 'true') ? true : f.fieldValue);

			} else {

				if (f.fieldName == 'dzieci') {
					f.children.forEach((c, i) => {
						this.dodajDzieci();
						c.children.forEach(cc => {
							this.dzieci.controls[i].get(cc.fieldName)?.setValue(cc.fieldValue);
						})
					})
				}

				if (f.fieldName == 'pracodawcy') {
					f.children.forEach((c, i) => {
						this.dodajPracodawce();
						c.children.forEach(cc => {
							this.pracodawcy.controls[i].get(cc.fieldName)?.setValue(cc.fieldValue);
						})
					})
				}

				if (f.fieldName == 'rachunki') {
					f.children.forEach((c, i) => {
						this.dodajRachunek();
						c.children.forEach(cc => {
							this.rachunki.controls[i].get(cc.fieldName)?.setValue(cc.fieldValue);
						})
					})
				}

				if (f.fieldName == 'adres_zamieszkania_w_niemczech') {
					f.children.forEach((c, i) => {
						this.dodajAdresZamieszkaniaWNiemczech();
						c.children.forEach(cc => {
							this.adres_zamieszkania_w_niemczech.controls[i].get(cc.fieldName)?.setValue(cc.fieldValue);
						})
					})
				}

			}
		});

		this.form.get('data_slubu')?.setValue(this.cbbform.zepsujDate(this.form.get('data_slubu')?.value));
		this.form.get('data_rozwodu')?.setValue(this.cbbform.zepsujDate(this.form.get('data_rozwodu')?.value));
		this.form.get('data_owdowienia')?.setValue(this.cbbform.zepsujDate(this.form.get('data_owdowienia')?.value));
		this.form.get('data_urodzenia_malzonka')?.setValue(this.cbbform.zepsujDate(this.form.get('data_urodzenia_malzonka')?.value));
		this.form.get('pos_6_geburtsdatum')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_6_geburtsdatum')?.value));

		this.isValid();
	}

	get dzieci() {
		return this.form.get('dzieci') as FormArray;
	}
	get pracodawcy() {
		return this.form.get('pracodawcy') as FormArray;
	}
	get rachunki() {
		return this.form.get('rachunki') as FormArray;
	}
	get adres_zamieszkania_w_niemczech() {
		return this.form.get('adres_zamieszkania_w_niemczech') as FormArray;
	}

	dodajDzieci() {
		let control = <FormArray>this.form.controls.dzieci;
		control.push(this.fb.group({
			dziecko_imie: new FormControl('', Validators.required),
			dziecko_nazwisko: new FormControl('', Validators.required),
			dziecko_data_urodzenia: new FormControl('', Validators.required),
			dziecko_miejsce_i_okres_nauki: new FormControl('', Validators.required),
			dziecko_dane_dot_dochodow_wlasnych_dzieci: new FormControl('', Validators.required),
		}))
	}

	dodajPracodawce() {
		let control = <FormArray>this.form.controls.pracodawcy;
		control.push(this.fb.group({
			pracodawca_nazwa: new FormControl('', Validators.required),
			pracodawca_adres: new FormControl('', Validators.required),
			numer_seryjny_auta_dojazdu: new FormControl(''),
			// pracodawca_nip: new FormControl('', Validators.required),
			// pracodawca_data_rozpoczecia_pracy: new FormControl('', Validators.required),
			// pracodawca_data_zakonczenia_pracy: new FormControl('', Validators.required),
			// pracodawca_dochod: new FormControl('', Validators.required),
			// pracodawca_dochod_zus: new FormControl('', Validators.required),
			// pracodawca_dochod_zus_zaliczka_na_podatek: new FormControl('', Validators.required),
			// pracodawca_dochod_zus_zaliczka_na_podatek_zus: new FormControl('', Validators.required),
		}))
	}

	dodajRachunek() {
		let control = <FormArray>this.form.controls.rachunki;
		control.push(this.fb.group({
			rachunek_nazwa_banku: new FormControl('', Validators.required),
			miejscowosc_banku: new FormControl('', Validators.required),
			rachunek_swift: new FormControl('', Validators.required),
			rachunek_numer: new FormControl('', Validators.required),
		}))
	}

	dodajAdresZamieszkaniaWNiemczech() {
		let control = <FormArray>this.form.controls.adres_zamieszkania_w_niemczech;
		control.push(this.fb.group({
			adres_zamieszkania_w_niemczech_adres: new FormControl('', Validators.required),
			adres_zamieszkania_w_niemczech_okres_pobytu: new FormControl('', Validators.required),
			adres_zamieszkania_w_niemczech_adres_wykonywanej_pracy_w_niemczech: new FormControl('', Validators.required),
			adres_zamieszkania_w_niemczech_odleglosc_de_de: new FormControl('', Validators.required),
			adres_zamieszkania_w_niemczech_odleglosc_pl_de: new FormControl('', Validators.required),
		}))
	}

	usunDziecko(i: number) {
		let control = <FormArray>this.form.controls.dzieci;
		control.removeAt(i);
	}

	usunPracodawce(i: number) {
		let control = <FormArray>this.form.controls.pracodawcy;
		control.removeAt(i);
	}

	usunRachunek(i: number) {
		let control = <FormArray>this.form.controls.rachunki;
		control.removeAt(i);
	}

	usunAdresZamieszkaniaWNiemczech(i: number) {
		let control = <FormArray>this.form.controls.adres_zamieszkania_w_niemczech;
		control.removeAt(i);
	}

	turn(swit: string) {
		switch (swit) {
			case 'on':
				if (this?.editedReport?.status == 'edited' || this.editedReport.status == 'new') {
					this.form.enable();
					this.disabled = false;
					this.formularzPrzetwarzany = false;
				} else {
					console.log('Nie można edytować', this.editedReport.status);
				}
				return;
			case 'off':
				this.form.disable();
				this.disabled = true;
				return;
		}
	}

	reset() {
		this.form.reset();
		this.pobierzDane();
		this.formularzPrzetwarzany = false;
	}

	setValue() {
	}

	isValid() {
		this.cbbform.czyMonzaIscDalej(false);
		if (!this.form.valid) return false;

		this.cbbform.czyMonzaIscDalej(true);
		return true;
	}

	kontra(co: string) {
		switch (co) {
			case 'osoba_nie_fizyczna':
				this.form.get('osoba_fizyczna')?.setValue(!this.form.get('osoba_nie_fizyczna')?.value);
				return;
			case 'osoba_fizyczna':
				this.form.get('osoba_nie_fizyczna')?.setValue(!this.form.get('osoba_fizyczna')?.value);
				return;
		}
	}

	send() {
		this.formularzPrzetwarzany = true;
		let fields: Field[] = [];
		for (let [key, value] of Object.entries(this.form.getRawValue())) {
			if (!value) value = '';

			if (key == 'dzieci') {
				let dzieci: Field[] = [];
				if (Array.isArray(value)) {
					value.forEach((v, i) => {
						let dziecko: Field = new Field;
						dziecko.fieldName = 'dziecko';
						dziecko.fieldValue = 'to jest tablica w polu children[] !!!';
						for (let [key, value] of Object.entries(v)) {
							dziecko.children.push({ fieldName: key, fieldValue: value.toString(), children: [] });
						}
						dzieci.push(dziecko);
					});
					fields.push({ fieldName: 'dzieci', fieldValue: 'to jest tablica w polu children[] !!!', children: dzieci });
				}
			}

			if (key == 'pracodawcy') {
				let pracodawcy: Field[] = [];
				if (Array.isArray(value)) {
					value.forEach((v, i) => {
						let pracodawca: Field = new Field;
						pracodawca.fieldName = 'pracodawca';
						pracodawca.fieldValue = 'to jest tablica w polu children[] !!!';
						for (let [key, value] of Object.entries(v)) {
							pracodawca.children.push({ fieldName: key, fieldValue: value.toString(), children: [] });
						}
						pracodawcy.push(pracodawca);
					});
					fields.push({ fieldName: 'pracodawcy', fieldValue: 'to jest tablica w polu children[] !!!', children: pracodawcy });
				}
			}

			if (key == 'rachunki') {
				let rachunki: Field[] = [];
				if (Array.isArray(value)) {
					value.forEach((v, i) => {
						let rachunek: Field = new Field;
						rachunek.fieldName = 'rachunek';
						rachunek.fieldValue = 'to jest tablica w polu children[] !!!';
						for (let [key, value] of Object.entries(v)) {
							rachunek.children.push({ fieldName: key, fieldValue: value.toString(), children: [] });
						}
						rachunki.push(rachunek);
					});
					fields.push({ fieldName: 'rachunki', fieldValue: 'to jest tablica w polu children[] !!!', children: rachunki });
				}
			}

			if (key == 'adres_zamieszkania_w_niemczech') {
				let adres_zamieszkania_w_niemczech: Field[] = [];
				if (Array.isArray(value)) {
					value.forEach((v, i) => {
						let adres_de: Field = new Field;
						adres_de.fieldName = 'adres_de';
						adres_de.fieldValue = 'to jest tablica w polu children[] !!!';
						for (let [key, value] of Object.entries(v)) {
							adres_de.children.push({ fieldName: key, fieldValue: value.toString(), children: [] });
						}
						adres_zamieszkania_w_niemczech.push(adres_de);
					});
					fields.push({ fieldName: 'adres_zamieszkania_w_niemczech', fieldValue: 'to jest tablica w polu children[] !!!', children: adres_zamieszkania_w_niemczech });
				}
			}

			if (key != 'dzieci' && key != 'pracodawcy' && key != 'rachunki' && key != 'adres_zamieszkania_w_niemczech') {


				let normalnePole: boolean = true;
				// zmiana formatu daty
				if (key == 'data_urodzenia_malzonka' || key == 'pos_6_geburtsdatum' || key == 'data_owdowienia' || key == 'data_rozwodu' || key == 'data_slubu' || key == 'pos_16_geburtsdatum') {
					normalnePole = false;
					fields.push({ fieldName: key, fieldValue: this.cbbform.naprawDate(value.toString()), children: [] });
				}

				if (normalnePole) fields.push({ fieldName: key, fieldValue: value.toString(), children: [] });

			}
		}
		if (this?.editedReport?.status == 'new' || this?.editedReport?.status == 'edited') {
			this.editedReport = { ...this.editedReport, fields: fields, status: 'edited' };
			this.state.dispatch(cancellAllFileByReport({ rep: this.editedReport }));
			this.state.dispatch(setReport({ rep: this.editedReport }));

			if (this.isValid()) {
				this.cbbform.idzdalej();
			} else {
				this.cbbform.errMess('Nie wszystkie dane są wprowadzone poprawnie (patrz czerwone ramki)');
			}
		}
		// this.turn('off');
	}
}
