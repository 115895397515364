<p-card>
	<app-info slug="kwestionariusz"></app-info>
</p-card>


<ng-container *ngIf="form">
	<form class="w-full my-6" [formGroup]="form" (ngSubmit)="send()">
		<div class="formwrapper mt-6">

			<div class="formwrapperrow33x"></div>

			<div class="formwrapperrow33x">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>
			<div class="formwrapperrow33x">
				<!-- <button (click)="turn('on')" *ngIf="disabled" pButton label="Edytuj" icon="pi pi-user-edit"></button> -->
				<button (click)="reset()" *ngIf="!disabled" pButton [label]="Anuluj" icon="pi pi-undo"></button>
			</div>

			<!-- <div class="formwrappermessagex">
				<h1 translate>Zgoda RODO</h1>
			</div>
			<div class="mt-0">
				<div class="flex">
					<div class="flex-1">
						<div class="formwrapperrow justify-center">
							<label for="administrator_danych_osobowych" class="text-center" translate>Wyrażam zgodę</label>
							<p-checkbox formControlName="administrator_danych_osobowych" [binary]="true" inputId="administrator_danych_osobowych" [ngClass]="{'dirtyinput': form.controls.administrator_danych_osobowych.invalid}"></p-checkbox>
						</div>
					</div>
					<div class="flex-3 pl-4">
						<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w niniejszym kwestionariuszu osobowym dla potrzeb niezbędnych do realizacji usługi przez CBBOffice (zgodnie z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych</p>
						<p translate>osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)).</p>
					</div>
				</div>
			</div> -->


			<div class="formwrappermessage">
				<h1 translate>Rozliczenie za rok:</h1>
			</div>

			<div class="formwrapperrow33"></div>
			<!-- ktory_rok -->
			<div class="formwrapperrow33 mb-4 justify-center">
				<label for="ktory_rok" class="text-center text-xl text-bold" translate>Rok</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="lata" id="ktory_rok" formControlName="ktory_rok" [ngClass]="{'dirtyinput': form.controls.ktory_rok.invalid}">
				</p-dropdown>
			</div>
			<div class="formwrapperrow33"></div>



			<div class="formwrappermessage">
				<h1 translate>DANE OSOBOWE</h1>
			</div>


			<!-- pos_4_vorname -->
			<div class="formwrapperrow33">
				<label for="pos_4_vorname" translate>Imię:</label>
				<input type="text" id="pos_4_vorname" pInputText formControlName="pos_4_vorname" [ngClass]="{'dirtyinput': form.controls.pos_4_vorname.invalid}">
			</div>

			<!-- pos_4_name -->
			<div class="formwrapperrow33">
				<label for="pos_4_name" translate>Nazwisko:</label>
				<input type="text" id="pos_4_name" pInputText formControlName="pos_4_name" [ngClass]="{'dirtyinput': form.controls.pos_4_name.invalid}">
			</div>

			<!-- pos_6_geburtsdatum -->
			<div class="formwrapperrow33">
				<label for="pos_6_geburtsdatum" translate>Data urodzenia: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="pos_6_geburtsdatum" pInputText formControlName="pos_6_geburtsdatum" [ngClass]="{'dirtyinput': form.controls.pos_6_geburtsdatum.invalid}"></p-inputMask>
			</div>

			<!-- telefon -->
			<div class="formwrapperrow50">
				<label for="telefon" translate>Telefon:</label>
				<input type="text" id="telefon" pInputText formControlName="telefon" [ngClass]="{'dirtyinput': form.controls.telefon.invalid}">
			</div>

			<!-- email -->
			<div class="formwrapperrow50">
				<label for="email" translate>Email:</label>
				<input type="text" id="email" pInputText formControlName="email" [ngClass]="{'dirtyinput': form.controls.email.invalid}">
			</div>



			<!-- stan_cywilny p-dropdown -->
			<div class="formwrapperrow33 mb-4">
				<label for="stan_cywilny" translate>Stan cywilny:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="stany_cywilne" id="stan_cywilny" formControlName="stan_cywilny" [ngClass]="{'dirtyinput': form.controls.stan_cywilny.invalid}">
				</p-dropdown>
			</div>

			<!-- religia p-dropdown -->
			<div class="formwrapperrow33 mb-4">
				<label for="religia" translate>Religia:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="religie" id="religia" [editable]="true" formControlName="religia" [ngClass]="{'dirtyinput': form.controls.religia.invalid}">
				</p-dropdown>
			</div>

			<div class="formwrapperrow33"></div>

			<!-- data_slubu -->
			<div class="formwrapperrow33 mb-4">
				<label for="data_slubu" translate>Data ślubu: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="data_slubu" pInputText formControlName="data_slubu" [ngClass]="{'dirtyinput': form.controls.data_slubu.invalid}"></p-inputMask>
			</div>

			<!-- data_rozwodu -->
			<div class="formwrapperrow33">
				<label for="data_rozwodu" translate>Data rozwodu: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="data_rozwodu" pInputText formControlName="data_rozwodu" [ngClass]="{'dirtyinput': form.controls.data_rozwodu.invalid}"></p-inputMask>
			</div>

			<!-- data_owdowienia -->
			<div class="formwrapperrow33">
				<label for="data_owdowienia" translate>Data owdowienia: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="data_owdowienia" pInputText formControlName="data_owdowienia" [ngClass]="{'dirtyinput': form.controls.data_owdowienia.invalid}"></p-inputMask>
			</div>




			<div class="formwrappermessage">
				<h1 translate>MIEJSCE STAŁEGO ZAMIESZKANIA</h1>
			</div>

			<!-- land -->
			<div class="formwrapperrow25">
				<label for="land" translate>Kraj:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" 	 [options]="lands" formControlName="land" [showClear]="true" [ngClass]="{'dirtyinput': form.controls.land.invalid}">
				</p-dropdown>
			</div>

			<!-- wojewodztwo -->
			<div class="formwrapperrow25">
				<label for="wojewodztwo" translate>Województwo:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="wojewodztwa" formControlName="wojewodztwo" [ngClass]="{'dirtyinput': form.controls.wojewodztwo.invalid}">
				</p-dropdown>
			</div>

			<!-- powiat -->
			<div class="formwrapperrow25">
				<!-- <label for="powiat" translate>Powiat</label>
				<input type="text" id="powiat" pInputText formControlName="powiat" [ngClass]="{'dirtyinput': form.controls.powiat.invalid}"> -->
			</div>

			<!-- gmina -->
			<div class="formwrapperrow25">
				<!-- <label for="gmina" translate>Gmina</label>
				<input type="text" id="gmina" pInputText formControlName="gmina" [ngClass]="{'dirtyinput': form.controls.gmina.invalid}"> -->
			</div>

			
			<!-- pos_7_strabe -->
			<div class="formwrapperrow50">
				<label for="pos_7_strabe" translate>Ulica</label>
				<input type="text" id="pos_7_strabe" pInputText formControlName="pos_7_strabe" [ngClass]="{'dirtyinput': form.controls.pos_7_strabe.invalid}">
			</div>

			<!-- pos_8_hausnummer -->
			<div class="formwrapperrow25">
				<label for="pos_8_hausnummer" translate>Numer domu</label>
				<input type="text" id="pos_8_hausnummer" pInputText formControlName="pos_8_hausnummer" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummer.invalid}">
			</div>

			<!-- pos_8_hausnummerzusats -->
			<div class="formwrapperrow25">
				<label for="pos_8_hausnummerzusats" translate>Numer mieszkania</label>
				<input type="text" id="pos_8_hausnummerzusats" pInputText formControlName="pos_8_hausnummerzusats" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummerzusats.invalid}">
			</div>

			<!-- pos_9_postleitzahl -->
			<div class="formwrapperrow25">
				<label for="pos_9_postleitzahl" translate>Kod pocztowy</label>
				<input type="text" id="pos_9_postleitzahl" pInputText formControlName="pos_9_postleitzahl" [ngClass]="{'dirtyinput': form.controls.pos_9_postleitzahl.invalid}">
			</div>

			<!-- poczta -->
			<div class="formwrapperrow25">
				<label for="poczta" translate>Miejscowość poczty</label>
				<input type="text" id="poczta" pInputText formControlName="poczta" [ngClass]="{'dirtyinput': form.controls.poczta.invalid}">
			</div>

			<div class="formwrapperrow50"></div>


			<div class="formwrappermessage">
				<h1 translate>Dane potrzebne do rozliczenia podatkowego</h1>
			</div>


			<!-- czy_posiadasz_numer_podatkowy -->
			<div class="formwrapperrow50">
				<label for="czy_posiadasz_numer_podatkowy" translate>Czy posiadasz numer podatkowy?</label>
				<p-dropdown #czynumer [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" option-label="name" option-value="id" id="czy_posiadasz_numer_podatkowy" formControlName="czy_posiadasz_numer_podatkowy" [ngClass]="{'dirtyinput': form.controls.czy_posiadasz_numer_podatkowy.invalid}">
				</p-dropdown>
			</div>

			<!-- numer_podatkowy -->
			<div class="formwrapperrow50">
				<ng-container *ngIf="czynumer?.value == 'TAK'">
					<label for="numer_podatkowy" translate>Numer podatkowy</label>
					<input type="text" id="numer_podatkowy" pInputText formControlName="numer_podatkowy" [ngClass]="{'dirtyinput': form.controls.numer_podatkowy.invalid}">
				</ng-container>
			</div>

			<!-- nip -->
			<div class="formwrapperrow50"></div>
			<div class="formwrapperrow50">
				<ng-container *ngIf="czynumer.value == 'TAK' ">
					<label for="nip" translate>NIP</label>
					<input type="text" id="nip" pInputText formControlName="nip" [ngClass]="{'dirtyinput': form.controls.nip.invalid}">
				</ng-container>
			</div>


			<!-- numer_id -->
			<div class="formwrapperrow50"></div>
			<div class="formwrapperrow50">
				<ng-container *ngIf="czynumer.value == 'TAK' ">
					<label for="numer_id" translate>Numer ID</label>
					<input type="text" id="numer_id" pInputText formControlName="numer_id" [ngClass]="{'dirtyinput': form.controls.numer_id.invalid}">
				</ng-container>
			</div>


			<!-- osoba_fizyczna -->
			<div class="formwrapperrow50">
				<label for="osoba_fizyczna" translate>Osoba fizyczna</label>
				<p-checkbox (onChange)="kontra('osoba_fizyczna')" formControlName="osoba_fizyczna" [binary]="true" inputId="osoba_fizyczna" [ngClass]="{'dirtyinput': form.controls.osoba_fizyczna.invalid}"></p-checkbox>
			</div>

			<!-- osoba_nie_fizyczna -->
			<div class="formwrapperrow50">
				<label for="osoba_nie_fizyczna" translate>Osoba nie fizyczna</label>
				<p-checkbox (onChange)="kontra('osoba_nie_fizyczna')" formControlName="osoba_nie_fizyczna" [binary]="true" inputId="osoba_nie_fizyczna" [ngClass]="{'dirtyinput': form.controls.osoba_nie_fizyczna.invalid}"></p-checkbox>
			</div>


			<!-- urzad_skarbowy_de -->
			<div class="formwrapperrow50">
				<label for="urzad_skarbowy_de" translate>Adres lub identyfikator urzędu skarbowego</label>
				<input type="text" id="urzad_skarbowy_de" pInputText formControlName="urzad_skarbowy_de" [ngClass]="{'dirtyinput': form.controls.urzad_skarbowy_de.invalid}">
			</div>

			<!-- co_bedzie_rozliczone input text -->
			<div class="formwrapperrow50">
				<label for="co_bedzie_rozliczone" translate>Co będzie rozliczone?</label>
				<input type="text" id="co_bedzie_rozliczone" pInputText formControlName="co_bedzie_rozliczone" [ngClass]="{'dirtyinput': form.controls.co_bedzie_rozliczone.invalid}">
			</div>

			<!-- zameldowanie_w_us -->
			<!-- <div class="formwrapperrow50">
				<label for="zameldowanie_w_us" translate>Zameldowanie w Urzędzie Skarbowym</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="zameldowanie_w_us" formControlName="zameldowanie_w_us" [ngClass]="{'dirtyinput': form.controls.zameldowanie_w_us.invalid}">
					<option [value]="" translate>wybierz</option>
				</p-dropdown>
			</div> -->

			<!-- bylo_juz_rozliczenie -->
			<div class="formwrapperrow50">
				<label for="bylo_juz_rozliczenie" translate>Było już robione rozliczenie?</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="bylo_juz_rozliczenie" formControlName="bylo_juz_rozliczenie" [ngClass]="{'dirtyinput': form.controls.bylo_juz_rozliczenie.invalid}">
					<option [value]="" translate>wybierz</option>
				</p-dropdown>
			</div>

			<!-- otrzymalem_rozlicznie -->
			<div class="formwrapperrow50">
				<label for="otrzymalem_rozlicznie" translate>Otrzymałem rozliczenie w formie Bescheid</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="otrzymalem_rozlicznie" formControlName="otrzymalem_rozlicznie" [ngClass]="{'dirtyinput': form.controls.otrzymalem_rozlicznie.invalid}">
					<option [value]="" translate>wybierz</option>
				</p-dropdown>
			</div>

			<!-- pobierasz_zasilek_rodzinny -->
			<div class="formwrapperrow50">
				<label for="pobierasz_zasilek_rodzinny" translate>Pobierasz zasiłek rodzinny</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="pobierasz_zasilek_rodzinny" formControlName="pobierasz_zasilek_rodzinny" [ngClass]="{'dirtyinput': form.controls.pobierasz_zasilek_rodzinny.invalid}">
					<option [value]="" translate>wybierz</option>
				</p-dropdown>
			</div>

			<div class="formwrapperrow50">
				<label for="zawod_wykonywany" translate>Zawód wykonywany:</label>
				<input id="zawod_wykonywany" type="text" formControlName="zawod_wykonywany" [ngClass]="{'dirtyinput': form.controls.zawod_wykonywany.invalid}">
			</div>

			<!-- masz_niesprawnosci xxxxxxxxxxxxxxxxxxxxxx -->
			<!-- <div class="formwrapperrow50"> -->
				<!-- <label for="masz_niesprawnosci" translate>Masz niesprawności</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="masz_niesprawnosci" formControlName="masz_niesprawnosci" [ngClass]="{'dirtyinput': form.controls.masz_niesprawnosci.invalid}">
				</p-dropdown> -->
			<!-- </div> -->


			<div class="formwrapperrow50"></div>

			<div class="formwrappermessage" >
				<h1 translate>Zaświadczenie EOG/EWR</h1>
				<p translate>Uzyskanie EOG/EWR w Polsce jest nieodzowne by otrzymać maksymalną kwotę zwolnienia z podatku oraz uzyskanie dobrej stopy podatkowej.	Uzyskanie EOG wiąże się ze złożeniem w urzędzie skarbowym wniosku o wydanie EOG/EWR. Takie zaświadczenie możemy wykonać również dla Państwa<br><br>				Zaświadczenie to jest tylko dla osób zamieszkałych w Polsce.</p>
				<br>
				<p translate>Jeżeli musisz posiadać EOG/EWR i go jeszcze nie masz, to możesz nam to zlecić. Zaznacz poniżej swój wybór.</p>
			</div>

			<!-- posiadasz_juz_zaswiadczenie_eog_ewr -->
			<div class="formwrapperrow50 mb-4">
				<label for="posiadasz_juz_zaswiadczenie_eog_ewr" translate>Posiadasz już zaświadczenie EOG/EWR</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="posiadasz_juz_zaswiadczenie_eog_ewr" formControlName="posiadasz_juz_zaswiadczenie_eog_ewr" [ngClass]="{'dirtyinput': form.controls.posiadasz_juz_zaswiadczenie_eog_ewr.invalid}">
				</p-dropdown>
			</div>

			<!-- eog_ewr_zalatwie_same_zlecam -->
			<div class="formwrapperrow50 mb-4">
				<label for="eog_ewr_zalatwie_same_zlecam" translate>Wybierz czy nam zlecasz czy załatwisz sam/sama.</label>
				<p-dropdown [placeholder]="czynamzlecasz" optionLabel="label" optionValue="value" [options]="czyzlecam" id="eog_ewr_zalatwie_same_zlecam" formControlName="eog_ewr_zalatwie_same_zlecam" [ngClass]="{'dirtyinput': form.controls.eog_ewr_zalatwie_same_zlecam.invalid}">
				</p-dropdown>
			</div>



			<!-- dzieci -->
			<div class="formwrappermessage">
				<h1 translate>Informacje o dzieciach</h1>
				<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="dodajDzieci(); $event.preventDefault();" translate>Dodaj dziecko</button>
			</div>


			<ng-container formArrayName="dzieci" *ngFor="let dziecko of dzieci.controls; let i=index;">

				<div class="formwrappermessage">
					<div><span translate>Informacje dotyczące dziecka nr</span> {{i+1}}</div>
					<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="usunDziecko(i); $event.preventDefault();" translate>usuń</button>
				</div>

				<!-- dziecko_imie input text -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'dziecko_imie_'+i" translate>Imię dziecka:</label>
					<input type="text" [id]="'dziecko_imie_'+i" formControlName="dziecko_imie" [ngClass]="{'dirtyinput': dziecko.controls.dziecko_imie.invalid}">
				</div>

				<!-- dziecko_nazwisko input text -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'dziecko_nazwisko_'+i" translate>Nazwisko dziecka:</label>
					<input type="text" [id]="'dziecko_nazwisko_'+i" formControlName="dziecko_nazwisko" [ngClass]="{'dirtyinput': dziecko.controls.dziecko_nazwisko.invalid}">
				</div>

				<!-- dziecko_data_urodzenia input text -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'dziecko_data_urodzenia_'+i" translate>Data urodzenia dziecka: [dd.mm.rrrr]</label>
					<p-inputMask mask="99.99.9999" type="text" [id]="'dziecko_data_urodzenia_'+i" formControlName="dziecko_data_urodzenia" [ngClass]="{'dirtyinput': dziecko.controls.dziecko_data_urodzenia.invalid}"></p-inputMask>					
				</div>

				<!-- dziecko_miejsce_i_okres_nauki input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'dziecko_miejsce_i_okres_nauki_'+i" translate>Miejsce i okres nauki dziecka:</label>
					<input type="text" [id]="'dziecko_miejsce_i_okres_nauki_'+i" formControlName="dziecko_miejsce_i_okres_nauki" [ngClass]="{'dirtyinput': dziecko.controls.dziecko_miejsce_i_okres_nauki.invalid}">
				</div>

				<!-- dziecko_dane_dot_dochodow_wlasnych_dzieci input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'dziecko_dane_dot_dochodow_wlasnych_dzieci_'+i" translate>Dane dotyczące dochodów własnych dziecka:</label>
					<input type="text" [id]="'dziecko_dane_dot_dochodow_wlasnych_dzieci_'+i" formControlName="dziecko_dane_dot_dochodow_wlasnych_dzieci" [ngClass]="{'dirtyinput': dziecko.controls.dziecko_dane_dot_dochodow_wlasnych_dzieci.invalid}">
				</div>

			</ng-container>

			<div class="formwrappermessage">
				<h1 translate>Informacje dotyczące zamieszkania oraz miejsca prowadzenia działalności gospodarczej</h1>
			</div>

			<!-- prowadzisz_gospodarstwo_domowe_w_pl -->
			<div class="formwrapperrow50 mb-4">
				<label for="prowadzisz_gospodarstwo_domowe_w_pl" translate>Prowadzisz gospodarstwo domowe w Polsce</label>
				<p-dropdown #pgosppl [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="prowadzisz_gospodarstwo_domowe_w_pl" formControlName="prowadzisz_gospodarstwo_domowe_w_pl" [ngClass]="{'dirtyinput': form.controls.prowadzisz_gospodarstwo_domowe_w_pl.invalid}">
				</p-dropdown>
			</div>

			<!-- prowadzisz_gospodarstwo_domowe_w_de -->
			<div class="formwrapperrow50 mb-4">
				<label for="prowadzisz_gospodarstwo_domowe_w_de" translate>Prowadzisz gospodarstwo domowe w Niemczech</label>
				<p-dropdown #pgospde [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="prowadzisz_gospodarstwo_domowe_w_de" formControlName="prowadzisz_gospodarstwo_domowe_w_de" [ngClass]="{'dirtyinput': form.controls.prowadzisz_gospodarstwo_domowe_w_de.invalid}">
				</p-dropdown>
			</div>

			<ng-container *ngIf="pgosppl.value == 'TAK'">
				<div class="formwrappermessage">
					<h1 translate>Informacje dotyczące zamieszkania w Polsce</h1>
				</div>

				
				<!-- gospodarstwo_domowe_ulica_pl -->
				<div class="formwrapperrow50">
					<label for="gospodarstwo_domowe_ulica_pl" translate>ulica</label>
					<input type="text" id="gospodarstwo_domowe_ulica_pl" pInputText formControlName="gospodarstwo_domowe_ulica_pl" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_ulica_pl.invalid}">
				</div>

				<!-- gospodarstwo_domowe_ulica_numer_pl -->
				<div class="formwrapperrow25">
					<label for="gospodarstwo_domowe_ulica_numer_pl" translate>numer domu/mieszkania</label>
					<input type="text" id="gospodarstwo_domowe_ulica_numer_pl" pInputText formControlName="gospodarstwo_domowe_ulica_numer_pl" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_ulica_numer_pl.invalid}">
				</div>
				<div class="formwrapperrow25"></div>

				<!-- gospodarstwo_domowe_kod_pl input text -->
				<div class="formwrapperrow25">
					<label for="gospodarstwo_domowe_kod_pl" translate>kod pocztowy</label>
					<input type="text" id="gospodarstwo_domowe_kod_pl" pInputText formControlName="gospodarstwo_domowe_kod_pl" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_kod_pl.invalid}">
				</div>

				<!-- gospodarstwo_domowe_miejscowosc_pl -->
				<div class="formwrapperrow50">
					<label for="gospodarstwo_domowe_miejscowosc_pl" translate>miejscowość</label>
					<input type="text" id="gospodarstwo_domowe_miejscowosc_pl" pInputText formControlName="gospodarstwo_domowe_miejscowosc_pl" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_miejscowosc_pl.invalid}">
				</div>
				<div class="formwrapperrow25"></div>

				<!-- adres_zameldowania_w_polsce -->
				<!-- <div class="formwrapperrow50">
					<label for="adres_zameldowania_w_polsce" translate>adres zameldowania w Polsce?</label>
					<input type="text" id="adres_zameldowania_w_polsce" pInputText formControlName="adres_zameldowania_w_polsce" [ngClass]="{'dirtyinput': form.controls.adres_zameldowania_w_polsce.invalid}">
				</div> -->
			</ng-container>

			<ng-container *ngIf="pgospde.value == 'TAK'">
				<div class="formwrappermessage">
					<h1 translate>Informacje dotyczące zamieszkania w Niemczech</h1>
				</div>

				<!-- gospodarstwo_domowe_ulica_de -->
				<div class="formwrapperrow50">
					<label for="gospodarstwo_domowe_ulica_de" translate>ulica</label>
					<input type="text" id="gospodarstwo_domowe_ulica_de" pInputText formControlName="gospodarstwo_domowe_ulica_de" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_ulica_de.invalid}">
				</div>

				<!-- gospodarstwo_domowe_ulica_numer_de -->
				<div class="formwrapperrow50">
					<label for="gospodarstwo_domowe_ulica_numer_de" translate>numer domu/mieszkania</label>
					<input type="text" id="gospodarstwo_domowe_ulica_numer_de" pInputText formControlName="gospodarstwo_domowe_ulica_numer_de" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_ulica_numer_de.invalid}">
				</div>

				<!-- gospodarstwo_domowe_kod_de -->
				<div class="formwrapperrow25">
					<label for="gospodarstwo_domowe_kod_de" translate>kod pocztowy</label>
					<input type="text" id="gospodarstwo_domowe_kod_de" pInputText formControlName="gospodarstwo_domowe_kod_de" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_kod_de.invalid}">
				</div>

				<!-- gospodarstwo_domowe_miejscowosc_de -->
				<div class="formwrapperrow50">
					<label for="gospodarstwo_domowe_miejscowosc_de" translate>miejscowość</label>
					<input type="text" id="gospodarstwo_domowe_miejscowosc_de" pInputText formControlName="gospodarstwo_domowe_miejscowosc_de" [ngClass]="{'dirtyinput': form.controls.gospodarstwo_domowe_miejscowosc_de.invalid}">
				</div>

				<!-- adres_zameldowania_w_niemczech -->
				<div class="formwrapperrow50">
					<!-- <label for="adres_zameldowania_w_niemczech" translate>adres zameldowania w Niemczech?</label>
					<input type="text" id="adres_zameldowania_w_niemczech" pInputText formControlName="adres_zameldowania_w_niemczech" [ngClass]="{'dirtyinput': form.controls.adres_zameldowania_w_niemczech.invalid}"> -->
				</div>

			</ng-container>




			<div class="formwrappermessage">
				<h1 translate>Informacje dotyczące Twojego zatrudnienia</h1>
				<p translate>Do wypełnienia tylko przez osoby zatrudnione w DE - nie na Gewerbe</p>
			</div>

			<!-- ile_dni_pracujesz_w_tygodniu -->
			<div class="formwrapperrow50">
				<label for="ile_dni_pracujesz_w_tygodniu" translate>ile dni pracujesz w tygodniu?</label>
				<input type="text" id="ile_dni_pracujesz_w_tygodniu" pInputText formControlName="ile_dni_pracujesz_w_tygodniu" [ngClass]="{'dirtyinput': form.controls.ile_dni_pracujesz_w_tygodniu.invalid}">
			</div>

			<!-- ile_km_masz_do_pracy -->
			<div class="formwrapperrow50">
				<label for="ile_km_masz_do_pracy" translate>ile km masz do pracy?</label>
				<input type="text" id="ile_km_masz_do_pracy" pInputText formControlName="ile_km_masz_do_pracy" [ngClass]="{'dirtyinput': form.controls.ile_km_masz_do_pracy.invalid}">
			</div>

			<!-- ile_masz_urlopu_w_roku -->
			<div class="formwrapperrow50">
				<label for="ile_masz_urlopu_w_roku" translate>ile masz urlopu w roku?</label>
				<input type="text" id="ile_masz_urlopu_w_roku" pInputText formControlName="ile_masz_urlopu_w_roku" [ngClass]="{'dirtyinput': form.controls.ile_masz_urlopu_w_roku.invalid}">
			</div>








			<!-- pracodawcy -->
			<div class="formwrappermessage">
				<h1 translate>PRACODAWCY</h1>
					<p translate>(jeśli pracujesz w kilku firmach, proszę o podanie danych dotyczących najważniejszej z nich)</p>
				<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="dodajPracodawce(); $event.preventDefault();" translate>Dodaj informacje o miejscu zatrudnienia</button>
			</div>

			<ng-container formArrayName="pracodawcy" *ngFor="let pracodawca of pracodawcy.controls; let i=index;">

				<div class="formwrappermessage">
					<div><span translate>Informacje dotyczące miejsca pracy nr</span> {{i+1}}</div>
					<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="usunPracodawce(i); $event.preventDefault();" translate>usuń</button>
				</div>

				<!-- pracodawca_nazwa input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'pracodawca_nazwa_'+i" translate>Nazwa firmy:</label>
					<input type="text" [id]="'pracodawca_nazwa_'+i" pInputText formControlName="pracodawca_nazwa" [ngClass]="{'dirtyinput': pracodawca.controls.pracodawca_nazwa.invalid}">
				</div>

				<!-- pracodawca_adres input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'pracodawca_adres_'+i" translate>Adres firmy:</label>
					<input type="text" [id]="'pracodawca_adres_'+i" pInputText formControlName="pracodawca_adres" [ngClass]="{'dirtyinput': pracodawca.controls.pracodawca_adres.invalid}">
				</div>

				<!-- numer_seryjny_auta_dojazdu -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'numer_seryjny_auta_dojazdu_'+i" translate>Jeżeli dojeżdżasz swoim autem, podaj numer rejestracyjny auta:</label>
					<input type="text" [id]="'numer_seryjny_auta_dojazdu_'+i" pInputText formControlName="numer_seryjny_auta_dojazdu" [ngClass]="{'dirtyinput': pracodawca.controls.numer_seryjny_auta_dojazdu.invalid}">
				</div>

			</ng-container>





			<!-- adres_zamieszkania_w_niemczech -->
			<div class="formwrappermessage">
				<h1 translate>Dodaj informacje o miejscach zamieszkania w Niemczech</h1>
				<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="dodajAdresZamieszkaniaWNiemczech(); $event.preventDefault();" translate>Dodaj informacje o miejscu zamieszkania</button>
			</div>

			<ng-container formArrayName="adres_zamieszkania_w_niemczech" *ngFor="let adres_de of adres_zamieszkania_w_niemczech.controls; let i=index;">

				<div class="formwrappermessage">
					<div><span translate>Informacje dotyczące miejsca zamieszkania nr</span>{{i+1}}</div>
					<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="usunAdresZamieszkaniaWNiemczech(i); $event.preventDefault();" translate>usuń</button>
				</div>

				<!-- adres_zamieszkania_w_niemczech_adres input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'adres_zamieszkania_w_niemczech_adres_'+i" translate>Adres:</label>
					<input type="text" [id]="'adres_zamieszkania_w_niemczech_adres_'+i" pInputText formControlName="adres_zamieszkania_w_niemczech_adres" [ngClass]="{'dirtyinput': adres_de.controls.adres_zamieszkania_w_niemczech_adres.invalid}">
				</div>

				<!-- adres_zamieszkania_w_niemczech_okres_pobytu input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'adres_zamieszkania_w_niemczech_okres_pobytu_'+i" translate>Okres pobytu:</label>
					<input type="text" [id]="'adres_zamieszkania_w_niemczech_okres_pobytu_'+i" pInputText formControlName="adres_zamieszkania_w_niemczech_okres_pobytu" [ngClass]="{'dirtyinput': adres_de.controls.adres_zamieszkania_w_niemczech_okres_pobytu.invalid}">
				</div>

				<!-- adres_zamieszkania_w_niemczech_adres_wykonywanej_pracy_w_niemczech input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'adres_zamieszkania_w_niemczech_adres_wykonywanej_pracy_w_niemczech_'+i" translate>Adres wykonywanej pracy w Niemczech:</label>
					<input type="text" [id]="'adres_zamieszkania_w_niemczech_adres_wykonywanej_pracy_w_niemczech_'+i" pInputText formControlName="adres_zamieszkania_w_niemczech_adres_wykonywanej_pracy_w_niemczech" [ngClass]="{'dirtyinput': adres_de.controls.adres_zamieszkania_w_niemczech_adres_wykonywanej_pracy_w_niemczech.invalid}">
				</div>

				<!-- adres_zamieszkania_w_niemczech_odleglosc_de_de input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'adres_zamieszkania_w_niemczech_odleglosc_de_de_'+i" translate>Odległość do pracy w Niemczech:</label>
					<input type="text" [id]="'adres_zamieszkania_w_niemczech_odleglosc_de_de_'+i" pInputText formControlName="adres_zamieszkania_w_niemczech_odleglosc_de_de" [ngClass]="{'dirtyinput': adres_de.controls.adres_zamieszkania_w_niemczech_odleglosc_de_de.invalid}">
				</div>

				<!-- adres_zamieszkania_w_niemczech_odleglosc_pl_de input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'adres_zamieszkania_w_niemczech_odleglosc_pl_de_'+i" translate>Odległość do pracy w Polsce:</label>
					<input type="text" [id]="'adres_zamieszkania_w_niemczech_odleglosc_pl_de_'+i" pInputText formControlName="adres_zamieszkania_w_niemczech_odleglosc_pl_de" [ngClass]="{'dirtyinput': adres_de.controls.adres_zamieszkania_w_niemczech_odleglosc_pl_de.invalid}">
				</div>

			</ng-container>












			<div class="formwrappermessage">
				<h1 translate>Jeżeli rozliczasz się z małżonkiem</h1>
			</div>


			<!-- rozliczenie_z_malzonkiem taknie -->
			<div class="formwrapperrow50 mb-4">
				<label for="rozliczenie_z_malzonkiem" translate>Rozliczenie z małżonkiem:</label>
				<p-dropdown #rozlzmal [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="rozliczenie_z_malzonkiem"            formControlName="rozliczenie_z_malzonkiem"            [ngClass]="{'dirtyinput': form.controls.rozliczenie_z_malzonkiem.invalid}">
				</p-dropdown>
			</div>

			<ng-container *ngIf="rozlzmal.value=='TAK'">
				<!-- imie_malzonka -->
				<div class="formwrapperrow50">
					<label for="imie_malzonka" translate>Imię małżonka:</label>
					<input type="text" id="imie_malzonka" pInputText formControlName="imie_malzonka" [ngClass]="{'dirtyinput': form.controls.imie_malzonka.invalid}">
				</div>

				<!-- nazwisko_malzonka -->
				<div class="formwrapperrow50">
					<label for="nazwisko_malzonka" translate>Nazwisko małżonka:</label>
					<input type="text" id="nazwisko_malzonka" pInputText formControlName="nazwisko_malzonka" [ngClass]="{'dirtyinput': form.controls.nazwisko_malzonka.invalid}">
				</div>

				<!-- nazwisko_panienskie_malzonka -->
				<div class="formwrapperrow50">
					<label for="nazwisko_panienskie_malzonka" translate>Nazwisko panieńskie małżonka:</label>
					<input type="text" id="nazwisko_panienskie_malzonka" pInputText formControlName="nazwisko_panienskie_malzonka" [ngClass]="{'dirtyinput': form.controls.nazwisko_panienskie_malzonka.invalid}">
				</div>

				<!-- data_urodzenia_malzonka -->
				<div class="formwrapperrow50">
					<label for="data_urodzenia_malzonka" translate>Data urodzenia małżonka: [dd.mm.rrrr]</label>
					<p-inputMask mask="99.99.9999" type="text" id="data_urodzenia_malzonka" pInputText formControlName="data_urodzenia_malzonka" [ngClass]="{'dirtyinput': form.controls.data_urodzenia_malzonka.invalid}"></p-inputMask>
				</div>

				<div class="formwrappermessage">
					<h1 translate>KONTO BANKOWE</h1>
				</div>				

				<!-- adres_malzonka -->
				<div class="formwrapperrow50">
					<label for="adres_malzonka" translate>Adres małżonka:</label>
					<input type="text" id="adres_malzonka" pInputText formControlName="adres_malzonka" [ngClass]="{'dirtyinput': form.controls.adres_malzonka.invalid}">
				</div>

				<!-- zaswoadczenie_ue_eog_malzonka taknie -->
				<div class="formwrapperrow50">
					<label for="zaswoadczenie_ue_eog_malzonka" translate>Czy małżonek posiada zaświadczenie UE/EOG:</label>
					<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="zaswoadczenie_ue_eog_malzonka" formControlName="zaswoadczenie_ue_eog_malzonka" [ngClass]="{'dirtyinput': form.controls.zaswoadczenie_ue_eog_malzonka.invalid}">
					</p-dropdown>
				</div>

				<!-- czy_malzonek_przebywa_i_pracuje_w_de -->
				<div class="formwrapperrow50">
					<label for="czy_malzonek_przebywa_i_pracuje_w_de" translate>Czy małżonek przebywa i pracuje w DE:</label>
					<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="czy_malzonek_przebywa_i_pracuje_w_de" formControlName="czy_malzonek_przebywa_i_pracuje_w_de" [ngClass]="{'dirtyinput': form.controls.czy_malzonek_przebywa_i_pracuje_w_de.invalid}">
					</p-dropdown>
				</div>

				<!-- dochod_malzonka -->
				<div class="formwrapperrow50">
					<label for="dochod_malzonka" translate>Dochód małżonka:</label>
					<input type="text" id="dochod_malzonka" pInputText formControlName="dochod_malzonka" [ngClass]="{'dirtyinput': form.controls.dochod_malzonka.invalid}">
				</div>

				<!-- rozdzielnosc_majatkowa taknie -->
				<div class="formwrapperrow50">
					<label for="rozdzielnosc_majatkowa" translate>Czy małżonek posiada rozdzielnosc majatkowa:</label>
					<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="rozdzielnosc_majatkowa" formControlName="rozdzielnosc_majatkowa" [ngClass]="{'dirtyinput': form.controls.rozdzielnosc_majatkowa.invalid}">
					</p-dropdown>
				</div>

				<!-- religia_malzonka p-dropdown religie -->
				<div class="formwrapperrow50">
					<label for="religia_malzonka" translate>Religia małżonka:</label>
					<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="religie" [editable]="true" id="religia_malzonka" formControlName="religia_malzonka" [ngClass]="{'dirtyinput': form.controls.religia_malzonka.invalid}">
					</p-dropdown>
				</div>

			</ng-container>



			<div class="formwrappermessage">
				<h1 translate>Pozostałe dane potrzebne do rozliczenia.</h1>
				<p translate>UWAGA: proszę wpisać kwoty jeśli są, jeśli nie były płacone są nie ważne i nie piszemy nic!</p>
			</div>


			<!-- praca_w_innym_kraju_niz_de -->
			<div class="formwrapperrow33">
				<label for="praca_w_innym_kraju_niz_de" translate>Czy pracowałeś w innym kraju niż DE:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="praca_w_innym_kraju_niz_de" formControlName="praca_w_innym_kraju_niz_de" [ngClass]="{'dirtyinput': form.controls.praca_w_innym_kraju_niz_de.invalid}">
				</p-dropdown>
			</div>

			<!-- czy_byly_darowizny -->
			<div class="formwrapperrow33">
				<label for="czy_byly_darowizny" translate>Czy były darowizny:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="czy_byly_darowizny" formControlName="czy_byly_darowizny" [ngClass]="{'dirtyinput': form.controls.czy_byly_darowizny.invalid}">
				</p-dropdown>
			</div>

			<!-- czy_izba_zemieslnicza -->
			<div class="formwrapperrow33">
				<label for="czy_izba_zemieslnicza" translate>Czy należysz do izby ziemieślniczej:</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="czy_izba_zemieslnicza" formControlName="czy_izba_zemieslnicza" [ngClass]="{'dirtyinput': form.controls.czy_izba_zemieslnicza.invalid}">
				</p-dropdown>
			</div>

			<!-- praca_w_innym_kraju_niz_de_opis input text -->
			<div class="formwrapperrow33">
				<label for="praca_w_innym_kraju_niz_de_opis" translate>Podaj miejsce pracy w de</label>
				<input type="text" id="praca_w_innym_kraju_niz_de_opis" pInputText formControlName="praca_w_innym_kraju_niz_de_opis" [ngClass]="{'dirtyinput': form.controls.praca_w_innym_kraju_niz_de_opis.invalid}">
			</div>

			<!-- czy_byly_darowizny_koszt_roczny input text -->
			<div class="formwrapperrow33">
				<label for="czy_byly_darowizny_koszt_roczny" translate>Koszt roczny darowizn</label>
				<input type="text" id="czy_byly_darowizny_koszt_roczny" pInputText formControlName="czy_byly_darowizny_koszt_roczny" [ngClass]="{'dirtyinput': form.controls.czy_byly_darowizny_koszt_roczny.invalid}">
			</div>

			<!-- czy_izba_zemieslnicza_koszt_rocny input text -->
			<div class="formwrapperrow33">
				<label for="czy_izba_zemieslnicza_koszt_rocny" translate>Koszt roczny członkostwa</label>
				<input type="text" id="czy_izba_zemieslnicza_koszt_rocny" pInputText formControlName="czy_izba_zemieslnicza_koszt_rocny" [ngClass]="{'dirtyinput': form.controls.czy_izba_zemieslnicza_koszt_rocny.invalid}">
			</div>



			<!-- koszty_alimentow -->
			<!-- <div class="formwrapperrow25">
				<label for="koszty_alimentow" translate>Koszty alimentów:</label>
				<input type="text" id="koszty_alimentow" pInputText formControlName="koszty_alimentow" [ngClass]="{'dirtyinput': form.controls.koszty_alimentow.invalid}">
			</div> -->

			<!-- przeprowadzka input text -->
			<!-- <div class="formwrapperrow25">
				<label for="przeprowadzka" translate>Przeprowadzka:</label>
				<input id="przeprowadzka" type="text" formControlName="przeprowadzka" [ngClass]="{'dirtyinput': form.controls.przeprowadzka.invalid}">
			</div> -->


			<!-- zakup_odziezy input text -->
			<div class="formwrapperrow25">
				<label for="zakup_odziezy" translate>Zakup odzieży:</label>
				<input id="zakup_odziezy" type="text" formControlName="zakup_odziezy" [ngClass]="{'dirtyinput': form.controls.zakup_odziezy.invalid}">
			</div>

			<!-- zakup_sprzetu input text -->
			<div class="formwrapperrow25">
				<label for="zakup_sprzetu" class="whitespace-nowrap" translate>Zakup sprzętu: telefon, komputer</label>
				<input id="zakup_sprzetu" type="text" formControlName="zakup_sprzetu" [ngClass]="{'dirtyinput': form.controls.zakup_sprzetu.invalid}">
			</div>

			<!-- szukanie_pracy_honorarium input text -->
			<div class="formwrapperrow25">
				<label for="szukanie_pracy_honorarium" translate>Szukanie pracy honorarium:</label>
				<input id="szukanie_pracy_honorarium" type="text" formControlName="szukanie_pracy_honorarium" [ngClass]="{'dirtyinput': form.controls.szukanie_pracy_honorarium.invalid}">
			</div>

			<!-- koszty_leczenia input text -->
			<div class="formwrapperrow25">
				<label for="koszty_leczenia" translate>Koszty leczenia:</label>
				<input id="koszty_leczenia" type="text" formControlName="koszty_leczenia" [ngClass]="{'dirtyinput': form.controls.koszty_leczenia.invalid}">
			</div>

			<!-- doradztwo_podatkowe input text -->
			<div class="formwrapperrow25">
				<label for="doradztwo_podatkowe" translate>Doradztwo podatkowe:</label>
				<input id="doradztwo_podatkowe" type="text" formControlName="doradztwo_podatkowe" [ngClass]="{'dirtyinput': form.controls.doradztwo_podatkowe.invalid}">
			</div>

			<!-- koszty_biura_gewerbe input text -->
			<div class="formwrapperrow25">
				<label for="koszty_biura_gewerbe" translate>Koszty biura (Gewerbe):</label>
				<input id="koszty_biura_gewerbe" type="text" formControlName="koszty_biura_gewerbe" [ngClass]="{'dirtyinput': form.controls.koszty_biura_gewerbe.invalid}">
			</div>

			<!-- zwrot_kosztow_pracy_de_za_co	-->
			<div class="formwrapperrow25">
				<label for="zwrot_kosztow_pracy_de_za_co" translate>Zwrot kosztów pracy DE za co:</label>
				<input id="zwrot_kosztow_pracy_de_za_co" type="text" formControlName="zwrot_kosztow_pracy_de_za_co" [ngClass]="{'dirtyinput': form.controls.zwrot_kosztow_pracy_de_za_co.invalid}">
			</div>

			<!-- koszty_ubezpieczenia_zdrowotnego input text -->
			<div class="formwrapperrow25">
				<label for="koszty_ubezpieczenia_zdrowotnego" translate>Koszty ubezpieczenia zdrowotnego:</label>
				<input id="koszty_ubezpieczenia_zdrowotnego" type="text" formControlName="koszty_ubezpieczenia_zdrowotnego" [ngClass]="{'dirtyinput': form.controls.koszty_ubezpieczenia_zdrowotnego.invalid}">
			</div>

			<!-- dodatkowe_koszty_z_praca_w_de inpu text -->
			<div class="formwrapperrow25">
				<label for="dodatkowe_koszty_z_praca_w_de" translate>Dodatkowe koszty z praca w DE:</label>
				<input id="dodatkowe_koszty_z_praca_w_de" type="text" formControlName="dodatkowe_koszty_z_praca_w_de" [ngClass]="{'dirtyinput': form.controls.dodatkowe_koszty_z_praca_w_de.invalid}">
			</div>

			<!-- doksztalcanie input text -->
			<div class="formwrapperrow25">
				<label for="doksztalcanie" translate>Dokształcanie:</label>
				<input id="doksztalcanie" type="text" formControlName="doksztalcanie" [ngClass]="{'dirtyinput': form.controls.doksztalcanie.invalid}">
			</div>



			<!-- zwrot_kosztow_pracy_de -->
			<!-- <div class="formwrapperrow25">
				<label for="zwrot_kosztow_pracy_de" translate>Zwrot kosztów pracy DE:</label>
				<input id="zwrot_kosztow_pracy_de" type="text" formControlName="zwrot_kosztow_pracy_de" [ngClass]="{'dirtyinput': form.controls.zwrot_kosztow_pracy_de.invalid}">
			</div> -->


			<!-- czy_podatek_koscielny_de taknie -->
			<!-- <div class="formwrapperrow25">
				<label for="czy_podatek_koscielny_de" translate>Podatek kościelny DE:</label>
				<input id="czy_podatek_koscielny_de" type="text" formControlName="czy_podatek_koscielny_de" [ngClass]="{'dirtyinput': form.controls.czy_podatek_koscielny_de.invalid}">
			</div> -->






			<!-- rachunki -->
			<div class="formwrappermessage" translate>
				<h1 translate>Informacje dotyczące rachunków bankowych</h1>
				<p translate>Proszę wypełnić informacje o posiadanych rachunkach bankowych</p>
				<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="dodajRachunek(); $event.preventDefault();" translate>Dodaj informacje o posiadanym rachunku bankowym</button>
			</div>

			<ng-container formArrayName="rachunki" *ngFor="let rachunek of rachunki.controls; let i=index;">

				<div class="formwrappermessage" translate>
					<div>Informacje dotyczące rachunku nr {{i+1}}</div>					
					<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="usunRachunek(i); $event.preventDefault();">usuń</button>
				</div>

				<!-- rachunek_nazwa_banku input text -->
				<div class="formwrapperrow50" [formGroupName]="i">
					<label [for]="'rachunek_nazwa_banku_'+i" translate>Nazwa banku:</label>
					<input type="text" [id]="'rachunek_nazwa_banku_'+i" pInputText formControlName="rachunek_nazwa_banku" [ngClass]="{'dirtyinput': rachunek.controls.rachunek_nazwa_banku.invalid}">
				</div>

				<!-- miejscowosc_banku input text -->
				<div class="formwrapperrow50" [formGroupName]="+i">
					<label [for]="'miejscowosc_banku_'+i" translate>Miejscowość banku:</label>
					<input type="text" [id]="'miejscowosc_banku_'+i" pInputText formControlName="miejscowosc_banku" [ngClass]="{'dirtyinput': rachunek.controls.miejscowosc_banku.invalid}">
				</div>

				<!-- swift input text -->
				<div class="formwrapperrow25" [formGroupName]="+i">
					<label [for]="'rachunek_swift_'+i" translate>Swift:</label>
					<input type="text" [id]="'swift_'+i" pInputText formControlName="rachunek_swift" [ngClass]="{'dirtyinput': rachunek.controls.rachunek_swift.invalid}">
				</div>

				<!-- rachunek_numer input text -->
				<div class="formwrapperrow50" [formGroupName]="+i">
					<label [for]="'rachunek_numer_'+i" translate>Numer rachunku:</label>
					<input type="text" [id]="'rachunek_numer_'+i" pInputText formControlName="rachunek_numer" [ngClass]="{'dirtyinput': rachunek.controls.rachunek_numer.invalid}">
				</div>

			</ng-container>









			<div class="formwrappermessage">
				<h1 translate>Dane dotyczące banku związanego z niniejszym rozliczeniem</h1>
			</div>

			<!-- bank input text -->
			<div class="formwrapperrow50">
				<label for="bank" translate>Bank:</label>
				<input id="bank" type="text" formControlName="bank" [ngClass]="{'dirtyinput': form.controls.bank.invalid}">
			</div>

			<!-- swift -->
			<div class="formwrapperrow50">
				<label for="swift" translate>Swift:</label>
				<input id="swift" type="text" formControlName="swift" [ngClass]="{'dirtyinput': form.controls.swift.invalid}">
			</div>

			<!-- numer_konta -->
			<div class="formwrapperrow50">
				<label for="numer_konta" translate>Numer konta:</label>
				<input id="numer_konta" type="text" formControlName="numer_konta" [ngClass]="{'dirtyinput': form.controls.numer_konta.invalid}">
			</div>

			<!-- miejscowosc_banku -->
			<div class="formwrapperrow50">
				<label for="miejscowosc_banku" translate>Miejscowość banku:</label>
				<input id="miejscowosc_banku" type="text" formControlName="miejscowosc_banku" [ngClass]="{'dirtyinput': form.controls.miejscowosc_banku.invalid}">
			</div>

			<!-- wlasciciel_konta -->
			<div class="formwrapperrow50">
				<label for="wlasciciel_konta" translate>Właściciel konta:</label>
				<input id="wlasciciel_konta" type="text" formControlName="wlasciciel_konta" [ngClass]="{'dirtyinput': form.controls.wlasciciel_konta.invalid}">
			</div>



			<!-- miejscowosc_urzedu_skarbowego -->
			<div class="formwrapperrow50">
				<label for="miejscowosc_urzedu_skarbowego" translate>Miejscowość urzędu skarbowego:</label>
				<input id="miejscowosc_urzedu_skarbowego" type="text" formControlName="miejscowosc_urzedu_skarbowego" [ngClass]="{'dirtyinput': form.controls.miejscowosc_urzedu_skarbowego.invalid}">
			</div>


			<!-- podpis -->
			<!-- <div class="formwrapperrow50">
				<label for="podpis" translate>Podpis:</label>
				<input id="podpis" type="text" formControlName="podpis" [ngClass]="{'dirtyinput': form.controls.podpis.invalid}">
			</div> -->


			<!-- zawod_wykonywany -->
			<!-- <div class="formwrapperrow50">
				<label for="zawod_wykonywany" translate>Zawód wykonywany:</label>
				<input id="zawod_wykonywany" type="text" formControlName="zawod_wykonywany" [ngClass]="{'dirtyinput': form.controls.zawod_wykonywany.invalid}">
			</div> -->

			<div class="formwrappermessage">
				<h1 translate>Wsparcie finansowe</h1>
			</div>

			<!-- posiadasz_juz_zaswiadczenie_eog_ewr -->
			<div class="formwrapperrow50 mb-4">
				<label for="czy_chcesz_wsparcia" translate>Czy chcesz wsparcia?</label>
				<p-dropdown [placeholder]="wybierz" optionLabel="label" optionValue="value" [options]="taknie" id="czy_chcesz_wsparcia" formControlName="czy_chcesz_wsparcia" [ngClass]="{'dirtyinput': form.controls.czy_chcesz_wsparcia.invalid}">
				</p-dropdown>
			</div>


			<div class="formwrappermessage" translate>
			</div>



			<div class="formwrappermessagex">
				<h1 translate>Zgody RODO</h1>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_akceptacja_regulaminu" [binary]="true" inputId="rodo_akceptacja_regulaminu" [ngClass]="{'dirtyinput': form.controls.rodo_akceptacja_regulaminu.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate><span translate>Akceptuję</span> <a class="underline" [routerLink]="['/regulamin']" translate>regulamin</a><span translate>(POLE OBOWIĄZKOWE)</span></p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_przetwarzanie_danych" [binary]="true" inputId="rodo_przetwarzanie_danych" [ngClass]="{'dirtyinput': form.controls.rodo_przetwarzanie_danych.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie danych szczególnych kategoriizawartych w przesłanych dokumentach w celu dokonania wyceny oraz wykonania usługi. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposów uniemożliwiający nam przetwarzenia danych szczególnych kategorii (POLE OBOWIĄZKOWE)</p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_dane_marketingowe" [binary]="true" inputId="rodo_dane_marketingowe" [ngClass]="{'dirtyinput': form.controls.rodo_dane_marketingowe.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymania na ten adres email materiałów ofertowych dotyczących usług oferowanych przez CBB-Office GmbH</p>
				</div>
			</div>



			<div class="formwrapperrow">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>

		</div>
	</form>
</ng-container>

<pre>

</pre>