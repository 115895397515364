import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UserCard } from './../DTOs/userCard';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApperanceService } from 'src/app/apperance.service';

@Component({
	selector: 'register',
	templateUrl: './register.component.html',
	styleUrls: ['../scss/styles.scss','./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {

	sposobLogowania: string = 'phoneNumber';
	singinMethod$: any;
	userCard: UserCard = new UserCard()
	cpassword: string = '';
	message: string = '';
	countryCodes: any[] = [];

	fg: FormGroup = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		phoneNumber: new FormControl('', [Validators.required]),
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
		cpassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
		countryCode: new FormControl('+48'),
	})

	constructor(
		private apperanceService: ApperanceService,
		private authService: AuthCognitoService
	) { }

	ngOnInit(): void {
		this.onSwitchLoginMethod(this.authService.sposobLogowania);
		this.singinMethod$ = this.authService.onChangeMethodSignIn
		.subscribe(x => {
			this.onSwitchLoginMethod(x);
		})

		if(this.authService.rememberPhone) {
			this.fg.get('phoneNumber').setValue(this.authService.rememberPhone);
		}
		if(this.authService.rememberCountryCode) {
			this.fg.get('countryCode').setValue(this.authService.rememberCountryCode);
		}
		if(this.authService.rememberEmail) {
			this.fg.get('email').setValue(this.authService.rememberEmail);
		}

		this.apperanceService.getNumeryKierunkowe()
			.subscribe((x:any[]) => {
				this.countryCodes = x;
			})

	}

	onSwitchLoginMethod(method: string) {
		this.sposobLogowania = method;
		if(this.sposobLogowania === 'phoneNumber'){
			this.fg.get('email').disable();
			this.fg.get('phoneNumber').enable();
			this.fg.get('countryCode').enable();
		}else{
			this.fg.get('email').enable();
			this.fg.get('phoneNumber').disable();
			this.fg.get('countryCode').disable();
		}
	}

	ngOnDestroy(): void {
		if(this.singinMethod$) this.singinMethod$.unsubscribe();
	}


	RegisterIn() {
		if (this.fg.value.password != this.fg.value.cpassword) {
			this.authService.setMessage('password are not equal');
			return;
		}

		this.authService.rememberEmail = this.fg.value.email;
		this.authService.rememberPhone = this.fg.value.phoneNumber;
		this.authService.rememberCountryCode = this.fg.value.countryCode;

		this.userCard.email = this.fg.value.email;
		this.userCard.phoneNumber = this.fg.value.phoneNumber;
		this.userCard.password = this.fg.value.password;
		this.userCard.countryCode = this.fg.value.countryCode;

		this.authService.register(this.userCard);
	}

	changeView(view:string) {
		this.authService.changeView(view);
	}

}
