import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApperanceService } from 'src/app/apperance.service';

@Component({
	selector: 'forgot-password-step1',
	templateUrl: './forgot-password-step1.component.html',
	styleUrls: ['../scss/styles.scss', './forgot-password-step1.component.scss'],
})
export class ForgotPasswordStep1Component implements OnInit, OnDestroy {

	sposobLogowania: string = 'phoneNumber';
	singinMethod$: any;
	countryCodes: any[] = [];

	fg: FormGroup = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		phoneNumber: new FormControl('', [Validators.required]),
		countryCode: new FormControl('+48'),
	})

	constructor(
		private apperanceService: ApperanceService,
		private authService: AuthCognitoService
	) { }

	ngOnInit(): void {

		this.onSwitchLoginMethod(this.authService.sposobLogowania);
		this.singinMethod$ = this.authService.onChangeMethodSignIn
			.subscribe(x => {
				this.onSwitchLoginMethod(x);
			})

		if (this.authService.rememberPhone) {
			this.fg.get('phoneNumber').setValue(this.authService.rememberPhone);
		}
		if(this.authService.rememberCountryCode) {
			this.fg.get('countryCode').setValue(this.authService.rememberCountryCode);
		}
		if (this.authService.rememberEmail) {
			this.fg.get('email').setValue(this.authService.rememberEmail);
		}

		this.apperanceService.getNumeryKierunkowe()
			.subscribe((x: any[]) => {
				this.countryCodes = x;
			})

	}

	onSwitchLoginMethod(method: string) {
		this.sposobLogowania = method;
		if (this.sposobLogowania === 'phoneNumber') {
			this.fg.get('email').disable();
			this.fg.get('phoneNumber').enable();
			this.fg.get('countryCode').enable();
		} else {
			this.fg.get('email').enable();
			this.fg.get('phoneNumber').disable();
			this.fg.get('countryCode').disable();
		}
	}

	ngOnDestroy(): void {
		if (this.singinMethod$) this.singinMethod$.unsubscribe();
	}

	forgotPassword1() {
		this.authService.rememberEmail = this.fg.value.email;
		this.authService.rememberPhone = this.fg.value.phoneNumber;
		this.authService.forgotPassword1(this.fg.value);
	}

	changeView(view: string) {
		this.authService.changeView(view);
	}

}
