import { ICbbState } from './interfaces/icbbstate';
import { Report } from './report';
import { File } from './file';
import { UserConfig } from './user-config';
import { DokToSig } from './doktosig';

export class CbbState implements ICbbState {
	// public cognitoUserId: string = '';
	// public cognitoUserEmail: string = '';
	public cognitoUsername: string = '';
	public dateOfInsert: Date = new Date();
	public userConfig: UserConfig = new UserConfig();
	public reports: Array<Report> = [];
	public email:string = '';
	public phoneNumber: string = '';
	public uidEditedReport: number;
	public files: Array<File> = [];
}