<p-card>
	<app-info slug="kwestionariusz"></app-info>
</p-card>

<ng-container *ngIf="form">
	<form class="w-full my-6" [formGroup]="form" (ngSubmit)="send()">
		<div class="formwrapper mt-6">

			<div class="formwrapperrow33x"></div>

			<div class="formwrapperrow33x">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>
			<div class="formwrapperrow33x">
				<!-- <button (click)="turn('on')" *ngIf="disabled" pButton label="Edytuj" icon="pi pi-user-edit"></button> -->
				<button (click)="reset()" *ngIf="!disabled" pButton [label]="Anuluj" icon="pi pi-undo"></button>
			</div>

			<!-- <div class="formwrappermessagex">
				<h1 translate>Zgoda RODO</h1>
			</div>
			<div class="mt-0">
				<div class="flex">
					<div class="flex-1">
						<div class="formwrapperrow justify-center">
							<label for="administrator_danych_osobowych" class="text-center" translate>Wyrażam zgodę</label>
							<p-checkbox formControlName="administrator_danych_osobowych" [binary]="true" inputId="administrator_danych_osobowych" [ngClass]="{'dirtyinput': form.controls.administrator_danych_osobowych.invalid}"></p-checkbox>
						</div>
					</div>
					<div class="flex-3 pl-4">
						<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w niniejszym kwestionariuszu osobowym dla potrzeb niezbędnych do realizacji usługi przez CBBOffice (zgodnie z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych</p>
						<p translate>osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)).</p>
					</div>
				</div>
			</div> -->

			<div class="formwrappermessage">
				<h1 translate>Podstawowe dane osobowe.</h1>
			</div>

			<div class="formwrapperrow">
				<label for="pos_1_finanzamt" translate>Rodzaj działalności</label>
				<input type="text" id="pos_1_finanzamt" pInputText formControlName="pos_1_finanzamt" [ngClass]="{'dirtyinput': form.controls.pos_1_finanzamt.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_4_name" translate>Nazwisko:</label>
				<input type="text" id="pos_4_name" pInputText formControlName="pos_4_name" [ngClass]="{'dirtyinput': form.controls.pos_4_name.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_5_geburtsname" translate>Nazwisko panieńskie</label>
				<input type="text" id="pos_5_geburtsname" pInputText formControlName="pos_5_geburtsname" [ngClass]="{'dirtyinput': form.controls.pos_5_geburtsname.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_4_vorname" translate>Imię:</label>
				<input type="text" id="pos_4_vorname" pInputText formControlName="pos_4_vorname" [ngClass]="{'dirtyinput': form.controls.pos_4_vorname.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_6_geburtsdatum" translate>Data urodzenia: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="pos_6_geburtsdatum" pInputText formControlName="pos_6_geburtsdatum" [ngClass]="{'dirtyinput': form.controls.pos_6_geburtsdatum.invalid}"></p-inputMask>
			</div>

			<div class="formwrapperrow50">
				<label for="pos_7_strabe" translate>Ulica zamieszkania:</label>
				<input type="text" id="pos_7_strabe" pInputText formControlName="pos_7_strabe" [ngClass]="{'dirtyinput': form.controls.pos_7_strabe.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_8_hausnummer" translate>Nr domu:</label>
				<input type="text" id="pos_8_hausnummer" pInputText formControlName="pos_8_hausnummer" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummer.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_8_hausnummerzusats" translate>Nr mieszkania:</label>
				<input type="text" id="pos_8_hausnummerzusats" pInputText formControlName="pos_8_hausnummerzusats" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummerzusats.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_9_postleitzahl" translate>Kod pocztowy PNA:</label>
				<input type="text" id="pos_9_postleitzahl" pInputText formControlName="pos_9_postleitzahl" [ngClass]="{'dirtyinput': form.controls.pos_9_postleitzahl.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_9_wohnort" translate>Miejscowosć:</label>
				<input type="text" id="pos_9_wohnort" pInputText formControlName="pos_9_wohnort" [ngClass]="{'dirtyinput': form.controls.pos_9_wohnort.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="land" translate>Kraj:</label>
				<input type="text" id="land" pInputText formControlName="land" [ngClass]="{'dirtyinput': form.controls.land.invalid}">
			</div>

			<div class="formwrapperrow25"></div>

			<div class="formwrappermessage">
				<h1 translate>Informacje do rozliczenia podatku</h1>
				<p translate>Informacje niezbędne do rozliczenia podatku dochodowego od osób fizycznych, w tym dane małżonka/małżonki</p>
			</div>

			<div class="formwrapperrow33">
				<label for="nip" translate>NIP:</label>
				<input type="text" id="nip" pInputText formControlName="nip" [ngClass]="{'dirtyinput': form.controls.nip.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_12_verheiratet" translate>Żonaty/zamężna od:</label>
				<input type="text" id="pos_12_verheiratet" pInputText formControlName="pos_12_verheiratet" [ngClass]="{'dirtyinput': form.controls.pos_12_verheiratet.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_12_verwitwet" translate>Wdowa/wdowiec od:</label>
				<input type="text" id="pos_12_verwitwet" pInputText formControlName="pos_12_verwitwet" [ngClass]="{'dirtyinput': form.controls.pos_12_verwitwet.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_14_name" translate>Nazwisko małżonka/małżonki</label>
				<input type="text" id="pos_14_name" pInputText formControlName="pos_14_name" [ngClass]="{'dirtyinput': form.controls.pos_14_name.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_14_vorname" translate>Imię małżonka/małżonki</label>
				<input type="text" id="pos_14_vorname" pInputText formControlName="pos_14_vorname" [ngClass]="{'dirtyinput': form.controls.pos_14_vorname.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_16_geburtsdatum" translate>Data urodzenia małżonka/małżonki [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" id="pos_16_geburtsdatum" pInputText formControlName="pos_16_geburtsdatum" [ngClass]="{'dirtyinput': form.controls.pos_16_geburtsdatum.invalid}"></p-inputMask>
			</div>

			<div class="formwrappermessage">
				<h1 translate>KONTO BANKOWE</h1>
			</div>

			<div class="formwrapperrow25">
				<label for="pos_29_bic" translate>numer SWIFT</label>
				<input type="text" id="pos_29_bic" pInputText formControlName="pos_29_bic" [ngClass]="{'dirtyinput': form.controls.pos_29_bic.invalid}">
			</div>

			<div class="formwrapperrow50">
				<label for="pos_28_iban" translate>Numer konta bankowego</label>
				<input type="text" id="pos_28_iban" pInputText formControlName="pos_28_iban" [ngClass]="{'dirtyinput': form.controls.pos_28_iban.invalid}">
			</div>

			<div class="formwrapperrow25"></div>

			<div class="formwrapperrow25">
				<label for="pos_111_col1" translate>Przypuszczalny obrót</label>
				<input type="text" id="pos_111_col1" pInputText formControlName="pos_111_col1" [ngClass]="{'dirtyinput': form.controls.pos_111_col1.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_111_col3" translate>Obrót w nowym roku</label>
				<input type="text" id="pos_111_col3" pInputText formControlName="pos_111_col3" [ngClass]="{'dirtyinput': form.controls.pos_111_col3.invalid}">
			</div>

			<div class="formwrapperrow50"></div>

			<div class="formwrappermessage">
				<h1 translate>Zwolnienie z podatku dochodowego</h1>
				<p translate>Zaznacz, czy chcesz skorzystać ze zwolnienia z podatku dochodowego. Nasi eksperci przeanalizują Twoją sytuację i poinformują Cię o możliwościach.</p>
			</div>

			<div class="formwrapperrow50">
				<label for="pos_124_freistellung" translate>Chcę freistellung (zwolnienie z podatku)</label>
				<p-checkbox formControlName="pos_124_freistellung" [binary]="true" inputId="pos_124_freistellung" [ngClass]="{'dirtyinput': form.controls.pos_124_freistellung.invalid}"></p-checkbox>
			</div>

			<div class="formwrapperrow50">
				<label for="pos_134_gesamtumzatz" translate>Gewerbe bez VAT</label>
				<p-checkbox formControlName="pos_134_gesamtumzatz" [binary]="true" inputId="pos_134_gesamtumzatz" [ngClass]="{'dirtyinput': form.controls.pos_134_gesamtumzatz.invalid}"></p-checkbox>
			</div>


			
			<div class="formwrappermessagex">
				<h1 translate>Zgody RODO</h1>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_akceptacja_regulaminu" [binary]="true" inputId="rodo_akceptacja_regulaminu" [ngClass]="{'dirtyinput': form.controls.rodo_akceptacja_regulaminu.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate><span translate>Akceptuję</span> <a class="underline" [routerLink]="['/regulamin']" translate>regulamin</a><span translate>(POLE OBOWIĄZKOWE)</span></p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_przetwarzanie_danych" [binary]="true" inputId="rodo_przetwarzanie_danych" [ngClass]="{'dirtyinput': form.controls.rodo_przetwarzanie_danych.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie danych szczególnych kategoriizawartych w przesłanych dokumentach w celu dokonania wyceny oraz wykonania usługi. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposów uniemożliwiający nam przetwarzenia danych szczególnych kategorii (POLE OBOWIĄZKOWE)</p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_dane_marketingowe" [binary]="true" inputId="rodo_dane_marketingowe" [ngClass]="{'dirtyinput': form.controls.rodo_dane_marketingowe.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymania na ten adres email materiałów ofertowych dotyczących usług oferowanych przez CBB-Office GmbH</p>
				</div>
			</div>




			<div class="formwrapperrow">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>

		</div>
	</form>
</ng-container>

<!-- 
<pre>
	brakuje:
</pre> -->