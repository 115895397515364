import { cloneDeep } from 'lodash';
import { first, map, mergeMap, tap } from 'rxjs/operators';
import { getFiles, getEditedReport } from './../../../cbboffice-rx-store/selectors';
import { autopodpis, getCbbFilesStateAction, prepareDokFromReportAndGeneratePDFAction, sendEmailToClient, sendReport, signDokFromReport } from './../../../cbboffice-rx-store/cbbactions';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { File } from '../../../cbbmodels/file';
import { DokToSig } from '../../../cbbmodels/doktosig';
import { Report } from '../../../cbbmodels/report';
import { environment } from '../../../environments/environment';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { get } from 'http';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-sign',
	templateUrl: './sign.component.html',
	styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

	public tapet: DokToSig;
	public dokumenty: Array<DokToSig> = []
	visibleModal: boolean = false;
	documentStatePath: string = '';
	wszystkiePodpisane: boolean = false;
	visibleKlepsydra: boolean = false;
	zakonczonoPodpis: boolean = false;
	visiblePreview: boolean = false;
	pierwszeotwarcie: boolean = true;
	Wyslij: string = 'Wyślij';
	poczekaj: string = 'Poczekaj na wygenerowanie dokumentu';

	choseLabel: string = 'Prześlij';
	SubMod;
	editedReport: Report;
	SubFiles;
	SubRou;
	SubUsr;
	Sub2;
	reports: Report[];
	lpi: number;
	// email: string;
	cognitoUsername: string;
	files
	signFile: string = '';
	uid: string = '';
	odrazuOtworzPodpisz: boolean = false;
	plikDoPodpisu: File = new File();
	plikDoPodgladu: File = new File();
	ilepodpisanych: number = 0;
	czekajZPodpisem = false;
	plikDoPodgladuUrl: string = '';
	plikDoPodgladuPodpisUrl: string = '';
	wyslanoDoAutopodpisu: boolean = false;
	url: string = environment.ApiUrl + '/document/upload';
	furl: string = environment.ApiUrl + '/document/sign/getfile?filename=';
	headers: any;

	languages: SelectItem[];
	formLanguage: SelectItem;

	constructor(
		private state: Store<CbbState>,
		private router: Router,
		private confirmationService: ConfirmationService,
		private translate: TranslateService,
	) {
		this.headers = {
			'Authorization': 'Bearer ' + localStorage.getItem('cognitoToken')
		}
		this.Wyslij = this.translate.instant('Wyślij');
		this.poczekaj = this.translate.instant('Poczekaj na wygenerowanie dokumentu');
	}

	ngOnInit(): void {
		this.SubMod = this.state.select(getEditedReport())
			.pipe(
				mergeMap(editedReport => {
					return this.state.select(getFiles(editedReport))
						.pipe(
							map((files: File[]) => {
								return { files: files, editedReport: editedReport }
							})
						)
				})
			)
			.subscribe(x => {
				this.editedReport = x.editedReport;
				this.files = x.files;
				this.cognitoUsername = this.editedReport.cognitoUsername;
				this.pobierzDane();

				this.SubFiles = this.state.select(getFiles(this.editedReport))
					.subscribe(files => {
						this.files = files;
					})

			})

		let locale = localStorage.getItem('cbblang');
		if (!locale) locale = 'pl';

		this.languages = [
			{ label: 'polskim', value: 'pl' },
			{ label: 'angielskim', value: 'en' },
			{ label: 'niemieckim', value: 'de' },
			{ label: 'czeskim', value: 'cz' },
			{ label: 'słowackim', value: 'sk' },
			{ label: 'ukraińskim', value: 'ua' },
			{ label: 'rumuńskim', value: 'ro' },
			{ label: 'łotewskim', value: 'lv' },
			{ label: 'litewskim', value: 'lt' },
			{ label: 'bułgarskim', value: 'bg' },			
		];

		this.formLanguage = this.languages.find(l => l.value == locale);

		this.choseLabel = this.translate.instant('Prześlij');
		console.log('this.choseLabel',this.choseLabel);

	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
		if (this.SubFiles) this.SubFiles.unsubscribe();
	}

	/**
	 * Pobiera i analizuje dane o dokumentach do podpisania - wyciąga wnioski i przygotowuje do działania
	 */
	async pobierzDane() {
		this.dokumenty = cloneDeep(this.editedReport.dokToSig);
		await this.dokumenty.forEach((d: DokToSig) => { d.stan = 'blokada' });

		for(let dok of this.dokumenty) {
			dok.label = this.translate.instant(dok.label);
		}

		let dalejPomijam: boolean = false;
		this.ilepodpisanych = 0;
		this.wszystkiePodpisane = false;

		await this.dokumenty.forEach(dok => {
			if (!dalejPomijam) {
				// szukam pliku sparowanego z tym dokumentem
				let idx = this.files.findIndex((f: File) => f.reportName == this.editedReport.reportName && f.dokToSigPath == dok.path && f.status != 'cancelled')
				if (idx >= 0) {


					switch (this.files[idx].status) {
						case 'new':
							// console.log('jest plik, ale nie podpisany', this.files[idx]);
							this.tapet = dok;
							dalejPomijam = true;
							break;

						case 'generated':
							// console.log('jest plik wygenerowany, ale nie podpisany', this.files[idx], this.odrazuOtworzPodpisz);
							this.tapet = dok;
							this.plikDoPodpisu = this.files[idx];
							this.visibleKlepsydra = false;
							if (this.odrazuOtworzPodpisz) {
								this.visibleModal = true;
								this.odrazuOtworzPodpisz = false;
							}
							dok.stan = 'aktywny';
							dalejPomijam = true;
							break;

						case 'signed':
							// console.log('jest plik podpisany', this.files[idx]);
							this.ilepodpisanych++;
							if (this.ilepodpisanych == this.dokumenty.length) this.wszystkiePodpisane = true;
							break;

						default:
							console.log('nie obsłużony status', this.files[idx]);
							dalejPomijam = true;
							break;
					}
				} else {
					// console.log('brak pliku do tej pozycji',dok);

					if (!dok.autopodpis) dok.stan = 'aktywny';
					this.tapet = dok;
					this.plikDoPodpisu = new File();
					this.plikDoPodpisu.fileName = '';
					this.plikDoPodpisu.dokToSigPath = dok.path;
					this.plikDoPodpisu.label = dok.label;
					this.plikDoPodpisu.uidReport = this.editedReport.uid;
					this.plikDoPodpisu.reportName = this.editedReport.reportName;
					this.plikDoPodpisu.cognitoUsername = this.editedReport.cognitoUsername;
					this.plikDoPodpisu.path = dok.path;
					this.plikDoPodpisu.position = this.editedReport.position;
					this.plikDoPodpisu.dateInsert = new Date().toISOString();
					this.plikDoPodpisu.status = 'new';
					this.plikDoPodpisu.uid = this.rand();
					dalejPomijam = true;

					if (dok.autopodpis && !this.wyslanoDoAutopodpisu) {
						this.wyslanoDoAutopodpisu = true;
						console.log('wysyłam do podpisu', this.plikDoPodpisu);
						this.plikDoPodpisu.status = 'signed';
						this.state.dispatch(autopodpis({ rep: this.editedReport, dok: dok }));
					}

				}
			}
		})
	}

	rand() { return Math.floor(Math.random() * 10000000000000000) }

	/**
	 * Otwiera modal z podpisem
	 */
	podpisz(dok: DokToSig) {
		if (dok.autopodpis) return;
		this.visibleModal = false;
		this.visibleKlepsydra = true;
		this.odrazuOtworzPodpisz = true;
		console.log('this.plikDoPodpisu', this.plikDoPodpisu);
		console.log('this.editedReport', this.editedReport);
		this.state.dispatch(prepareDokFromReportAndGeneratePDFAction({ dok: dok, file: this.plikDoPodpisu, rep: this.editedReport, lang: this.formLanguage.value }))
	}

	/**
	 * Wysyła pliki do podpisu
	 */
	podpisane() {
		this.visibleKlepsydra = true;
		setTimeout(() => {
			this.visibleKlepsydra = false;
		}, 3000);
		this.state.dispatch(signDokFromReport({ dok: this.tapet, rep: this.editedReport, signFile: this.signFile, signedFile: this.plikDoPodpisu, lang: this.formLanguage.value }));
		this.visibleModal = false;
	}

	onEndSign($event: string) {
		this.signFile = $event;
	}

	pobierz(f: File) {
		this.plikDoPodgladuUrl = environment.ApiUrl + '/document/pdf/getfile?filename=' + f.fileName + '&cognitoUsername=' + f.cognitoUsername;
		this.plikDoPodgladuPodpisUrl = environment.ApiUrl + '/document/sign/getfile?filename=' + f.signPath + '&cognitoUsername=' + f.cognitoUsername;
		this.plikDoPodgladu = f;
		this.visiblePreview = true;
	}

	drukuj() {
	}

	/**
	 * finalizuje proces podpisywania plików, wysyła paczkę do realizacji
	 */
	wyslij() {

		let czynapewno:string = this.translate.instant('Czy na pewno chcesz wysłać zgłoszenie do realizacji?');

		this.confirmationService.confirm({
			message: czynapewno,
			accept: () => {
				//Actual logic to perform a confirmation
				this.state.dispatch(sendReport({ rep: { ...this.editedReport, status: 'signed' } }));
				this.state.dispatch(sendEmailToClient({ rep: this.editedReport }));
				this.router.navigate(['console/documents']); return;
			}
		});
	}

	onUpload($event) {
		console.log('this.cognitoUsername', this.cognitoUsername);

		//this.state.dispatch(getCbbFilesStateAction({ cognitoUsername: this.cognitoUsername }));
		this.state.dispatch(getCbbFilesStateAction({ cognitoUsername: this.cognitoUsername }));
	}

	onLanguageChange(event) {
		// console.log('event', event.value.value);
		// localStorage.setItem('frmlng', event.value.value);
	}
}
