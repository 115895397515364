<!-- Code block starts -->
<div class="flex items-center justify-center px-4 py-4">
	<div role="alert" id="alert" class="transition duration-150 ease-in-out w-full lg:w-11/12 mx-auto bg-white dark:bg-gray-800 shadow rounded flex flex-col py-4 md:py-0 items-center md:flex-row justify-between">
		<div class="flex flex-col items-center md:flex-row">
			<div class="mr-3 p-4 bg-blue-400 rounded md:rounded-tr-none md:rounded-br-none text-white">
				<img class="focus:outline-none" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/simple-with-action-button-warning-svg1.svg" alt="warning" />
			</div>
			<p class="mr-2 text-base font-bold text-gray-800 dark:text-gray-100 mt-2 md:my-0" translate>Uwaga!</p>
			<div class="h-1 w-1 bg-gray-300 dark:bg-gray-700 rounded-full mr-2 hidden xl:block"></div>
			<p class="text-sm lg:text-base dark:text-gray-400 text-gray-600 lg:pt-1 xl:pt-0 sm:mb-0 mb-2 text-center sm:text-left">
				<app-info slug="uwaga-przy-profilu" ></app-info>
			</p>
		</div>
	</div>
</div>
<!-- Code block ends -->

<div class="flex justify-center">
	<div class="block p-6 rounded-lg shadow-lg bg-white w-[90%] m-6">
		<h5 class="text-gray-900 text-xl leading-tight font-medium mb-2" translate>Formularz Twoich danych osobowych</h5>
		<p class="text-gray-700 text-base mb-4">
			<app-info slug="informacje-o-profilu" ></app-info>
		</p>
	</div>
</div>


<form [formGroup]="profileForm" (ngSubmit)="send()">

	<div class="formwrapper mt-6">

		<div class="formwrapperrow33"></div>

		<div class="formwrapperrow33">
			<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
		</div>
		<div class="formwrapperrow33">
			<button (click)="turn('on')" *ngIf="disabled" pButton [label]="Edytuj" icon="pi pi-user-edit"></button>
			<button (click)="turn('off'); reset()" *ngIf="!disabled" pButton [label]="Anuluj" icon="pi pi-undo"></button>
		</div>

		<div class="formwrapperrow33">
			<label for="firstName" translate>Imię:</label>
			<input type="text" id="firstName" pInputText formControlName="firstName" [ngClass]="{'dirtyinput': profileForm.controls.firstName.invalid && (profileForm.controls.firstName.touched || profileForm.controls.firstName.dirty)}">
			<span *ngIf="profileForm.controls.firstName.invalid && (profileForm.controls.firstName.touched || profileForm.controls.firstName.dirty)" translate>wrong value</span>
		</div>

		<div class="formwrapperrow33">
			<label for="lastName" translate>Nazwisko:</label>
			<input type="text" id="lastName" pInputText formControlName="lastName">
		</div>

		<div class="formwrapperrow33">
			<label for="birthDate" translate>Data urodzenia</label>
			<p-calendar styleClass="pCallendar" inputId="basic" dateFormat="yy-mm-dd" formControlName="birthDate" [ngClass]="{'disabled':'disabled'}"></p-calendar>
		</div>

		<div class="formwrapperrow33">
			<label for="telNo" translate>Numer telefonu</label>
			<input type="text" id="telNo" pInputText formControlName="telNo">
		</div>

		<div class="formwrapperrow33">
			<label for="email" translate>E-mail.</label>
			<input type="email" id="email" pInputText formControlName="email">
		</div>

		<div class="formwrapperrow33"></div>

		<div class="formwrapperrow25">
			<label for="country" translate>Kraj</label>
			<input type="text" id="country" pInputText formControlName="country">
		</div>


		<div class="formwrapperrow25">
			<label for="zipCode" translate>Kod pocztowy</label>
			<input type="text" id="zipCode" pInputText formControlName="zipCode">
		</div>

		<div class="formwrapperrow50">
			<label for="city" translate>Miejscowość</label>
			<input type="text" id="city" pInputText formControlName="city">
		</div>

		<div class="formwrapperrow50">
			<label for="address" translate>Ulica</label>
			<input type="text" id="address" pInputText formControlName="address">
		</div>

		<div class="formwrapperrow25">
			<label for="local" translate>Lokal</label>
			<input type="text" id="local" pInputText formControlName="local">
		</div>


		<div class="formwrapperrow25">
			<label for="possession" translate>Posesja</label>
			<input type="text" id="possession" pInputText formControlName="possession">
		</div>


		<div class="formwrapperrow25"></div>


		<div class="formwrapperrow50"></div>
		<div class="formwrapperrow">
			<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
		</div>

		<div class="formwrapperrow"></div>
		<div class="formwrapperrow"></div>
	</div>

</form>








<!-- 
<h3 class="m-5">Tutaj uzupełnij swój profil</h3>



<div class="card">

	<div class="m-5">
		<p-fieldset legend="Note">
			Uzupełnienie profilu jest niezbędne do przeprowadzenia dalszych usług wsparcia. Jednocześnie powieżając nam swoje dane zgadzasz się na warunki zawarte w Regulaminie, Polityce prywatności oraz zasadach RODO.
		</p-fieldset>
	</div>
	<div class="flex card-container indigo-container">
		<form [formGroup]="profileForm" (ngSubmit)="send()">
			<div class="formgrid grid m-5">
				<div class="field col-12 md:col-4"><label for="firstName">First Name</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="firstName" pInputText formControlName="firstName" /></div>
				<div class="field col-12 md:col-4"><label for="lastName">Last Name</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="lastName" pInputText formControlName="lastName" /></div>
				<div class="field col-12 md:col-4"><label for="telNo">Telefon No.</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="telNo" pInputText formControlName="telNo" /></div>
				<div class="field col-12 md:col-4"><label for="address">Address</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="address" pInputText formControlName="address" /></div>
				<div class="field col-12 md:col-4"><label for="local">Local No.</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="local" pInputText formControlName="local" /></div>
				<div class="field col-12 md:col-4"><label for="possession">Possession</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="possession" pInputText formControlName="possession" /></div>
				<div class="field col-12 md:col-4"><label for="zipCode">zip Code</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="zipCode" pInputText formControlName="zipCode" /></div>
				<div class="field col-12 md:col-4"><label for="city">City</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="city" pInputText formControlName="city" /></div>
				<div class="field col-12 md:col-4"><label for="country">Country</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="country" pInputText formControlName="country" /></div>
				<div class="field col-12 md:col-4"><label for="documentNo">Id Card No.</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="documentNo" pInputText formControlName="documentNo" /></div>
				<div class="field col-12 md:col-4"><label for="birthDate">birthDate</label><input type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="birthDate" pInputText formControlName="birthDate" /></div>

				<div class="col-12">
					<button pButton style="width: 100%;" class="" [label]="Zapisz"></button>
				</div>

			</div>
		</form>
	</div>
</div>

<h5 class="todo m-5">//TODO: do zrobienia:</h5>
<ul class="todo m-5">
	<li>Dodać maskę do pól takich jak data, żeby nie dało się źle wprowadzić daty</li>
	<li>Dodać odnośniki do polityki prywatności</li>
	<li>Ustalić, które pola są wymagane</li>
	<li>zesłownikować nazwy kraju</li>
	<li>pole komunikacji o błędnych danych formularza</li>
	<li>Przycisk "Edytuj" odblokowujący możliwość edycji formularza</li>
	<li>Informacyjne pole opisowe</li>
</ul> -->