import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class CognitoJwtInterceptor implements HttpInterceptor {

	constructor(private router: Router) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			tap((httpEvent: HttpEvent<any>) => {
				if (httpEvent.type === 0) {
					return;
				}
				if (httpEvent instanceof HttpResponse) {
					let jwt = httpEvent.headers.get('X-Access-Token');
					if (jwt && jwt.length > 10) localStorage.setItem('cognitoToken', jwt)
				}
			}),
			catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem('cognitoToken');
          this.router.navigate(['/']);
        }
        return throwError(error);
      })
		)
	}

}
