import { CbbformService } from './../../cbbform.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { getEditedReport, getUserProfile } from '../../../cbboffice-rx-store/selectors';
import { Report } from '../../../cbbmodels/report';
import { map, mergeMap } from 'rxjs';
import { UserConfig } from '../../../cbbmodels/user-config';
import { Field } from '../../../cbbmodels/field';
import { cancellAllFileByReport, setReport } from '../../../cbboffice-rx-store/cbbactions';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-register-zoo',
	templateUrl: './register-zoo.component.html',
	styleUrls: ['./register-zoo.component.scss']
})
export class RegisterZooComponent implements OnInit {

	editedReport: Report;
	storeOk: boolean = false;
	subUser$
	SubMod;
	form: FormGroup
	disabled: boolean = false;
	jestformularz: boolean = false;
	user: UserConfig;
	formularzPrzetwarzany: boolean = false;
	jestWspolnik: boolean = false;
	Zapisz: string;
	Anuluj: string; 
	iluWspolnikow: number = 0;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private state: Store<CbbState>,
		private cbbform: CbbformService,
		private translate: TranslateService
	) {
		this.form = this.cbbform.getForm('rejestracjaSpzoo');
		this.Zapisz = this.translate.instant('Zapisz');
		this.Anuluj = this.translate.instant('Anuluj'); 
	}

	ngOnInit(): void {
		this.storeOk = false;

		this.subUser$ = this.state.select(getUserProfile())
		this.SubMod = this.state.select(getEditedReport())
			.pipe(
				mergeMap(rep => {
					return this.subUser$.pipe(
						map(user => { return { user: user, report: rep } })
					)
				})
			)
			.subscribe((wsad: { user, report }) => {
				if (!this.formularzPrzetwarzany) {
					if (wsad.report?.reportName == '') { this.router.navigate(['console/documents']); return; }
					this.editedReport = wsad.report;

					if (this.editedReport.status == 'signed' || this.editedReport.status == 'archive' || this.editedReport.status == 'confirmed') {
						this.turn('off');
					} else this.turn('on');

					this.user = wsad.user;
					this.pobierzDane()
				}
			})
	}

	pobierzDane() {
		this.form = this.cbbform.setDefaultFormValue(this.form, this.user);
		this.editedReport.fields.forEach(f => {
			if (f.fieldName != 'udzialowcy')
				this.form.get(f.fieldName)?.setValue((f.fieldValue == 'true') ? true : f.fieldValue);
			else {
				this.iluWspolnikow = f.children.length;
				f.children.forEach((c, i) => {
					this.dodajWspolnika();
					c.children.forEach(cc => {
						this.jestWspolnik = true;
						this.udzialowcy.controls[i].get(cc.fieldName)?.setValue(cc.fieldValue);
					})
				})
			}
		});

		this.form.get('pos_6_geburtsdatum')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_6_geburtsdatum')?.value));
		
		this.isValid();
	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
	}

	turn(swit: string) {
		switch (swit) {
			case 'on':
				if (this?.editedReport?.status == 'edited' || this.editedReport.status == 'new') {
					this.form.enable();
					this.disabled = false;
					this.formularzPrzetwarzany = false;
				} else {
					console.log('Nie można edytować', this.editedReport.status);
				}
				return;
			case 'off':
				this.form.disable();
				this.disabled = true;
				return;
		}
	}

	reset() {
		this.form.reset();
		this.pobierzDane();
		this.formularzPrzetwarzany = false;
	}

	setValue() {
	}

	isValid() {

		if(this.form.get('religia')?.value == '') this.form.get('religia')?.setValue('n/d');
		if(this.form.get('pos_6_kraj_urodzenia')?.value == '') this.form.get('pos_6_kraj_urodzenia')?.setValue(' ');
		if(this.form.get('pos_6_miejsce_urodzenia')?.value == '') this.form.get('pos_6_miejsce_urodzenia')?.setValue(' ');

		this.cbbform.czyMonzaIscDalej(false);

		let control = <FormArray>this.form.controls.udzialowcy;
		this.jestWspolnik = (control.length > 0);

		if (!this.jestWspolnik) return false;

		if (control.status == 'INVALID') return false;

		if (!this.form.valid) return false;					//TODO: sprawdzić pola i podpola

		this.cbbform.czyMonzaIscDalej(true);
		return true;
	}

	get udzialowcy() {
		return this.form.get('udzialowcy') as FormArray;
	}

	dodajWspolnika() {
		let control = <FormArray>this.form.controls.udzialowcy;
		control.push(this.fb.group({
			zoo_82_firma: new FormControl('', Validators.required),
			zoo_83_nazwisko: new FormControl('', Validators.required),
			zoo_83_imie: new FormControl('', Validators.required),
			zoo_84_ulica: new FormControl('', Validators.required),
			zoo_85_nr_domu: new FormControl('', Validators.required),
			zoo_86_nr_lokalu: new FormControl('', Validators.required),
			zoo_87_data_urodzenia: new FormControl('', Validators.required),
			zoo_89_urzad_skarbowy: new FormControl('', Validators.required),
			zoo_90_nip: new FormControl('', Validators.required),
			zoo_91_kwota_udzialu: new FormControl('', Validators.required),
			zoo_91_procent_udzialu: new FormControl('', Validators.required),
		}))

	}

	usunWspolnika(i) {
		let control = <FormArray>this.form.controls.udzialowcy;
		control.removeAt(i);
		this.isValid();
	}

	send() {
		this.formularzPrzetwarzany = true;
		let fields: Field[] = [];

		for (let [key, value] of Object.entries(this.form.getRawValue())) {

			if (key == 'pos_9_wohnort') this.form.get('zoo_21_miejscowosc_w_pl')?.setValue(value.toString());
			if (key == 'pos_9_postleitzahl') this.form.get('zoo_21_kod_pocztowy_w_pl')?.setValue(value.toString());
			if (key == 'pos_8_hausnummer') this.form.get('zoo_20_nr_domu_w_pl')?.setValue(value.toString());
			if (key == 'pos_7_strabe') this.form.get('zoo_19_ulica_w_pl')?.setValue(value.toString());


			if (key == 'udzialowcy') {
				let udzialowcy: Field[] = [];
				if (Array.isArray(value)) {
					value.forEach((v, i) => {
						this.jestWspolnik = true;
						let udzialowiec: Field = new Field;
						udzialowiec.fieldName = 'udzialowiec';
						udzialowiec.fieldValue = 'to jest tablica w polu children[] !!!';
						for (let [key, value] of Object.entries(v)) {
							udzialowiec.children.push({ fieldName: key, fieldValue: value.toString(), children: [] });
						}
						udzialowcy.push(udzialowiec);
					});
					if (!value) value = '';
					fields.push({ fieldName: 'udzialowcy', fieldValue: 'to jest tablica w polu children[] !!!', children: udzialowcy });
				}

			} else {
				try {
					if (!value) value = '';
					
					let normalnePole: boolean = true;
					// zmiana formatu daty
					if (key == 'pos_6_geburtsdatum' || key == 'pkt_11_dzialalnosc_od' || key == 'pos_12_verheiratet' || key == 'pos_12_verwitwet' || key == 'pos_16_geburtsdatum') {
						normalnePole = false;
						fields.push({ fieldName: key, fieldValue: this.cbbform.naprawDate(value.toString()), children: [] });
					}

					if (normalnePole) fields.push({ fieldName: key, fieldValue: value.toString(), children: [] });

				} catch (error) {
					console.log(error, key, value);
				}
			}
		}
		if (this?.editedReport?.status == 'new' || this?.editedReport?.status == 'edited') {
			this.editedReport = { ...this.editedReport, fields: fields, status: 'edited' };
			this.state.dispatch(cancellAllFileByReport({ rep: this.editedReport }));
			this.state.dispatch(setReport({ rep: this.editedReport }));

			if (this.isValid()) {
				this.cbbform.idzdalej();
			} else {
				this.cbbform.errMess('Nie wszystkie dane są wprowadzone poprawnie (patrz czerwone ramki)');
			}
		}
		// this.turn('off');
	}
}


