import { AuthCognitoService } from './auth-cognito/auth-cognito.service';
import { AfterViewInit, Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, from, Observable, of, toArray } from 'rxjs';
import { CbbState } from '../cbbmodels/cbbstate'
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { MenuItem } from 'primeng/api';

import { TranslateService } from "@ngx-translate/core";
import { ApperanceService } from './apperance.service';
import { CbbformService } from './cbbform.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
	title = 'CBBOffice';
	isLogin: boolean = false;
	visibleMenu: boolean = true;
	cbbState$: Observable<CbbState>;
	changeLogin$;
	lang: string;
	langSub;
	transSub;
	errMessSub$;

	SzablonItemsWylogowany: MenuItem[] = [
		{ label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
		// { label: 'Blog', icon: 'pi pi-fw pi-calendar', routerLink: ['/blog'] },
		{ label: 'O nas', icon: 'pi pi-fw pi-star', routerLink: ['/aboutus'] },
		{ label: 'Podstawa prawna', icon: 'pi pi-fw pi-book', routerLink: ['/policy'] },
		{ label: 'Kontakt', icon: 'pi pi-fw pi-envelope', routerLink: ['/contact'] },
		{ label: 'Konsola', icon: 'pi pi-fw pi-envelope', routerLink: ['/console'] },
	];

	SzablonItemsZalogowany: MenuItem[] = [
		{ label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
		{ label: 'Konsola', icon: 'pi pi-fw pi-cog', routerLink: ['/console'] },
		{ label: 'Twój profil', icon: 'pi pi-fw pi-home', routerLink: ['/console/profile'] },
		{ label: 'Złożone wnioski', icon: 'pi pi-fw pi-calendar', routerLink: ['/console/documents'] },
		{ label: 'Przesłane pliki', icon: 'pi pi-fw pi-file', routerLink: ['/console/files'] },
		{ label: 'Korespondencja', icon: 'pi pi-fw pi-envelope', routerLink: ['/console/mailbox'] },
	];

	itemsZalogowany: MenuItem[] = [];
	itemsWylogowany: MenuItem[] = [];
	numeryKierunkowe: any[] = [];

	constructor(
		private store: Store<CbbState>,
		private authService: AuthCognitoService,
		private primengConfig: PrimeNGConfig,
		private translate: TranslateService,
		private apperanceService: ApperanceService,
		private cbbformService: CbbformService,
		private messageService: MessageService
	) {

		this.errMessSub$ = this.cbbformService.errMessage$.subscribe(x => {
			this.messageService.add({ severity: 'error', summary: 'Błąd', detail: x });
		})

		/// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular-app-with-ngx-translate

		this.lang = localStorage.getItem('cbblang');			//TODO: przenieść do apperance service
		if (!this.lang) {
			this.lang = 'pl';
			localStorage.setItem('cbblang', this.lang);
		}
		this.translate.use(this.lang);
		this.przetlumaczMenu();
		this.translate.setDefaultLang(this.lang);
		this.apperanceService.changeLocale(this.lang);
	}

	useLanguage(language: string): void {
		this.translate.use(language);
	}

	przetlumaczMenu() {
		setTimeout(() => {
			this.itemsZalogowany = [...this.SzablonItemsZalogowany.map((x: MenuItem) => { return { label: this.translate.instant(x.label), icon: x.icon, routerLink: x.routerLink } })]
			this.itemsWylogowany = [...this.SzablonItemsWylogowany.map((x: MenuItem) => { return { label: this.translate.instant(x.label), icon: x.icon, routerLink: x.routerLink } })]
		}, 1000);
		
	}

	ngAfterViewInit() {
	}

	ngOnInit(): void {
		this.changeLogin$ = this.authService.onChangeAuth
			.subscribe(x => {
				this.isLogin = x;
			});
		this.cbbState$ = this.store.select((store) => store);
		this.langSub = this.apperanceService.currentLanguage$.subscribe(lang => {
			this.translate.use(lang);
			this.przetlumaczMenu();
		})
	}

	ngOnDestroy(): void {
		if (this.changeLogin$) this.changeLogin$.unsubscribe();
		if (this.langSub) this.langSub.unsubscribe();
	}
}
