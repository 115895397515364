import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApperanceService } from '../apperance.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  zalety;
  aSub;
  wstep;

  constructor(
    private apperanceService: ApperanceService
  ) { }

  ngOnInit(): void {
    this.pobierzStopki();
  }

	pobierzStopki() {
		this.aSub = this.apperanceService.getZalety()
			.subscribe(zalety => {
				this.zalety = zalety;
        this.wstep = this.zalety.filter(x => x.slug == 'wstep')[0];
			})
	}

  ngOnDestroy(): void {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

}
