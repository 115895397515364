<!-- DWA WIDOKI - W ZALEŻNOŚCI, CZY JESTEM W HOME CZY W MENU -->
<ng-container *ngIf="jestemWHome">
	<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="!loginState" translate>Zaloguj się do konsoli</div>
	<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="loginState" translate>Jesteś zalogowany</div>
	<div class="h-10 w-full flex flex-row bg-gray-50" *ngIf="!loginState">
		<div (click)="ustawSposobLogowania('email', 'login')" [ngClass]="{'sposoblogowania': sposobLogowania == 'email'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>przez e-mail</div>
		<div (click)="ustawSposobLogowania('phoneNumber', 'login')" [ngClass]="{'sposoblogowania': sposobLogowania == 'phoneNumber'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>numer telefonu</div>
	</div>
	<div class="w-full flex flex-col bg-gray-200 p-3">
		<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="!loginState" (click)="changeView('login')"><span translate>Zaloguj</span></button>
		<!-- <button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="loginState" (click)="changeView('profil')"><span translate>Your Profile</span></button> -->
		<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="loginState" (click)="changeView('changePassword')"><span translate>Zmień hasło</span></button>
		<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="!loginState" translate>Zarejestruj nowy profil</div>				
		<div class="h-10 w-full flex flex-row bg-gray-50" *ngIf="!loginState">
			<div (click)="ustawSposobLogowania('email', 'register')" [ngClass]="{'sposoblogowania': sposobLogowania == 'email'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>przez e-mail</div>
			<div (click)="ustawSposobLogowania('phoneNumber', 'register')" [ngClass]="{'sposoblogowania': sposobLogowania == 'phoneNumber'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>przez telefon</div>
		</div>
		<button class="w-full h-9 mb-3 mt-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="!loginState" (click)="changeView('register')"><span translate>Zarejestruj nowy profil</span></button>
		<button class="w-full h-9 mb-3 mt-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="!loginState" (click)="changeView('forgot1')"><span translate>Odzyskaj swoje hasło</span></button>
		<a translate class="mb-3 text-blue-400 hover:text-green-600 underline cursor-pointer" (click)="changeView('forgot2')" translate>Jeżeli odzyskujesz hasło (krok 2/2) tutaj potwierdź kodem z e-mail lub telefonu</a>
		<a translate class="mb-3 text-blue-400 hover:text-green-600 underline cursor-pointer" (click)="changeView('confirm')" translate>Potwierdź swój profil za pomocą kodu z e-mail lub telefonu</a>
		<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="loginState" (click)="logout()" mat-stroked-button color="secondary"><span translate>Wyloguj</span></button>
	</div>
</ng-container>

<ng-container *ngIf="!jestemWHome">
	<p-overlayPanel #op [style]="{width: '450px'}" [showCloseIcon]="true">
		<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="!loginState" translate>Zaloguj się do konsoli</div>
		<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="loginState" translate>Jesteś zalogowany</div>
		<div class="h-10 w-full flex flex-row translate bg-gray-50" *ngIf="!loginState">
			<div (click)="ustawSposobLogowania('email', 'login')" [ngClass]="{'sposoblogowania': sposobLogowania == 'email'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>przez e-mail</div>
			<div (click)="ustawSposobLogowania('phoneNumber', 'login')" [ngClass]="{'sposoblogowania': sposobLogowania == 'phoneNumber'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>numer telefonu</div>
		</div>
		<div class="w-full flex flex-col bg-gray-200 p-3">
			<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="!loginState" (click)="changeView('login'); op.toggle(false)"><span translate>Zaloguj</span></button>
			<!-- <button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="loginState" (click)="changeView('profil'); op.toggle(false)"><span translate>Your Profile</span></button> -->
			<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="loginState" (click)="changeView('changePassword'); op.toggle(false)"><span translate>Zmień hasło</span></button>
			<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="!loginState" translate>Zarejestruj nowy profil</div>				
			<div class="h-10 w-full flex flex-row bg-gray-50" *ngIf="!loginState">
				<div (click)="ustawSposobLogowania('email', 'register')" [ngClass]="{'sposoblogowania': sposobLogowania == 'email'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-1.5" translate>przez e-mail</div>
				<div (click)="ustawSposobLogowania('phoneNumber', 'register')" [ngClass]="{'sposoblogowania': sposobLogowania == 'phoneNumber'}" class="cursor-pointer w-1/2 bg-white hover:bg-blue-200 text-xl text-center pt-	1.5" translate>przez telefon</div>
			</div>
			<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="!loginState" (click)="changeView('register'); op.toggle(false)"><span translate>Zarejestruj nowy profil</span></button>
			<div class="w-full h-10 bg-gray-400 bold pt-1.5 text-center text-xl" *ngIf="!loginState" translate>&nbsp;</div>				
			<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="!loginState" (click)="changeView('forgot1'); op.toggle(false)"><span translate>Odzyskaj swoje hasło</span></button>
			<a translate class="mb-3 text-blue-400 hover:text-green-600 underline cursor-pointer" (click)="changeView('forgot2')" translate>Jeżeli odzyskujesz hasło (krok 2/2) tutaj potwierdź kodem z e-mail lub telefonu</a>
			<a translate class="mb-3 text-blue-400 hover:text-green-600 underline cursor-pointer" (click)="changeView('confirm')" translate>Potwierdź swój profil za pomocą kodu z e-mail lub telefonu</a>
			<button class="w-full h-9 mb-3 rounded-md shadow-sm bg-white shadow-[#ddd] hover:bg-blue-200 active:bg-blue-300" *ngIf="loginState" (click)="logout(); op.toggle(false)" mat-stroked-button color="secondary"><span translate>Wyloguj</span></button>
		</div>
	</p-overlayPanel>

	<button type="text" pButton [label]="vname" (click)="op.toggle($event)" class="bg-blue-400 hover:bg-green-800 text-white font-bold py-2 px-4 rounded-full"></button>
</ng-container>