import { CbbformService } from './../../cbbform.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { getEditedReport, getUserProfile } from '../../../cbboffice-rx-store/selectors';
import { Report } from '../../../cbbmodels/report';
import { map, mergeMap } from 'rxjs';
import { UserConfig } from '../../../cbbmodels/user-config';
import { Field } from '../../../cbbmodels/field';
import { cancellAllFileByReport, setReport } from '../../../cbboffice-rx-store/cbbactions';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-freistellung',
	templateUrl: './freistellung.component.html',
	styleUrls: ['./freistellung.component.scss']
})
export class FreistellungComponent implements OnInit {

	editedReport: Report;
	storeOk: boolean = false;
	subUser$
	SubMod;
	form: FormGroup
	disabled: boolean = false;
	jestformularz: boolean = false;
	user: UserConfig;
	formularzPrzetwarzany: boolean = false;
	Zapisz: string;
	Anuluj: string;
	//wojewodztwa
	wojewodztwa: string[] = ['dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie', 'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie'];


	constructor(
		private fb: FormBuilder,
		private router: Router,
		private state: Store<CbbState>,
		private cbbform: CbbformService,
		private translate: TranslateService
	) {
		this.form = this.cbbform.getForm('freistellung');
		console.log('this.form ', this.form.getRawValue());
		this.Zapisz = this.translate.instant('Zapisz');
		this.Anuluj = this.translate.instant('Anuluj');
	}

	ngOnInit(): void {
		this.storeOk = false;

		this.subUser$ = this.state.select(getUserProfile())
		this.SubMod = this.state.select(getEditedReport())
			.pipe(
				mergeMap(rep => {
					return this.subUser$.pipe(
						map(user => { return { user: user, report: rep } })
					)
				})
			)
			.subscribe((wsad: { user, report }) => {
				if (!this.formularzPrzetwarzany) {
					if (wsad.report?.reportName == '') { this.router.navigate(['console/documents']); return; }
					this.editedReport = wsad.report;

					if (this.editedReport.status == 'signed' || this.editedReport.status == 'archive' || this.editedReport.status == 'confirmed') {
						this.turn('off');
					} else this.turn('on');

					this.user = wsad.user;
					this.pobierzDane()
				}
			})
	}

	pobierzDane() {
		this.form = this.cbbform.setDefaultFormValue(this.form, this.user);
		this.editedReport.fields.forEach(f => {
			this.form.get(f.fieldName)?.setValue((f.fieldValue == 'true') ? true : f.fieldValue);
		});
		
		this.form.get('pos_6_geburtsdatum')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_6_geburtsdatum')?.value));

		this.isValid();
	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
	}

	turn(swit: string) {
		switch (swit) {
			case 'on':
				if (this?.editedReport?.status == 'edited' || this.editedReport.status == 'new') {
					this.form.enable();
					this.disabled = false;
					this.formularzPrzetwarzany = false;
				} else {
					console.log('Nie można edytować', this.editedReport.status);
				}
				return;
			case 'off':
				this.form.disable();
				this.disabled = true;
				return;
		}
	}

	reset() {
		this.form.reset();
		this.pobierzDane();
		this.formularzPrzetwarzany = false;
	}

	setValue() {
	}

	isValid() {
		this.form.get('podpis')?.setValue('todo')
		if(this.form.get('pos_6_miejsce_urodzenia')?.value == '') this.form.get('pos_6_miejsce_urodzenia')?.setValue(' ');
		if(this.form.get('pos_6_kraj_urodzenia')?.value == '') this.form.get('pos_6_kraj_urodzenia')?.setValue(' ');

		console.log('invalid form',Object.entries(this.form.controls).filter(x => x[1].status != 'VALID'));
		
		console.log('this.form.controls', this.form.controls);

		this.cbbform.czyMonzaIscDalej(false);
		if(!this.form.valid) return false;

		this.cbbform.czyMonzaIscDalej(true);
		return true;
	}

	kontra(co: string) {
		switch (co) {
			case 'osoba_nie_fizyczna':
				this.form.get('osoba_fizyczna')?.setValue(!this.form.get('osoba_nie_fizyczna')?.value);
				return;
			case 'osoba_fizyczna':
				this.form.get('osoba_nie_fizyczna')?.setValue(!this.form.get('osoba_fizyczna')?.value);
				return;
		}
	}

	send() {
		this.formularzPrzetwarzany = true;
		let fields: Field[] = [];
		for (let [key, value] of Object.entries(this.form.getRawValue())) {
			if(!value) value = '';
			
			// powielenie powtarzających się pól, które są potrzebne do wygenerowania raportu ale nie ma ich w formularzu
			// if (key == 'pkt_10_co_bedzie_wykonywane') fields.push({ fieldName: 'pos_1_finanzamt', fieldValue: value.toString(), children: [] });
			
			// pos_6_geburtsdatum 
			
			
			let normalnePole:boolean = true;
			// zmiana formatu daty
			if(key == 'pos_6_geburtsdatum')
			{
				normalnePole = false;
				fields.push({ fieldName: key, fieldValue: this.cbbform.naprawDate(value.toString()), children: [] });
			}
			
			if(normalnePole) fields.push({ fieldName: key, fieldValue: value.toString(), children: [] });


			
		}
		if (this?.editedReport?.status == 'new' || this?.editedReport?.status == 'edited') {
			this.editedReport = { ...this.editedReport, fields: fields, status: 'edited' };
			this.state.dispatch(cancellAllFileByReport({ rep: this.editedReport }));
			this.state.dispatch(setReport({ rep: this.editedReport }));

			if (this.isValid()) {
				this.cbbform.idzdalej();
			} else {
				this.cbbform.errMess('Nie wszystkie dane są wprowadzone poprawnie (patrz czerwone ramki)');
			}
		}
		// this.turn('off');
	}
}


