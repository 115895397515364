import { IFile } from './interfaces/ifile';

export class File implements IFile {
	_id: string = '';
	cognitoUsername: string = '';
	fileName: string = '';
	dokToSigPath: string = '';
	uidReport: number = -1;
	fileType: string = '';
	reportName: string = '';
	email: string = '';
	path: string = '';
	position: number = -1;
	dateInsert: string = '';
	status: string = '';
	signPath: string = '';
	uid: number = -1;

	label:string = '';
}