
<app-info slug="pliki"></app-info>

<p-fileUpload name="cbbfile" (onUpload)="onUpload($event)" [chooseLabel]="chooseLabel" [headers]="headers" [mode]="'advanced'" [auto]="true" [url]="url" accept="image/*" ></p-fileUpload>

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
	<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
		<caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
			<span translate>Lista wygenerowanych i/lub przesłanych plików</span>
			<p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400" translate>Jeżeli któryś plik wymaga podpisu, a nie został jeszcze podpisany - kliknij przycisk podpisz</p>
			<p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400" translate>Po podpisaniu wszystkich wymaganych plików rozpoczęta zostanie procedura realizacji wniosku</p>
		</caption>
		<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
			<tr>
				<th scope="col" class="px-6 py-3" translate>dotyczy dokumentu</th>
				<th scope="col" class="px-6 py-3" translate>nazwa pliku</th>
				<th scope="col" class="px-6 py-3" translate>status</th>
				<th scope="col" class="px-6 py-3" translate><span translate>Pobierz</span></th>
				<th scope="col" class="px-6 py-3" translate>Podpis</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let f of allFiles">
				<ng-container *ngIf="f.status != 'cancelled' && f.status != 'deleted'">
					<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
						<th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
							{{ f.label | translate }}
						</th>
						<td class="px-6 py-4">
							{{ f.fileName }}
						</td>
						<td class="px-6 py-4">
							{{ f.status | translate }}
						</td>
						<td class="px-6 py-4">
								<ng-container *ngIf="f.signPath?.length > 1">
									<a target="_blank" [href]="api + '/document/pdf/getfile?filename=' + f.fileName + '&cognitoUsername=' + f.cognitoUsername" translate><span translate>pobierz</span></a>
								</ng-container>
								<ng-container *ngIf="!(f.signPath?.length > 1)">
									<a target="_blank" [href]="api + '/document/sign/getfile?filename=' + f.fileName + '&cognitoUsername=' + f.cognitoUsername" translate><span translate>pobierz</span></a>
								</ng-container>
						</td>
						<td class="px-6 py-4">
							<ng-container *ngIf="f.signPath?.length > 1">
								<a target="_blank" [href]="api + '/document/sign/getfile?filename=' + f.signPath + '&cognitoUsername=' + f.cognitoUsername" translate><span translate>pobierz</span></a>
							</ng-container>
						</td>
					</tr>
				</ng-container>
			</ng-container>
		</tbody>
	</table>
</div>



