import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApperanceService } from '../apperance.service';

@Component({
  selector: 'app-stopka',
  templateUrl: './stopka.component.html',
  styleUrls: ['./stopka.component.scss']
})
export class StopkaComponent implements OnInit, OnDestroy {

  stopki;
  aSub;
  kolumna1;
  kolumna3;

  constructor(
    private apperanceService: ApperanceService
  ) { }

  ngOnInit(): void {
    this.pobierzStopki();
  }

	pobierzStopki() {
		this.aSub = this.apperanceService.getStopki()
			.subscribe(stopki => {
				this.stopki = stopki;
        console.log('ssss',this.stopki[3] );
        this.kolumna1 = this.stopki.filter(x => x.slug == 'kolumna1')[0];
        this.kolumna3 = this.stopki.filter(x => x.slug == 'kolumna3')[0];
			})
	}

  ngOnDestroy(): void {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

}
