import { MongoService } from './../mongo.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../cbbmodels/cbbstate';
import { Report } from '../../cbbmodels/report';
import { newReportToEdt } from '../../cbboffice-rx-store/cbbactions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checker',
  templateUrl: './checker.component.html',
  styleUrls: ['./checker.component.scss']
})
export class CheckerComponent implements OnInit {

	widok:string = 'start';

	constructor(
		private state: Store<CbbState>,
		private cbbService: MongoService,
		private translate: TranslateService,
		private router: Router,
	) { }


  ngOnInit(): void {
		this.widok = 'start';
  }

	checked(v: string) {
		switch(v) {
			case '1gewerbe': this.widok = '2gewerbe'; break;
			case 'start': this.widok = 'start'; break;
		}
	}

	gotoNewModule(raport: string, label: string) {
		let newReport:Report = new Report();
		newReport.reportName = raport;
		newReport.label = label;
		newReport.uid = Math.random() * 100000000000000000;
		newReport.dokToSig = this.cbbService.getNewDokToSig(newReport.reportName, newReport.uid);
		this.state.dispatch(newReportToEdt({ rep: newReport }))
		this.router.navigate(['/createdoc'])
	}
}
