import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { StopkaComponent } from './stopka/stopka.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { ConsoleComponent } from './console/console.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthCognitoModule } from './auth-cognito/auth-cognito.module';
import { AuthCognitoConfig } from './auth-cognito/auth-cognito-config';
import { DocumentsComponent } from './documents/documents.component';
import { FilesComponent } from './files/files.component';
import { OrdersComponent } from './orders/orders.component';
import { MailboxComponent } from './mailbox/mailbox.component';
import { CheckerComponent } from './checker/checker.component';
import { GewerbeComponent } from './creators/gewerbe/gewerbe.component';
import { GewerbeTransportComponent } from './creators/gewerbe-transport/gewerbe-transport.component';
import { GewerbeConstructionComponent } from './creators/gewerbe-construction/gewerbe-construction.component';
import { GewerbeCareComponent } from './creators/gewerbe-care/gewerbe-care.component';
import { GewerbeRegistrationComponent } from './creators/gewerbe-registration/gewerbe-registration.component';
import { FreistellungComponent } from './creators/freistellung/freistellung.component';
import { RegisterJdgComponent } from './creators/register-jdg/register-jdg.component';
import { RegisterZooComponent } from './creators/register-zoo/register-zoo.component';
import { TaxComponent } from './creators/tax/tax.component';
import { ArticleComponent } from './article/article.component';
import { UmowaComponent } from './creators/umowa/umowa.component';
import { SignComponent } from './creators/sign/sign.component';
import { cbbReducer } from '../cbboffice-rx-store/cbbreducer';
import { CBBEffects } from '../cbboffice-rx-store/cbbeffects';
import { DoctosignComponent } from './doctosign/doctosign.component';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { CardModule } from 'primeng/card';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CreatedocComponent } from './createdoc/createdoc.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FlagsComponent } from './flags/flags.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';

import { CheckboxModule } from 'primeng/checkbox';
import { DoctoprevComponent } from './doctoprev/doctoprev.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { InfoComponent } from './info/info.component';
import { PolicyComponent } from './policy/policy.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';

import { InputSwitchModule } from 'primeng/inputswitch';
import { RegulaminComponent } from './regulamin/regulamin.component';


const CustomConfig: AuthCognitoConfig = {
	ApiUrl: environment.ApiUrl,
	CognitoUserPoolId: environment.CognitoUserPoolId,
	CognitoClientId: environment.CognitoClientId,
};

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		StopkaComponent,
		BlogComponent,
		ContactComponent,
		PricingComponent,
		ConsoleComponent,
		ProfileComponent,
		DocumentsComponent,
		FilesComponent,
		OrdersComponent,
		MailboxComponent,
		CheckerComponent,
		GewerbeComponent,
		GewerbeTransportComponent,
		GewerbeConstructionComponent,
		GewerbeCareComponent,
		GewerbeRegistrationComponent,
		FreistellungComponent,
		RegisterJdgComponent,
		RegisterZooComponent,
		TaxComponent,
		ArticleComponent,
		UmowaComponent,
		SignComponent,
		DoctosignComponent,
		CreatedocComponent,
		FlagsComponent,
		DoctoprevComponent,
		AboutusComponent,
		InfoComponent,
		PolicyComponent,
		RegulaminComponent
	],
	imports: [
		RouterModule,
		PdfViewerModule,
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		AuthCognitoModule.forRoot(CustomConfig),
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		StoreModule.forRoot({ cbbState: cbbReducer }, {}),
		EffectsModule.forRoot([CBBEffects]),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),

		AngularSignaturePadModule,

		TabMenuModule,
		MenuModule,
		MenubarModule,
		OverlayPanelModule,
		TableModule,
		ButtonModule,
		ToastModule,
		DialogModule,
		FieldsetModule,
		CardModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		CalendarModule,
		CheckboxModule,
		DropdownModule,
		ConfirmDialogModule,
		FileUploadModule,
		InputTextareaModule,
		KeyFilterModule,
		InputSwitchModule,
		InputMaskModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		MessageService,
		ConfirmationService
	],
	bootstrap: [AppComponent],
	schemas: [NO_ERRORS_SCHEMA],
	exports: [StopkaComponent]
})
export class AppModule { }
