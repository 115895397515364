<app-article [artykul]="'instrukcja-podpisywania-dokumentow'" [wariant]="'prosty'"></app-article>

<!-- Code block starts -->
<div class="flex items-center justify-center px-4 py-4 mt-4">
	<div role="alert" id="alert" class="transition duration-150 ease-in-out w-full lg:w-11/12 mx-auto bg-white dark:bg-gray-800 shadow rounded flex flex-col py-4 md:py-0 items-center md:flex-row justify-between">
		<div class="flex flex-col items-center md:flex-row">
			<div class="mr-3 p-4 bg-red-400 rounded md:rounded-tr-none md:rounded-br-none text-white">
				<img class="focus:outline-none" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/simple-with-action-button-warning-svg1.svg" alt="warning" />
			</div>
			<p class="mr-2 text-base font-bold text-gray-800 dark:text-gray-100 mt-2 md:my-0" translate>Uwaga!</p>
			<div class="h-1 w-1 bg-gray-300 dark:bg-gray-700 rounded-full mr-2 hidden xl:block"></div>
			<p class="text-sm lg:text-base dark:text-gray-400 text-gray-600 lg:pt-1 xl:pt-0 sm:mb-0 mb-2 text-center sm:text-left">
				<app-info slug="podpis-uwaga"></app-info>
			</p>
		</div>
	</div>
</div>
<!-- Code block ends -->


<!-- <div class="formwrapperrow justify-left">
	<label for="administrator_danych_osobowych" class="text-left font-bold text-blue-900 my-3" translate>W jakim języku wygenerować dokumenty ?</label>
	<p-dropdown [(ngModel)]="formLanguage" optionLabel="label" (onChange)="onLanguageChange($event)" [options]="languages">
	</p-dropdown>
</div> -->



<div class="grid mt-5">
	<div class="col-2 hidden  md:block"></div>
	<div class="col">
		<div class="flex flex-column card-container blue-container">
			<ng-container *ngFor="let dok of dokumenty">
				<ng-container *ngIf="dok?.autopodpis == false">
					<button (click)="podpisz(dok)" pButton icon="pi pi-pencil" [disabled]="dok.stan != 'aktywny'" class="mybutton flex align-items-center justify-content-center h-4rem text-blue-500 bg-gray-300 border-round m-2 text-center hover:bg-gray-200" [label]="dok.label" translate></button>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="col-2 hidden  md:block"></div>
</div>

<div class="flex flex-col lg:flex-row mb-2 text-xl font-bold" translate>Nie zapomnij przed wysłaniem dokumentów załączyć skanu dowodu osobistego (zrób to klikając znak + poniżej)</div>
<p-fileUpload name="cbbfile" (onUpload)="onUpload($event)" [chooseLabel]="choseLabel" [headers]="headers" [mode]="'advanced'" [auto]="true" [url]="url" accept="image/*"></p-fileUpload>

<div class="flex flex-col lg:flex-row mb-8">
	<!-- <button (click)="drukuj()" pButton icon="pi pi-print" [disabled]="!wszystkiePodpisane" 		class="w-1/2 mx-6 mybutton flex align-items-center justify-content-center h-4rem text-blue-500 bg-gray-300 border-round text-center hover:bg-gray-200" label="Drukuj"></button> -->
	<div class="w-1/2 mx-6 flex align-items-center h-6rem text-sm border-round text-justify p-5">
		<app-info slug="podpis-wysylka"></app-info>
	</div>
	<button (click)="wyslij()" pButton icon="pi pi-envelope" [ngClass]="{'zielony': !(!wszystkiePodpisane || editedReport.status != 'edited')}" [disabled]="!wszystkiePodpisane || editedReport.status != 'edited'" class="w-1/2 mt-5 mx-6 mybutton flex align-items-center justify-content-center h-4rem text-blue-500 bg-gray-300 border-round text-center hover:bg-gray-200" [label]="Wyslij"></button>
</div>

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
	<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
		<caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
			<span translate>Lista wygenerowanych i/lub przesłanych plików</span>
			<!-- <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400" translate>Jeżeli któryś plik wymaga podpisu, a nie został jeszcze podpisany - kliknij przycisk "podpisz"</p> -->
			<p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400" translate>Po podpisaniu wszystkich wymaganych plików rozpoczęta zostanie procedura realizacji wniosku</p>
		</caption>
		<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
			<tr>
				<th scope="col" class="px-6 py-3" translate>dotyczy dokumentu</th>
				<th scope="col" class="px-6 py-3" translate>nazwa pliku</th>
				<th scope="col" class="px-6 py-3" translate>status</th>
				<th scope="col" class="px-6 py-3" translate>Pobierz</th>
				<!-- <th scope="col" class="px-6 py-3" translate>
					podpisz ponownie
				</th> -->
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let f of files">
				<ng-container *ngIf="( f.status != 'cancelled' && f.status != 'deleted' ) || f.reportName == 'userfile'">
					<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
						<th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
							{{ f.label | translate }}
						</th>
						<td class="px-6 py-4">
							{{ f.fileName }}
						</td>
						<td class="px-6 py-4">
							{{ f.status | translate }}
						</td>
						<td class="px-6 py-4" *ngIf="f.reportName != 'userfile'">
							<span class="font-medium text-blue-600 dark:text-blue-500 hover:underline" (click)="pobierz(f)" translate>pobierz</span>
						</td>
						<td class="px-6 py-4" *ngIf="f.reportName == 'userfile'">
							<span class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
								<a [href]="furl+f.fileName+'&cognitoUsername='+f.cognitoUsername" target="_blank" class="text-blue-600 dark:text-blue-500 hover:underline" translate>wyświetl</a>
							</span>
						</td>
					</tr>
				</ng-container>
			</ng-container>
		</tbody>
	</table>
</div>




<p-dialog [header]="poczekaj" [(visible)]="visibleKlepsydra" [modal]="true" [style]="{width: '40%'}">
	<div style="width: 100%; padding-top: 50px; text-align: center">
		<p-progressSpinner [animationDuration]="'20s'"></p-progressSpinner>
	</div>
</p-dialog>


<p-dialog [showHeader]="false" [(visible)]="visibleModal" [modal]="true" [style]="{'width': '90%', 'height': '95vh', 'max-height': '95vh', 'display':'flex', 'flex-direction': 'column'}">
	<ng-container *ngIf="pierwszeotwarcie">
		<div class="flex justify-center items-center h-screen">
			<div class="border-4 border-gray-800 p-8">
				<h1 class="text-4xl font-bold text-center" translate>UWAGA</h1>
				<p class="text-2xl text-center py-10" translate>Pliki należy uważnie przeczytać i podpisać muszką w polu pod przeglądarką.</p>
				<p class="text-2xl text-center py-10" translate>Mimo podpisania plików za pomocą myszki - należy je wydrukować, podpisać ręcznie i przesłać do biura.</p>
				<button (click)="pierwszeotwarcie = false" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" translate>Zrozumiałem</button>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!pierwszeotwarcie">
		<app-doctosign (onendsign)="onEndSign($event)" (cancel)="visibleModal = false" (signed)="podpisane()" [fileToSign]="plikDoPodpisu"></app-doctosign>
	</ng-container>
</p-dialog>


<p-dialog [showHeader]="true" [(visible)]="visiblePreview" [modal]="true" [style]="{'width': '90%', 'height': '95vh', 'max-height': '95vh', 'display':'flex', 'flex-direction': 'column'}">
	<ng-template pTemplate="header">
		<div class="flex flex-row">
			<a class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [href]="plikDoPodgladuUrl" target="_blank" translate>pobierz</a>&nbsp;&nbsp;&nbsp;
			<a class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [href]="plikDoPodgladuPodpisUrl" target="_blank" translate>pobierz podpis</a>
		</div>
	</ng-template>
	<app-doctoprev (cancel)="visiblePreview = false" [file]="plikDoPodgladu"></app-doctoprev>
</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<div style="height: 30vh;">&nbsp;</div>