<div class="vz-formplace">
	<h4 *ngIf="sposobLogowania == 'email'" translate>Potwierdź rejestracje kodem z email</h4>
	<h4 *ngIf="sposobLogowania == 'phoneNumber'" translate>Potwierdź rejestracje kodem SMS</h4>

	<form [formGroup]="fg" (ngSubmit)="confirm()" class="vz-center-container">

		<div class="p-field viz-form-group" *ngIf="sposobLogowania == 'email'">
			<label for="email" translate>Podaj swój adres email</label>
			<input id="email" class="vz-input-lg" type="email" pInputText formControlName="email" required />
		</div>
		<!-- <div class="p-field viz-form-group" *ngIf="sposobLogowania == 'phoneNumber'">
			<label for="phoneNumber" translate>Podaj numer telefonu w formacie +48.....</label>
			<input id="phoneNumber" class="vz-input-lg" type="phoneNumber" pInputText formControlName="phoneNumber" required />
		</div> -->
		
		<div class="w-full py-3" *ngIf="sposobLogowania == 'phoneNumber'">
			<label for="phoneNumber" translate>wprowadź swój numer tlefonu</label>
			<div class="flex items-center">
				<p-dropdown class="flex-none" [options]="countryCodes" formControlName="countryCode" optionLabel="label" optionValue="value"></p-dropdown>
				<span class="flex-none ml-2">
					{{fg.get('countryCode').value}}
				</span>
				<input id="phoneNumber" class="vz-input-lg flex-grow ml-2" type="phoneNumber" pInputText formControlName="phoneNumber" required />
			</div>
		</div>

		<div class="p-field viz-form-group" *ngIf="!resend">
			<label for="confirmcode" translate>Wprowadź kod powtwierdzający</label>
			<input id="confirmcode" class="vz-input-lg" type="text" pInputText formControlName="confirmcode" required />
		</div>

		<br>

		<div class="viz-form-group" *ngIf="!resend">
			<button class="vz-input-lg" type="submit" mat-stroked-button color="primary" translate>Potwierdź swoją rejestrację</button>
		</div>

	</form>

	<div class="viz-form-group" *ngIf="resend">
		<button class="vz-input-lg" (click)="reconfirm()" mat-stroked-button color="primary" translate>Wyślij ponownie kod</button>
	</div>


	<br><br>
	<a class="vz-alink" (click)="resend = true" translate>Wyślij ponownie kod weryfikacyjny</a>
</div>