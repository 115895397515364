<div class="flex flex-col justify-center bg-gray-100" *ngIf="editedReport && editedReport.reportName">
	<div class="mx-auto w-full" style="max-width: 1200px;">

		

		<div [ngSwitch]="templatka">

			<div *ngSwitchCase="'start'" class="flex flex-col mt-4">
				<app-article [wariant]="'INTRO'" [artykul]="editedReport.reportName"></app-article>				
			</div>

			<div *ngSwitchCase="'sign'" class="flex flex-row">
				<button (click)="templatka = 'umowa'" class="p-element lg:w-1/4 my-4 p-button p-component" pButton [label]="edytuj" icon="pi pi-angle-double-left"></button>
				<button [routerLink]="['/console/documents']" class="p-element lg:w-1/4 my-4 p-button p-component ml-4" pButton [label]="wrocdopanelu" icon="pi pi-angle-double-left"></button>
			</div>

			<div *ngSwitchCase="'umowa'" class="flex flex-col">

				<div class="flex flex-row mb-2 mt-3">
					<button (click)="templatka = 'start'" style="max-height: 55px;" class="w-full lg:w-1/4 mr-auto" pButton [label]="wroc" icon="pi pi-angle-double-left"></button>
					<h1 class="w-full px-4 lg:w-1/2 m-auto text-xl"><span translate>Kwestionariusz</span> <span translate>{{editedReport.label}}</span></h1>
					<button (click)="templatka = 'sign'" style="max-height: 55px;" [disabled]="!czyMoznaIscDalej" class="w-full lg:w-1/4 ml-auto" pButton [label]="przejdzdalej" icon="pi pi-angle-double-right"></button>
				</div>

				<ng-container *ngIf="editedReport.reportName == 'rejestracjaJdg'">
					<app-register-jdg></app-register-jdg>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'rejestracjaSpzoo'">
					<app-register-zoo></app-register-zoo>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'rozliczeniePodatkowe'">
					<app-tax></app-tax>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'freistellung'">
					<app-freistellung></app-freistellung>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'gewerbeOpieka'">
					<app-gewerbe-care></app-gewerbe-care>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'gewerbeBudowlanka'">
					<app-gewerbe-construction></app-gewerbe-construction>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'gewerbeTransort'">
					<app-gewerbe-transport></app-gewerbe-transport>
				</ng-container>

				<ng-container *ngIf="editedReport.reportName == 'gewerbeOgolna'">
					<app-gewerbe></app-gewerbe>
				</ng-container>

			</div>

			<div *ngSwitchCase="'sign'">
				<h1 translate>Podpisz i wyślij dokuementy</h1>
				<app-sign></app-sign>
				<!-- <button pButton (click)="templatka = 'umowa'" type="button" class="btn btn-primary btn-rounded">Wróć</button> -->
			</div>

			<div *ngSwitchCase="'umowa'" class="flex flex-row mb-4 mt-2 mb-16">
				<button (click)="templatka = 'start'" class="w-full lg:w-1/4 mr-auto" pButton [label]="wroc" icon="pi pi-angle-double-left"></button>
				<h1 class="w-full lg:w-1/4 m-auto prose lg:prose-xl">	</h1>
				<button (click)="templatka = 'sign'" [disabled]="!czyMoznaIscDalej"  class="w-full lg:w-1/4 ml-auto" pButton [label]="przejdzdalej" icon="pi pi-angle-double-right"></button>
			</div>


		</div>

	</div>
</div>

<app-stopka></app-stopka>