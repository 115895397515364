import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CbbState } from "../cbbmodels/cbbstate";
import { DokToSig } from "../cbbmodels/doktosig";
import { File } from '../cbbmodels/file';
import { Report } from "../cbbmodels/report";

export const getCbbState = createFeatureSelector<CbbState>('cbbState');

export const getUserProfile = () => createSelector(getCbbState, state => state.userConfig);

// export const getUserEmail = () => createSelector(getCbbState, state => state.email);  //TODO: wyłączyć
export const getUserEmailDoWysylki = () => createSelector(getCbbState, state => state.email);
export const getUserphoneNumberDoWysylki = () => createSelector(getCbbState, state => state.phoneNumber);
export const getCognitoUsername = () => createSelector(getCbbState, state => state.cognitoUsername);

export const getReport = (path:string) => createSelector(getCbbState, state => state.reports[path]);

export const getEditedReport = () => createSelector(getCbbState, state => { 
	if (state.uidEditedReport && state.uidEditedReport > 0) {
		let idx = state.reports.findIndex((r:Report) => r.uid == state.uidEditedReport);
		if(idx >=0 ) return state.reports[idx];
	}
	else return null;
} );

export const getReports = () => createSelector(getCbbState, state => state.reports);

export const getFiles = (rep: Report) => createSelector(getCbbState, state => {
	if(!state.files) return [];
	return state.files.filter((f:File) => 
															(
																(
																	f.reportName == 'userfile'
																)
																||
																(
																	f.reportName == rep.reportName 
																	&& 	f.position.toString() == rep.position.toString() 
																)
															)
															&& 	f.cognitoUsername == rep.cognitoUsername 
														)
});

export const getFilesByCognitoUsername = (cognitoUsername: string) => createSelector(getCbbState, state => {
	if(!state.files) return [];
	return state.files.filter((f:File) => f.cognitoUsername == cognitoUsername )
});
