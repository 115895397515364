<p-menubar [model]="itemsWylogowany">
	<ng-template pTemplate="item" let-item let-i="index">
		<i [ngClass]="item.icon"></i>
		<a translate [routerLink]="item.url">{{item.label | translate}}</a>
	</ng-template>
		<div class="flex">
			<viz-auth-auth-panel [jestemWHome]="false"></viz-auth-auth-panel>
		</div>
</p-menubar>

<viz-auth-right-panel></viz-auth-right-panel>

<p-toast></p-toast>

<router-outlet></router-outlet>

