import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

@Component({
	selector: 'viz-auth-right-panel',
	templateUrl: './right-panel.component.html',
	styleUrls: ['../scss/styles.scss', './right-panel.component.scss']
})
export class RightPanelComponent implements OnInit, OnDestroy, AfterViewInit {

	// @ViewChild('drawer') drawer: any;

	panelVisible: boolean = false;
	$sub: any;
	$sub2: any;
	messSub$: any;
	message: string = '';
	shouldRun: boolean;
	actualView: string;

	loginState: boolean;
	loginState$: any;

	constructor(
		public authService: AuthCognitoService
	) {
	}

	ngAfterViewInit(): void {
	}

	ngOnDestroy(): void {
		if (this.$sub) this.$sub.unsubscribe();
		if (this.$sub2) this.$sub2.unsubscribe();
		if (this.messSub$) this.messSub$.unsubscribe();
	}

	ngOnInit(): void {
		this.messSub$ = this.authService.message$.subscribe((x: any) => { this.message = x; });
		this.$sub = this.authService.onTogglePanel.subscribe((x: string) => {
			this.actualView = x;
			this.toggle();
		})

		this.$sub2 = this.authService.changeViewSub.subscribe((x: string) => {
			this.actualView = x;
			if (x == 'profile') {
				// if(this.drawer) this.drawer.close();
				this.panelVisible = false;
			}
		})
	}

	toggle() {
		if (this.panelVisible) {
			// this.drawer.close()
			setTimeout(() => {
				this.panelVisible = false;
			}, 200);
		} else {
			this.panelVisible = true;
			// this.drawer.open();
		}
	}

	changeView(view: string) {
		this.authService.changeView(view);
	}

	sprobujUkryc() {
		this.panelVisible = false;
		// if(!this.drawer.opened) this.drawer.toggle();
	}

	logout() {
		this.authService.logout();
		this.changeView('login');
		this.toggle();
	}


	showBasicDialog() {
		this.panelVisible = !this.panelVisible;
	}

}
