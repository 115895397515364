export const environment = {
  production: false,
	ApiUrl: 'https://api.akta24.de',
	ApiUrlxx: 'http://116.203.191.48:3001',
	ApiUrlx: 'http://localhost:3001',
  CognitoUserPoolId: 'eu-central-1_bMsIus0P9',
  CognitoClientId: '7m6fkidut8nfprs2fq6v9eongr',
	StrapiUrlx: 'http://116.203.191.48:3007',
	StrapiUrl: 'https://cms.akta24.de',
	StrapiToken: 'da1c86f38bf8a00652840da2f7ec6db738de86ae62d5081f9800c20b92cb554a88ec9d3483ee75390f0096fca90d42c66adcd69499860af70f8a279facd502a10982f5c3a4470ebef1652cd6d32dc6fc0c977ff9b526522349ad416a33f0e1720d740c223a2de7f0d7c8c9cbfa98c53e1b1258fe2f186f3e1e0df5b9b40dc976'
};
