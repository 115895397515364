import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApperanceService } from '../apperance.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit, OnDestroy {

	artykul$;
	aSub;
	lSub;
	contentArt;
	strapiUrl: string = '';
	slug: string = 'regulamin';
	rt;

	constructor(
		private apperanceService: ApperanceService,
		private translate: TranslateService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.strapiUrl = environment.StrapiUrl;

		// pobierz slug z route
		this.slug = this.route.snapshot.paramMap.get('slug');


		this.lSub = this.apperanceService.currentLanguage$
			.subscribe(x => {
				this.pobierzStopke(this.slug)
			})


		this.rt = this.route.paramMap.subscribe(params => {
			this.slug = params.get('slug');
			this.pobierzStopke(this.slug);
		});

	}

	ngOnDestroy(): void {
		if (this.aSub) this.aSub.unsubscribe();
		if (this.lSub) this.lSub.unsubscribe();
		if (this.rt) this.rt.unsubscribe();
	}

	pobierzStopke(slug: string) {
		if (!this.slug) return;

		console.log('slug ', slug);

		this.aSub = this.apperanceService.getStopkaBySlug(this.slug)
			.subscribe(art => {
				this.contentArt = art;
				this.contentArt.data[0].attributes.tresc = this.contentArt.data[0].attributes.tresc.replace(/\/uploads/g, 'https://cms.akta24.de/uploads');
				// this.contentArt.data[0].attributes.tresc = this.contentArt.data[0].attributes.tresc.replace(/<img src/g, '<img width=300 src');

				if (!this.contentArt || this.contentArt.data.length == 0) {
					this.contentArt = {
						data: [{
							attributes: {
								tytul: 'Uzupełnij artykuł dla języka: ' + this.apperanceService.currentLanguage$.value,
								tresc: 'Brak artykułu'
							}
						}]
					}
				}
			})
	}

}
