import { ApperanceService } from './../apperance.service';
import { Component } from '@angular/core';

@Component({
	selector: 'app-flags',
	templateUrl: './flags.component.html',
	styleUrls: ['./flags.component.scss']
})
export class FlagsComponent {

	lang:string = '';

	constructor(
		private apperanceService: ApperanceService
	) {
		this.lang = localStorage.getItem('cbblang')
	}

	useLanguage(lang: string) {
		this.lang = lang;
		this.apperanceService.changeLocale(lang);
	}

}
