<div class="w-full bg-cover bg-left bg-top mb-8" style="background-image: url(/assets/bground.jpg)">
	<div class="w-full flex flex-col md:flex-row justify-evenly flex-wrap overflow-hidden p-0 md:p-6" style="background-color: rgba(0,0,0,.4);">
		<div class="md:w-[40%] m-1">
			<div class="w-full">
				<app-article [headerClass]="'headerArticleClassLight'" [textClass]="'textArticleClassLight'" [dividerClass]="'dividerArticleClassLight'" [artykul]="'artykul_na_pierwszej_stronie_obok_logowania'"></app-article>
			</div>
			<div class="w-full flex justify-end">
				<button type="button" [routerLink]="['/console']" class="inline-block px-12 py-5 mt-20 ml-auto border-2 border-white hover:bg-blue-600 text-white font-large text-2xl leading-tight uppercase rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out" translate>Złóż nowy wniosek</button>
			</div>
		</div>
		<div class="md:w-[30%] m-4 bg-gray-50">
			<app-flags></app-flags>
			<viz-auth-auth-panel [jestemWHome]="true"></viz-auth-auth-panel>
		</div>
	</div>
	<div class="w-full h-[48px] grid grid-cols-5 m-0" style="background-color: rgba(0,0,0,.4);">
		<div class="h-full col-span-2">x</div>
		<div class="h-full" style="background-image: url(/assets/down.svg); background-repeat: no-repeat; background-position: bottom;"></div>
		<div class="h-full col-span-2">x</div>
	</div>
</div>





<div class="surface-section px-4 py-8 mt-8 md:px-6 lg:px-8 text-center " *ngIf="zalety">

	<ng-container *ngIf="wstep">
		<div [innerHTML]="wstep.tresc"></div>
	</ng-container>

	<div class="flex flex-wrap">
		<ng-container *ngFor="let zaleta of zalety">
			<ng-container *ngIf="zaleta.slug != 'wstep'">
				<div class="col-12 md:col-4 mb-4 px-5">
					<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
						<i [class]="zaleta.ikona + ' text-4xl text-blue-500'"></i>
					</span>
					<div class="text-900 text-xl mb-3 font-medium">{{zaleta.tytul}} {{zaleta.ikona}}</div>
					<span class="text-700 line-height-3">{{zaleta.tresc}}</span>
				</div>
			</ng-container>
		</ng-container>

		<!-- <div class="col-12 md:col-4 mb-4 px-5">
			<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
				<i class="pi pi-desktop text-4xl text-blue-500"></i>
			</span>
			<div class="text-900 text-xl mb-3 font-medium">Built for Developers</div>
			<span class="text-700 line-height-3">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
		</div>
		<div class="col-12 md:col-4 mb-4 px-5">
			<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
				<i class="pi pi-lock text-4xl text-blue-500"></i>
			</span>
			<div class="text-900 text-xl mb-3 font-medium">End-to-End Encryption</div>
			<span class="text-700 line-height-3">Risus nec feugiat in fermentum posuere urna nec. Posuere sollicitudin aliquam ultrices sagittis.</span>
		</div>
		<div class="col-12 md:col-4 mb-4 px-5">
			<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
				<i class="pi pi-check-circle text-4xl text-blue-500"></i>
			</span>
			<div class="text-900 text-xl mb-3 font-medium">Easy to Use</div>
			<span class="text-700 line-height-3">Ornare suspendisse sed nisi lacus sed viverra tellus. Neque volutpat ac tincidunt vitae semper.</span>
		</div>
		<div class="col-12 md:col-4 mb-4 px-5">
			<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
				<i class="pi pi-globe text-4xl text-blue-500"></i>
			</span>
			<div class="text-900 text-xl mb-3 font-medium">Fast & Global Support</div>
			<span class="text-700 line-height-3">Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum tellus.</span>
		</div>
		<div class="col-12 md:col-4 mb-4 px-5">
			<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
				<i class="pi pi-github text-4xl text-blue-500"></i>
			</span>
			<div class="text-900 text-xl mb-3 font-medium">Open Source</div>
			<span class="text-700 line-height-3">Nec tincidunt praesent semper feugiat. Sed adipiscing diam donec adipiscing tristique risus nec feugiat. </span>
		</div>
		<div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
			<span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
				<i class="pi pi-shield text-4xl text-blue-500"></i>
			</span>
			<div class="text-900 text-xl mb-3 font-medium">Trusted Securitty</div>
			<span class="text-700 line-height-3">Mattis rhoncus urna neque viverra justo nec ultrices. Id cursus metus aliquam eleifend.</span>
		</div> -->
	</div>
</div>



<div class="m-4">
	<app-article [artykul]="'testowy-artykul'" [wariant]="'elegancki'"></app-article>
</div>

<app-stopka></app-stopka>