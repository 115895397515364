import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CbbState } from 'src/cbbmodels/cbbstate';
import { getCognitoUsername, getUserEmailDoWysylki } from 'src/cbboffice-rx-store/selectors';
import { Inbox } from '../../cbbmodels/inbox';
import { CbbService } from '../cbb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.scss']
})
export class MailboxComponent implements OnInit {

	inbox: Inbox[] = [];
	first = 0;
	rows = 50;
	wybranyInbox: Inbox | undefined;
	visibleModal: boolean = false;
	kryteria: any = {};
	odpowiedz: string = '';
	newPanel: boolean = false;
	title: string = '';
	newContent: string = '';
	usub;
	email:string= '';
	cognitoUsername:string= '';
	nowawiadomosc: string = 'Nowa wiadomość';
	Wyslij: string = 'Wyślij';

	constructor(
		private cbbService: CbbService,
		private state: Store<CbbState>,
		private translate: TranslateService
	) { 
		// this.usub = this.state.select(getUserEmailDoWysylki())
		this.usub = this.state.select(getCognitoUsername())
			.subscribe((wynik) => {
				this.cognitoUsername = wynik;
				this.getInboxs();
			});
	}

	ngOnInit() {
		this.nowawiadomosc = this.translate.instant('Nowa wiadomość');
		this.Wyslij = this.translate.instant('Wyślij');
	}

	getInboxs() {
		this.cbbService.getMessages(this.cognitoUsername, this.kryteria)
			.subscribe((m:Array<Inbox>) => {
				this.inbox = m;
			})
	}

	next() {
		this.first = this.first + this.rows;
	}

	prev() {
		this.first = this.first - this.rows;
	}

	reset() {
		this.first = 0;
	}

	isLastPage(): boolean {
		return this.inbox ? this.first === (this.inbox.length - this.rows) : true;
	}

	isFirstPage(): boolean {
		return this.inbox ? this.first === 0 : true;
	}

	showModalDialog(inbox: Inbox) {
		this.wybranyInbox = inbox;
		this.read(inbox);
		this.visibleModal = true;
	}
	
	reply() {
		if(!this.wybranyInbox) return;
		let inbox:Inbox = new Inbox();
		inbox.content = this.odpowiedz;
		inbox.sender = this.cognitoUsername;
		inbox.receiver = 'Administracja';
		inbox.title = 'Re: ' + this.wybranyInbox.title;
		inbox.date = new Date();
		inbox.status = 'new';
		inbox.cognitoUsername = this.wybranyInbox.cognitoUsername;

		this.cbbService.reply(this.cognitoUsername,inbox)
			.subscribe((m:any) => {
				this.getInboxs();
				this.visibleModal = false;
				this.odpowiedz = '';
			})
	}
	
	// zmiana statusu na 'read'
	read(inbox: Inbox) {
		if(inbox.receiver === 'Administracja') return;
		inbox.status = 'read';
		this.cbbService.read(this.cognitoUsername,inbox)
			.subscribe((m:any) => {
				this.getInboxs();
			})
	}

	newSend() {
		let inbox:Inbox = new Inbox();
		inbox.content = this.newContent;
		inbox.sender = this.email;
		inbox.receiver = 'Administracja';
		inbox.title = this.title;
		inbox.date = new Date();
		inbox.status = 'new';
		inbox.cognitoUsername = this.cognitoUsername;
		inbox.email = this.email;

		this.cbbService.reply(this.cognitoUsername,inbox)
			.subscribe((m:any) => {
				this.getInboxs();
				this.newPanel = false;
				this.title = '';
				this.newContent = '';
			})
	}
}
