import { CbbformService } from './../cbbform.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../cbbmodels/cbbstate';
import { Report } from '../../cbbmodels/report';
import { getEditedReport } from '../../cbboffice-rx-store/selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-createdoc',
	templateUrl: './createdoc.component.html',
	styleUrls: ['./createdoc.component.scss']
})
export class CreatedocComponent {

	templatka: string = 'start';					//TODO: wrócić tutaj
	// templatka: string = 'sign';
	// templatka: string = 'umowa';

	Subs;
	editedReport: Report;
	czyMoznaIscDalej: boolean = true;
	wrocdopanelu: string;
	wroc: string;
	przejdzdalej: string;
	edytuj: string;

	constructor(
		private state: Store<CbbState>,
		private router: Router,
		private cbbForm: CbbformService,
		private translate: TranslateService
	) {
		this.czyMoznaIscDalej = true;
		this.cbbForm.czyMonzaIscDalej$.subscribe((v: boolean) => {
			if (this.templatka == 'umowa') {
				setTimeout(() => {
					this.czyMoznaIscDalej = v;
				},11);
			} else {
				this.czyMoznaIscDalej = true;
			}
		});

		this.cbbForm.idzDalej$.subscribe((v: boolean) => {
			this.templatka = 'sign';
		});

		this.cbbForm.idzDoUmowy$.subscribe((v: boolean) => {
			this.templatka = 'umowa';
		});
		this.wrocdopanelu = this.translate.instant('Wróć do panelu użytkownika');
		this.wroc = this.translate.instant('Wróć');
		this.przejdzdalej = this.translate.instant('Przejdź dalej');
		this.edytuj = this.translate.instant('Edytuj');
	}

	ngOnInit(): void {
		this.Subs = this.state.select(getEditedReport())
			.subscribe((r: Report) => {
				if (!r || r.reportName == '') { this.router.navigate(['console/documents']); return; }
				// if(r.status == 'signed' || r.status == 'edited' || r.status == 'accepted' || r.status == 'sended') { this.templatka = 'sign';  }
				if (r.status == 'signed' || r.status == 'accepted' || r.status == 'sended') { this.templatka = 'sign'; }
				if (r.status == 'edited' && this.templatka != 'sign') { this.templatka = 'umowa'; }
				this.editedReport = r;
			})
	}


	ngOnDestroy(): void {
		if (this.Subs) this.Subs.unsubscribe();
	}

}
