import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../cbbmodels/cbbstate';
import { Report } from '../../cbbmodels/report';
import { reportToEdit } from '../../cbboffice-rx-store/cbbactions';
import { getReports } from '../../cbboffice-rx-store/selectors';

@Component({
	selector: 'app-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

	SubMod;
	SubRou;
	reports: Report[];

	constructor(
		private state: Store<CbbState>,
		private router: Router,
	) { }

	ngOnInit(): void {
		this.SubMod = this.state.select(getReports())							//TODO: pobrać też moduły z innych raportów !!!
			.subscribe(m => {
				this.reports = m;
			})
	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
	}

	edit(r: Report) {
		this.state.dispatch(reportToEdit({ rep: r }))
		this.router.navigate(['/editdoc'])
	}
}
