import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of, tap, EMPTY } from 'rxjs';
import { CbbState } from '../cbbmodels/cbbstate';
import { DokToSig } from '../cbbmodels/doktosig';
import { Report } from '../cbbmodels/report';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
// import { cloneDeep } from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class MongoService {

	apiUrl: string;
	tokenJWT: string | null;
	hdr: any;

	constructor(
		private http: HttpClient,
		private translate: TranslateService,
	) {
		this.apiUrl = environment.ApiUrl;
		this.getHdr()
	}

	getHdr() {
		this.tokenJWT = localStorage.getItem('cognitoToken');
		this.hdr = {
			'Content-Type': 'application/json',
			'accept': '*/*',
			'Authorization': `Bearer ${this.tokenJWT}`
		};
	}

	getCbbStateByCognitoUserName(payload: { cognitoUsername: string, type: string }) {
		this.getHdr();
		return this.http.get(this.apiUrl + '/cbb/getcbbstorage?cun=' + payload.cognitoUsername, { headers: this.hdr })
			.pipe(
				map(x => {
					if (!x) {
						let cbbState: CbbState = new CbbState();
						cbbState.cognitoUsername = payload.cognitoUsername
						// cbbState.email = x.email
						this.saveCbbState({ cbbState })
							.subscribe(nx => {
							});
						return cbbState;
					} else return x;
				})
			)
	}

	getCbbStateFilesByCognitoUsername(payload: { cognitoUsername: string }) {
		this.getHdr();
		return this.http.get(this.apiUrl + '/cbb/getcbbfiles?cun=' + payload.cognitoUsername, { headers: this.hdr })
			.pipe(
				map(x => {
					if (!x) {
						return EMPTY;
					} else return x;
				})
			)
	}

	saveCbbState(payload) {
		this.getHdr();
		return this.http.post(this.apiUrl + '/cbb/savecbbstorage', payload, { headers: this.hdr })
	}

	sendEmailToClient(payload) {
		this.getHdr();
		return this.http.post(this.apiUrl + '/cbb/sendemailtoclient', payload, { headers: this.hdr })
	}

	//props<{dok: DokToSig, file: File, rep: Report}>()
	prepareDokFromReportAndGeneratePDF(payload: { dok: DokToSig, file: File, rep: Report }) {
		this.getHdr();
		return this.http.post(this.apiUrl + '/document/pdf' + payload.dok.path, payload, { headers: this.hdr })
	}

	signDokFromReport(payload: { dok: DokToSig, rep: Report, signFile: string, signedFile: string }) {
		this.getHdr();
		return this.http.post(this.apiUrl + '/document/sign', payload, { headers: this.hdr })
	}

	autopodpis(payload: { dok: DokToSig, rep: Report }) {
		this.getHdr();
		return this.http.post(this.apiUrl + '/document/autopodpis', payload, { headers: this.hdr })
	}

	//tylko dla nowych
	getNewDokToSig(reportName: string, uidReport: number) {										//TODO: ciepnąć gdzieś do bazy i załadować jakoś wcześniej

		let dokumenty: Array<DokToSig> = [];

		switch (reportName) {
			case 'rejestracjaSpzoo':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/fragebogen2', label: 'Kwestionariusz do Urzędu Skarbowego', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/antrag1', label: 'Wniosek rezydenta', uid: '' }))

				break;
			case 'rejestracjaJdg':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/fragebogen1', label: 'Wniosek do Urzędu Skarbowego', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/antrag1', label: 'Wniosek rezydenta', uid: '' }))

				break;
			case 'rozliczeniePodatkowe':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo2', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht3', label: 'Uprawnienie do reprezentacji w sprawach podatkowych', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht4', label: 'Pełnomocnictwo dot. komunikacji miejskiej', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/tax', label: 'Informacje do przeprowadzenia rozliczenia', uid: '' }))

				break;
			case 'freistellung':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/antrag1', label: 'Wniosek rezydenta', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht4', label: 'Pełnomocnictwo dot. komunikacji miejskiej', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/antrag2', label: 'Wniosek o zwolnienie podatkowe', uid: '' }))

				break;
			case 'gewerbeOpieka':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-care', label: 'Umowa Gewerbe opieka', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/fragebogen1', label: 'Wniosek do Urzędu Skarbowego', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-anmeldung1', label: 'Formularz Gewerbe meldunek', uid: '', autopodpis: true }))

				break;
			case 'gewerbeBudowlanka':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-construction', label: 'Umowa Gewerbe branża budowlana', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/fragebogen1', label: 'Wniosek do Urzędu Skarbowego', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-anmeldung1', label: 'Formularz Gewerbe meldunek', uid: '', autopodpis: true}))

				break;
			case 'gewerbeTransort':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-transport', label: 'Umowa Gewerbe Transport', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/fragebogen1', label: 'Wniosek do Urzędu Skarbowego', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-anmeldung1', label: 'Formularz Gewerbe meldunek', uid: '', autopodpis: true }))

				break;
			case 'gewerbeOgolna':
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/vollmacht2', label: 'Pełnomocnictwo', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-ogolna', label: 'Umowa ogólna', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/fragebogen1', label: 'Wniosek do Urzędu Skarbowego', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/rodo1', label: 'Klauzula RODO', uid: '' }))
				dokumenty.push(new DokToSig({ uidReport: uidReport, reportName: reportName, stan: 'none', path: '/gewerbe-anmeldung1', label: 'Formularz Gewerbe meldunek', uid: '', autopodpis: true }))
				break;
		}


		// change label to locale string
		for(let dok of dokumenty) {
			dok.label = this.translate.instant(dok.label);
		}

		console.log('potłumaczone dokumenty',dokumenty);
		

		return dokumenty;
	}
}
