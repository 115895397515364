import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { File } from '../../cbbmodels/file';
import { environment } from '../../environments/environment';

export class Osrc {
	constructor(uri:string, cors:boolean) {
		this.url = uri;
		this.withCredentials = cors;
	}
	url: string;
	withCredentials: boolean;
}

@Component({
  selector: 'app-doctoprev',
  templateUrl: './doctoprev.component.html',
  styleUrls: ['./doctoprev.component.scss']
})
export class DoctoprevComponent {

	@Input() file: File;
	@Output('cancel') onCancel = new EventEmitter<string>();

	path: string = '';
	html: string = '';
	uri: string = '';
	osrc: Osrc;

	ngOnChanges(changes: SimpleChanges) {
		if(!changes.file.currentValue) return;
		if(!changes.file.currentValue.fileName) return;
		this.uri = environment.ApiUrl + '/document/pdf/getfile?filename=' + changes.file.currentValue.fileName + '&cognitoUsername=' + changes.file.currentValue.cognitoUsername;
		this.osrc = new Osrc(this.uri, false);
	}

}
