import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CbbState } from '../../cbbmodels/cbbstate';
import { getCbbFilesStateAction, getCbbStateAction } from '../../cbboffice-rx-store/cbbactions';
import { AuthCognitoService } from '../auth-cognito/auth-cognito.service';
import { MenuItem } from 'primeng/api';
import { UserConfig } from '../../cbbmodels/user-config';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
// import { getUserEmail } from '../../cbboffice-rx-store/selectors';
import { ApperanceService } from '../apperance.service';
import { TranslateService } from '@ngx-translate/core';
import { getCognitoUsername } from 'src/cbboffice-rx-store/selectors';

@Component({
	selector: 'app-console',
	templateUrl: './console.component.html',
	styleUrls: ['./console.component.scss']
})
export class ConsoleComponent implements OnInit, OnDestroy {

	constructor(
		private state: Store<CbbState>,
		private authService: AuthCognitoService,
		private translate: TranslateService,
		private router: Router,
	) { 
		this.usub = this.state.select(getCognitoUsername())
			.subscribe(u => {
				this.cognitoUsername = u;
			})

	}

	// email: string = null;
	cognitoUsername: string = null;
	items: MenuItem[];
	sub$;
	sub;
	sub2;
	usub;
	profile;
	user$
	pobraloSie: boolean = false;

	ngOnInit(): void {

		this.items = [
			{ label: this.translate.instant('Twój profil'), icon: 'pi pi-fw pi-home', routerLink: ['/console/profile'] },
			{ label: this.translate.instant('Złożone wnioski'), icon: 'pi pi-fw pi-calendar', routerLink: ['/console/documents'] },
			{ label: this.translate.instant('Przesłane pliki'), icon: 'pi pi-fw pi-file', routerLink: ['/console/files'] },
			{ label: this.translate.instant('Korespondencja'), icon: 'pi pi-fw pi-envelope', routerLink: ['/console/mailbox'] },
		];

		// TUTAJ POBIERAM DANE Z BAZY - JEŻELI BRAK.

		this.sub2 = this.authService.userInfo$
			.subscribe((u:any) => {
					if(u?.cognitoUsername) {
						this.state.dispatch(getCbbStateAction({cognitoUsername: u.cognitoUsername}));
					}
			})




	}

	ngOnDestroy() {
		if (this.sub) this.sub.unsubscribe();
		if (this.sub2) this.sub2.unsubscribe();
	}
}

