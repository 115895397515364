import { DokToSig } from './doktosig';
import { Field } from './field';
import { IReport } from './interfaces/ireport';

export class Report implements IReport {
	uid: number;
	cognitoUsername: string = '';
	reportName: string = '';
	position: number = 0;
	filename: string = '';
	// email: string = '';
	status: string = 'new';
	fields: Array<Field> = []
	dokToSig: Array<DokToSig> = [];
	dateInsert: Date = new Date();
	label: string = '';
}