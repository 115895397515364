<div class="card mr-5 mb-5">
	<p-card>
		<app-info slug="wybor-wniosku"></app-info>
	</p-card>
</div>


<div class="grid mt-5" *ngIf="widok=='start'">
	<div class="col-2 hidden  md:block"></div>
	<div class="col">
		<div class="flex flex-column card-container blue-container">
			<a (click)="checked('1gewerbe')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Rejestracja Gewerbe</a>
			<a (click)="gotoNewModule('rejestracjaSpzoo', 'Rejestracja polskiej firmy do Urzędu Skarbowego dla polskiej spółki z o.o.')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Rejestracja polskiej firmy do Urzędu Skarbowego dla polskiej spółki z o.o.</a>
			<a (click)="gotoNewModule('rejestracjaJdg', 'Rejestracja polskiej firmy do Urzędu Skarbowego dla osób z działalnością gospodarczą')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Rejestracja polskiej firmy do Urzędu Skarbowego dla osób z działalnością gospodarczą</a>
			<a (click)="gotoNewModule('rozliczeniePodatkowe', 'Rozliczenie podatkowe')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Rozliczenie podatkowe</a>
			<a (click)="gotoNewModule('freistellung', 'Freistellung | Zwolnienie z podatku')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Freistellung | Zwolnienie z podatku</a>
		</div>
	</div>
	<div class="col-2 hidden  md:block"></div>
</div>


<div class="grid mt-5" *ngIf="widok=='2gewerbe'">
	<div class="col-12">
		<a (click)="checked('start')" style="text-decoration: underline; cursor: pointer;" translate>wróć do poprzedniego wyboru</a>
	</div>
	<div class="col-2 hidden  md:block"></div>
	<div class="col">
		<div class="flex flex-column card-container blue-container">
			<a (click)="gotoNewModule('gewerbeOpieka','Gewerbe: Opieka dla osób starszych')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Opieka dla osób starszych</a>
			<a (click)="gotoNewModule('gewerbeBudowlanka', 'Gewerbe: Rejestracja firmy z branży budowlanej')" class="w-full text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Rejestracja firmy z branży budowlanej</a>
			<a (click)="gotoNewModule('gewerbeTransort', 'Gerwerbe: Rejestracja firmy z branży transportowej')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>Rejestracja firmy z branży transportowej</a>
			<a (click)="gotoNewModule('gewerbeOgolna', 'Gewerbe: inna działalność gospodarcza')" class="text-2xl mybutton flex align-items-center justify-content-center h-4rem bg-blue-500 text-white border-round m-2 text-center hover:bg-blue-400" translate>inne ...</a>
		</div>
	</div>
	<div class="col-2 hidden  md:block"></div>
</div>

