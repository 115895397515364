import { map, mergeMap } from 'rxjs';
import { getFilesByCognitoUsername, getCognitoUsername } from './../../cbboffice-rx-store/selectors';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { getCbbFilesStateAction } from '../../cbboffice-rx-store/cbbactions';
import { CbbState } from '../../cbbmodels/cbbstate';
import { Store } from '@ngrx/store';
import { File } from '../../cbbmodels/file';

@Component({
	selector: 'app-files',
	templateUrl: './files.component.html',
	styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnDestroy {

	url: string = environment.ApiUrl + '/document/upload';
	uploadedFiles: any[] = [];
	chooseLabel: string = 'Wybierz plik';
	headers: any;
	usub: any;
	fsub: any;
	// email: string;
	cognitoUsername: string;
	allFiles: File[] = [];

	api: string = '';

	constructor(
		private messageService: MessageService,
		private state: Store<CbbState>,
		private translate: TranslateService
	) {
		this.chooseLabel = this.translate.instant('Wybierz plik');
		this.headers = {
			'Authorization': 'Bearer ' + localStorage.getItem('cognitoToken')
		}

		this.api = environment.ApiUrl;
		
		this.usub = this.state.select(getCognitoUsername())
			.pipe(
				mergeMap(cognitoUsername => {
					this.cognitoUsername = cognitoUsername;
					return this.state.select(getFilesByCognitoUsername(this.cognitoUsername))
						.pipe(
							map((f: any) => {
								return { cognitoUsername: cognitoUsername, files: f };
							})
						)
				})
			)
			.subscribe((wynik) => {
				this.allFiles = wynik.files;
				this.cognitoUsername = wynik.cognitoUsername;
			});

	}

	ngOnInit(): void {
		this.chooseLabel = this.translate.instant('Wybierz plik');
	}

	ngOnDestroy(): void {
		if(this.usub) this.usub.unsubscribe();
		if(this.fsub) this.fsub.unsubscribe();
	}

	myUploader(event) {
		for (let file of event.files) {
			this.uploadedFiles.push(file);
		}
		this.state.dispatch(getCbbFilesStateAction({ cognitoUsername: this.cognitoUsername }));
		this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
	}

	onUpload($event) {
	}

	pobierz(f: File) {
		// this.plikDoPodgladuUrl = environment.ApiUrl + '/document/pdf/getfile?filename=' + f.fileName + '&email=' + f.email;
		// this.plikDoPodgladuPodpisUrl = environment.ApiUrl + '/document/sign/getfile?filename=' + f.signPath + '&email=' + f.email;
		// this.plikDoPodgladu = f;
	}
}
