import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UserConfig } from '../cbbmodels/user-config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class CbbformService {

	constructor(
		private fb: FormBuilder,
		private translate: TranslateService
	) { }

	public czyMonzaIscDalej$ = new Subject<boolean>();
	public idzDoUmowy$ = new Subject<boolean>();
	public idzDalej$ = new Subject<boolean>();
	public errMessage$ = new Subject<string>();

	public getReligie(lang:string) {
		this.translate.use(lang);
		let katolicka = this.translate.instant('katolicka');
		let ewangelicka = this.translate.instant('ewangelicka');
		let bezReligii = this.translate.instant('bezReligii');
		let inne = this.translate.instant('inne');
	  return	[{ label: katolicka, value: 'RK' }, { label: ewangelicka, value: 'EV' }, { label: bezReligii, value: 'VD' }, { label: inne, value: 'IN' }];
	}

	public getTakNie(lang:string) {
		this.translate.use(lang);
		let tak = this.translate.instant('tak');
		let nie = this.translate.instant('nie');
		return	[{ label: tak, value: 'TAK' }, { label: nie, value: 'NIE' }];
	}				

	getStanyCywilne(lang:string) {
		//example form lang=='pl' stany_cywilne: any[] = [{ label: 'małżeństwo', value: 'małżeństwo' }, { label: 'związek partnerski', value: 'związek partnerski' }, { label: 'samotny', value: 'samotny' }, { label: 'rozwiedziony', value: 'rozwiedziony' }, { label: 'wdowiec', value: 'wdowiec' }, { label: 'wdowa', value: 'wdowa' }];
		this.translate.use(lang);
		let malzenstwo = this.translate.instant('małżeństwo');
		let zwiazekPartnerski = this.translate.instant('związek partnerski');
		let samotny = this.translate.instant('samotny');
		let rozwiedziony = this.translate.instant('rozwiedziony');
		let wdowiec = this.translate.instant('wdowiec');
		let wdowa = this.translate.instant('wdowa');
		return	[{ label: malzenstwo, value: 'małżeństwo' }, { label: zwiazekPartnerski, value: 'związek partnerski' }, { label: samotny, value: 'samotny' }, { label: rozwiedziony, value: 'rozwiedziony' }, { label: wdowiec, value: 'wdowiec' }, { label: wdowa, value: 'wdowa' }];
	}

	//getOsoby
	//[{ label: 'Osoba fizyczna', value: 'Osoba fizyczna' }, { label: 'Osoba nie fizyczna', value: 'Osoba nie fizyczna' }];
	getOsoby(lang:string) {
		this.translate.use(lang);
		let osobaFizyczna = this.translate.instant('osoba fizyczna');
		let osobaNieFizyczna = this.translate.instant('osoba nie fizyczna');
		return	[{ label: osobaFizyczna, value: 'Osoba fizyczna' }, { label: osobaNieFizyczna, value: 'Osoba nie fizyczna' }];
	}

	//[{ label: 'chcę odpłątnie to zlecić', value: 'chcę odpłątnie to zlecić' }, { label: 'Załatwię to sam', value: 'Załatwię to sam' }];		//TODO: uwzględnić język
	getCzyZlecam(lang:string) {
		this.translate.use(lang);
		let chceOdpowiedzialnosc = this.translate.instant('chcę odpłątnie to zlecić');
		let zalezeOdCeny = this.translate.instant('Załatwię to sam');
		return	[{ label: chceOdpowiedzialnosc, value: 'chcę odpłątnie to zlecić' }, { label: zalezeOdCeny, value: 'Załatwię to sam' }];
	}



	czyMonzaIscDalej(stan: boolean) {
		this.czyMonzaIscDalej$.next(stan);
	}

	idzdalej() {
		this.czyMonzaIscDalej$.next(true);
		setTimeout(() => {
			this.idzDalej$.next(true);
		}, 500);
	}

	idzdoumowy() {
			this.idzDoUmowy$.next(true);
	}

	formatujDate(data: Date) {
		let rok = data.toLocaleString("default", { year: "numeric" })
		let miesiac = data.toLocaleString("default", { month: "2-digit" })
		let dzien = data.toLocaleString("default", { day: "2-digit" })
		return rok + '-' + miesiac + '-' + dzien
	}

	naprawDate(data: string) {
		let rok = data.slice(6, 10);
		let miesiac = data.slice(3, 5);
		let dzien = data.slice(0, 2);
		return rok + '-' + miesiac + '-' + dzien
	}

	zepsujDate(data: string) {
		let rok = data.slice(0, 4);
		let miesiac = data.slice(5, 7);
		let dzien = data.slice(8, 10);
		return dzien + '.' + miesiac + '.' + rok
	}

	setDefaultFormValue(form: FormGroup, values: UserConfig) {
		if (!values) return form;
		let czyKtoresJest: boolean = Object.values(form.getRawValue()).every((f: string) => f != ''); //wartości domyślne tylko wtedy gdy formularz pusty
		if (!czyKtoresJest) {
			form.get('pos_4_name')?.setValue(values.lastName);
			form.get('pos_4_vorname')?.setValue(values.firstName);
			form.get('pos_6_geburtsdatum')?.setValue(this.formatujDate(new Date(values.birthDate)));
			form.get('pos_10_ort')?.setValue(values.country);
			form.get('pos_9_wohnort')?.setValue(values.city);
			form.get('pos_23_mail')?.setValue(values.email);
			form.get('pos_8_hausnummer')?.setValue(values.local);
			form.get('pos_8_hausnummerzusats')?.setValue(values.possession);
			form.get('pos_9_postleitzahl')?.setValue(values.zipCode);
			form.get('pos_7_strabe')?.setValue(values.address);
		}

		return form;
	}

	errMess(mess: string) {
		this.errMessage$.next(mess);
	}

	getForm(reportType: string) {
		let fbg = this.fb.group({})

		switch (reportType) {
			case 'gewerbeOgolna':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addGewerbeOgolna_7(fbg);						// [7] UMOWA OGOLNA.docx
				fbg = this.addFragebogen1_8(fbg);							// [8] str 26 Wniosek Gewerbe.pdf
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addAnmeldung1_10(fbg);							// [10] formular-gewerbeanmeldung.pdf
				break;

			case 'freistellung':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addAngrag1_16(fbg);								// [16] /pdf/antrag1
				fbg = this.addVollmacht3_15(fbg);							// [15] /pdf/vollmacht3
				fbg = this.addAngrag2_17(fbg);								// [17] /pdf/antrag2
				// fbg = this.addFreistellung_11(fbg);						// [11] /pdf/freistellung
				break;

			case 'gewerbeOpieka':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addCare_4(fbg);										// [4] /pdf/gewerbe-care
				fbg = this.addFragebogen1_8(fbg);							// [8] str 26 Wniosek Gewerbe.pdf
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addAnmeldung1_10(fbg);							// [10] formular-gewerbeanmeldung.pdf
				break;

			case 'gewerbeBudowlanka':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addGewerbeConstruction_5(fbg);			// [5] /pdf/gewerbe-construction
				fbg = this.addFragebogen1_8(fbg);							// [8] str 26 Wniosek Gewerbe.pdf
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addAnmeldung1_10(fbg);							// [10] formular-gewerbeanmeldung.pdf
				break;

			case 'gewerbeTransort':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addGewerbeTransport_6(fbg);				// [6] /pdf/gewerbe-transport
				fbg = this.addFragebogen1_8(fbg);							// [8] str 26 Wniosek Gewerbe.pdf
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addAnmeldung1_10(fbg);							// [10] formular-gewerbeanmeldung.pdf
				break;

			case 'rejestracjaSpzoo':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addFrageborgen2_13(fbg);						// [13] /pdf/fragebogen2
				fbg = this.addAngrag1_16(fbg);								// [16] /pdf/antrag1
				break;

			case 'rejestracjaJdg':
				fbg = this.addVollmacht2_2(fbg);							// [2] CBB VOLLMACHT.docx				
				fbg = this.addRodo_9(fbg);										// [9] Klauzula RODO.docx.docx
				fbg = this.addFragebogen1_8(fbg);							// [8] str 26 Wniosek Gewerbe.pdf
				fbg = this.addAngrag1_16(fbg);								// [16] /pdf/antrag1
				break;

			case 'rozliczeniePodatkowe':
				fbg = this.addRodo2_14(fbg);									// [14] /pdf/rodo2
				fbg = this.addVollmacht3_15(fbg);							// [15] /pdf/vollmacht3
				fbg = this.addVollmacht1_3(fbg);							// [3] /pdf/vollmacht3
				fbg = this.tax(fbg)														// DOROBIĆ

				break;

			case 'do wyjaśnienia':
				fbg = this.addVollmacht1_1(fbg);									// [1] /pdf/vollmacht1 * (muszę dopytać, bo jest nieścisłość)
				fbg = this.addGewerbeAnmeldung2_12(fbg);					// [12] /pdf/gewerbe-Anmeldung2 ????? upewnić się czy to robimy
				break;
		}
		return fbg
	}

	addVollmacht2_2(fbg: FormGroup) {
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_6_miejsce_urodzenia')) fbg.addControl('pos_6_miejsce_urodzenia', new FormControl('', Validators.required));
		if (!fbg.contains('pos_6_kraj_urodzenia')) fbg.addControl('pos_6_kraj_urodzenia', new FormControl('', Validators.required));
		if (!fbg.contains('pos_6_geburtsdatum')) fbg.addControl('pos_6_geburtsdatum', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('land')) fbg.addControl('land', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		return fbg;
	}

	addGewerbeOgolna_7(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_2_vorname2')) fbg.addControl('pkt_2_vorname2', new FormControl(''));
		if (!fbg.contains('pkt_4_pesel')) fbg.addControl('pkt_4_pesel', new FormControl(''));
		if (!fbg.contains('pkt_5_seria_dowodu')) fbg.addControl('pkt_5_seria_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_5_numer_dowodu')) fbg.addControl('pkt_5_numer_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_6_obywatelstwo')) fbg.addControl('pkt_6_obywatelstwo', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_8_email')) fbg.addControl('pkt_8_email', new FormControl(''));
		if (!fbg.contains('pkt_9_telefon')) fbg.addControl('pkt_9_telefon', new FormControl(''));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pkt_10_co_bedzie_wykonywane')) fbg.addControl('pkt_10_co_bedzie_wykonywane', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_11_dzialalnosc_od')) fbg.addControl('pkt_11_dzialalnosc_od', new FormControl('', Validators.required));
		//if (!fbg.contains('pkt_11_dzialalnosc_do')) fbg.addControl('pkt_11_dzialalnosc_do', new FormControl('', Validators.required));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_1')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_1', new FormControl(''));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_2')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_2', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_rejestracji_Gewerbe', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));

		return fbg;
	}

	addFragebogen1_8(fbg: FormGroup) {
		if (!fbg.contains('pos_1_finanzamt')) fbg.addControl('pos_1_finanzamt', new FormControl(''));
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_5_geburtsname')) fbg.addControl('pos_5_geburtsname', new FormControl(''));
		if (!fbg.contains('pos_6_geburtsdatum')) fbg.addControl('pos_6_geburtsdatum', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_12_verheiratet')) fbg.addControl('pos_12_verheiratet', new FormControl(''));
		if (!fbg.contains('pos_12_verwitwet')) fbg.addControl('pos_12_verwitwet', new FormControl(''));
		if (!fbg.contains('pos_14_name')) fbg.addControl('pos_14_name', new FormControl(''));
		if (!fbg.contains('pos_14_vorname')) fbg.addControl('pos_14_vorname', new FormControl(''));
		if (!fbg.contains('pos_16_geburtsdatum')) fbg.addControl('pos_16_geburtsdatum', new FormControl(''));
		if (!fbg.contains('pos_28_iban')) fbg.addControl('pos_28_iban', new FormControl(''));
		if (!fbg.contains('pos_29_bic')) fbg.addControl('pos_29_bic', new FormControl(''));
		if (!fbg.contains('pos_111_col1')) fbg.addControl('pos_111_col1', new FormControl(''));
		if (!fbg.contains('pos_111_col3')) fbg.addControl('pos_111_col3', new FormControl(''));
		if (!fbg.contains('pos_124_freistellung')) fbg.addControl('pos_124_freistellung', new FormControl(''));
		if (!fbg.contains('pos_134_gesamtumzatz')) fbg.addControl('pos_134_gesamtumzatz', new FormControl(''));
		if (!fbg.contains('pos_134_chce_vat')) fbg.addControl('pos_134_chce_vat', new FormControl(''));
		if (!fbg.contains('religia')) fbg.addControl('religia', new FormControl('', Validators.required));
		if (!fbg.contains('religia_malzonka')) fbg.addControl('religia_malzonka', new FormControl(''));
		return fbg;
	}

	addRodo_9(fbg: FormGroup) {
		if (!fbg.contains('administrator_danych_osobowych')) fbg.addControl('administrator_danych_osobowych', new FormControl(''));
		if (!fbg.contains('rodo_akceptacja_regulaminu')) fbg.addControl('rodo_akceptacja_regulaminu', new FormControl('', Validators.required));
		if (!fbg.contains('rodo_przetwarzanie_danych')) fbg.addControl('rodo_przetwarzanie_danych', new FormControl('', Validators.required));
		if (!fbg.contains('rodo_dane_marketingowe')) fbg.addControl('rodo_dane_marketingowe', new FormControl(''));
		return fbg;
	}

	addAnmeldung1_10(fbg: FormGroup) {
		if (!fbg.contains('pos_1_finanzamt')) fbg.addControl('pos_1_finanzamt', new FormControl(''));
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_5_geburtsname')) fbg.addControl('pos_5_geburtsname', new FormControl(''));
		if (!fbg.contains('pos_6_geburtsdatum')) fbg.addControl('pos_6_geburtsdatum', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_12_verheiratet')) fbg.addControl('pos_12_verheiratet', new FormControl(''));
		if (!fbg.contains('pos_12_verwitwet')) fbg.addControl('pos_12_verwitwet', new FormControl(''));
		if (!fbg.contains('pos_14_name')) fbg.addControl('pos_14_name', new FormControl(''));
		if (!fbg.contains('pos_14_vorname')) fbg.addControl('pos_14_vorname', new FormControl(''));
		if (!fbg.contains('pos_16_geburtsdatum')) fbg.addControl('pos_16_geburtsdatum', new FormControl(''));
		if (!fbg.contains('pos_28_iban')) fbg.addControl('pos_28_iban', new FormControl(''));
		if (!fbg.contains('pos_29_bic')) fbg.addControl('pos_29_bic', new FormControl(''));
		if (!fbg.contains('pos_111_col1')) fbg.addControl('pos_111_col1', new FormControl(''));
		if (!fbg.contains('pos_111_col3')) fbg.addControl('pos_111_col3', new FormControl(''));
		if (!fbg.contains('pos_124_freistellung')) fbg.addControl('pos_124_freistellung', new FormControl(''));
		if (!fbg.contains('pos_134_gesamtumzatz')) fbg.addControl('pos_134_gesamtumzatz', new FormControl(''));
		return fbg;
	}

	addVollmacht1_1(fbg: FormGroup) {
		return fbg;
	}

	addVollmacht1_3(fbg: FormGroup) {
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_6_geburtsdatum')) fbg.addControl('pos_6_geburtsdatum', new FormControl('', Validators.required));
		return fbg;
	}

	addCare_4(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_2_vorname2')) fbg.addControl('pkt_2_vorname2', new FormControl(''));
		if (!fbg.contains('pkt_4_pesel')) fbg.addControl('pkt_4_pesel', new FormControl(''));
		if (!fbg.contains('pkt_5_seria_dowodu')) fbg.addControl('pkt_5_seria_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_5_numer_dowodu')) fbg.addControl('pkt_5_numer_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_6_obywatelstwo')) fbg.addControl('pkt_6_obywatelstwo', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_8_email')) fbg.addControl('pkt_8_email', new FormControl(''));
		if (!fbg.contains('pkt_9_telefon')) fbg.addControl('pkt_9_telefon', new FormControl(''));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pkt_10_co_bedzie_wykonywane')) fbg.addControl('pkt_10_co_bedzie_wykonywane', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_11_dzialalnosc_od')) fbg.addControl('pkt_11_dzialalnosc_od', new FormControl('', Validators.required));
		//// if (!fbg.contains('pkt_11_dzialalnosc_do')) fbg.addControl('pkt_11_dzialalnosc_do', new FormControl('', Validators.required));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_1')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_1', new FormControl(''));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_2')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_2', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_rejestracji_Gewerbe', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));

		if (!fbg.contains('agencja')) fbg.addControl('agencja', new FormControl(''));

		return fbg;
	}

	addGewerbeConstruction_5(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_2_vorname2')) fbg.addControl('pkt_2_vorname2', new FormControl(''));
		if (!fbg.contains('pkt_4_pesel')) fbg.addControl('pkt_4_pesel', new FormControl(''));
		if (!fbg.contains('pkt_5_seria_dowodu')) fbg.addControl('pkt_5_seria_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_5_numer_dowodu')) fbg.addControl('pkt_5_numer_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_6_obywatelstwo')) fbg.addControl('pkt_6_obywatelstwo', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_8_email')) fbg.addControl('pkt_8_email', new FormControl(''));
		if (!fbg.contains('pkt_9_telefon')) fbg.addControl('pkt_9_telefon', new FormControl(''));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pkt_10_co_bedzie_wykonywane')) fbg.addControl('pkt_10_co_bedzie_wykonywane', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_11_dzialalnosc_od')) fbg.addControl('pkt_11_dzialalnosc_od', new FormControl('', Validators.required));
		///if (!fbg.contains('pkt_11_dzialalnosc_do')) fbg.addControl('pkt_11_dzialalnosc_do', new FormControl('', Validators.required));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_1')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_1', new FormControl(''));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_2')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_2', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_rejestracji_Gewerbe', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));



		return fbg;
	}

	addGewerbeTransport_6(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_2_vorname2')) fbg.addControl('pkt_2_vorname2', new FormControl(''));
		if (!fbg.contains('pkt_4_pesel')) fbg.addControl('pkt_4_pesel', new FormControl(''));
		if (!fbg.contains('pkt_5_seria_dowodu')) fbg.addControl('pkt_5_seria_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_5_numer_dowodu')) fbg.addControl('pkt_5_numer_dowodu', new FormControl(''));
		if (!fbg.contains('pkt_6_obywatelstwo')) fbg.addControl('pkt_6_obywatelstwo', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_8_email')) fbg.addControl('pkt_8_email', new FormControl(''));
		if (!fbg.contains('pkt_9_telefon')) fbg.addControl('pkt_9_telefon', new FormControl(''));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pkt_10_co_bedzie_wykonywane')) fbg.addControl('pkt_10_co_bedzie_wykonywane', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_11_dzialalnosc_od')) fbg.addControl('pkt_11_dzialalnosc_od', new FormControl('', Validators.required));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_1')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_1', new FormControl(''));
		if (!fbg.contains('zgoda_na_przystapienie_do_cbboffice_pole_2')) fbg.addControl('zgoda_na_przystapienie_do_cbboffice_pole_2', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_rejestracji_Gewerbe', new FormControl(''));

		if (!fbg.contains('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczaly_obrot_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));
		if (!fbg.contains('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe')) fbg.addControl('przypuszczale_koszty_w_roku_nastepnym_rejestracji_Gewerbe', new FormControl(''));

		return fbg;
	}

	addFreistellung_11(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('nip')) fbg.addControl('nip', new FormControl(''));
		return fbg;
	}

	addGewerbeAnmeldung2_12(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_6_geburtsdatum')) fbg.addControl('pos_6_geburtsdatum', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('land')) fbg.addControl('land', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('geschlecht')) fbg.addControl('geschlecht', new FormControl('', Validators.required));
		if (!fbg.contains('betriebsstatte')) fbg.addControl('betriebsstatte', new FormControl('', Validators.required));
		if (!fbg.contains('hauptniederlassung')) fbg.addControl('hauptniederlassung', new FormControl('', Validators.required));
		if (!fbg.contains('dzialalnosc_dodaktowa_tak')) fbg.addControl('dzialalnosc_dodaktowa_tak', new FormControl('', Validators.required));
		if (!fbg.contains('dzialalnosc_dodaktowa_nie')) fbg.addControl('dzialalnosc_dodaktowa_nie', new FormControl('', Validators.required));
		if (!fbg.contains('dzialalnosc_od_kiedy')) fbg.addControl('dzialalnosc_od_kiedy', new FormControl('', Validators.required));
		if (!fbg.contains('rodzaj_dzialalnosci_przemysl')) fbg.addControl('rodzaj_dzialalnosci_przemysl', new FormControl('', Validators.required));
		if (!fbg.contains('rodzaj_dzialalnosci_rzemioslo')) fbg.addControl('rodzaj_dzialalnosci_rzemioslo', new FormControl('', Validators.required));
		if (!fbg.contains('rodzaj_dzialalnosci_handel')) fbg.addControl('rodzaj_dzialalnosci_handel', new FormControl('', Validators.required));
		if (!fbg.contains('rodzaj_dzialalnosci_rozny')) fbg.addControl('rodzaj_dzialalnosci_rozny', new FormControl('', Validators.required));
		if (!fbg.contains('liczba_pracownikow_pelny_etat')) fbg.addControl('liczba_pracownikow_pelny_etat', new FormControl('', Validators.required));
		if (!fbg.contains('liczba_pracownikow_niepelny_etat')) fbg.addControl('liczba_pracownikow_niepelny_etat', new FormControl('', Validators.required));
		if (!fbg.contains('liczba_pracownikow_zaden')) fbg.addControl('liczba_pracownikow_zaden', new FormControl('', Validators.required));
		if (!fbg.contains('siedziba')) fbg.addControl('siedziba', new FormControl('', Validators.required));
		if (!fbg.contains('oddzial')) fbg.addControl('oddzial', new FormControl('', Validators.required));
		if (!fbg.contains('grunt1')) fbg.addControl('grunt1', new FormControl('', Validators.required));
		if (!fbg.contains('grunt2')) fbg.addControl('grunt2', new FormControl('', Validators.required));
		if (!fbg.contains('grunt3')) fbg.addControl('grunt3', new FormControl('', Validators.required));
		if (!fbg.contains('nazwa_poprzedniej_firmy')) fbg.addControl('nazwa_poprzedniej_firmy', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_28_pozwolenie_tak')) fbg.addControl('pkt_28_pozwolenie_tak', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_28_pozwolenie_nie')) fbg.addControl('pkt_28_pozwolenie_nie', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_29_rzemieslnik_tak')) fbg.addControl('pkt_29_rzemieslnik_tak', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_29_rzemieslnik_nie')) fbg.addControl('pkt_29_rzemieslnik_nie', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_30_pobyt_tak')) fbg.addControl('pkt_30_pobyt_tak', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_30_pobyt_nie')) fbg.addControl('pkt_30_pobyt_nie', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_31_pobyt_tak')) fbg.addControl('pkt_31_pobyt_tak', new FormControl('', Validators.required));
		if (!fbg.contains('pkt_31_pobyt_nie')) fbg.addControl('pkt_31_pobyt_nie', new FormControl('', Validators.required));
		return fbg;
	}

	addFrageborgen2_13(fbg: FormGroup) {

		if (!fbg.contains('zoo_3_nazwa_firmy')) fbg.addControl('zoo_3_nazwa_firmy', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_4_ul_spolki')) fbg.addControl('zoo_4_ul_spolki', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_5_nr_domu_spolki')) fbg.addControl('zoo_5_nr_domu_spolki', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_5_nr_lokal_spolki')) fbg.addControl('zoo_5_nr_lokal_spolki', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_6_kod_pocztowy')) fbg.addControl('zoo_6_kod_pocztowy', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_6_miejscowosc_spolki')) fbg.addControl('zoo_6_miejscowosc_spolki', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_12_telefon')) fbg.addControl('zoo_12_telefon', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_13_mail')) fbg.addControl('zoo_13_mail', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_14_www_w_pl')) fbg.addControl('zoo_14_www_w_pl', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_19_ulica_w_pl')) fbg.addControl('zoo_19_ulica_w_pl', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_20_nr_domu_w_pl')) fbg.addControl('zoo_20_nr_domu_w_pl', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_21_kod_pocztowy_w_pl')) fbg.addControl('zoo_21_kod_pocztowy_w_pl', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_21_miejscowosc_w_pl')) fbg.addControl('zoo_21_miejscowosc_w_pl', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_61_iban_konto')) fbg.addControl('zoo_61_iban_konto', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_62_swift')) fbg.addControl('zoo_62_swift', new FormControl('', Validators.required));
		if (!fbg.contains('zoo_77_od_kiedy_firma')) fbg.addControl('zoo_77_od_kiedy_firma', new FormControl(''));

		fbg.addControl('udzialowcy', new FormArray([]));

		return fbg;
	}

	addRodo2_14(fbg: FormGroup) {
		if (!fbg.contains('administrator_danych_osobowych')) fbg.addControl('administrator_danych_osobowych', new FormControl(''));
		if (!fbg.contains('rodo_akceptacja_regulaminu')) fbg.addControl('rodo_akceptacja_regulaminu', new FormControl('', Validators.required));
		if (!fbg.contains('rodo_przetwarzanie_danych')) fbg.addControl('rodo_przetwarzanie_danych', new FormControl('', Validators.required));
		if (!fbg.contains('rodo_dane_marketingowe')) fbg.addControl('rodo_dane_marketingowe', new FormControl(''));
		return fbg;
	}

	addVollmacht3_15(fbg: FormGroup) {
		if (!fbg.contains('nip')) fbg.addControl('nip', new FormControl('', Validators.required));
		if (!fbg.contains('osoba_nie_fizyczna')) fbg.addControl('osoba_nie_fizyczna', new FormControl(''));
		if (!fbg.contains('osoba_fizyczna')) fbg.addControl('osoba_fizyczna', new FormControl(''));
		if (!fbg.contains('miejscowosc_urzedu_skarbowego')) fbg.addControl('miejscowosc_urzedu_skarbowego', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('land')) fbg.addControl('land', new FormControl('', Validators.required));
		if (!fbg.contains('wojewodztwo')) fbg.addControl('wojewodztwo', new FormControl(''));
		if (!fbg.contains('powiat ')) fbg.addControl('powiat', new FormControl(''));
		if (!fbg.contains('gmina ')) fbg.addControl('gmina', new FormControl(''));
		if (!fbg.contains('pos_7_strabe  ')) fbg.addControl('pos_7_strabe', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('poczta')) fbg.addControl('poczta', new FormControl(''));
		if (!fbg.contains('podpis')) fbg.addControl('podpis', new FormControl(''));
		return fbg;
	}

	addAngrag1_16(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe  ', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('nip')) fbg.addControl('nip', new FormControl('', Validators.required));
		return fbg;
	}

	addAngrag2_17(fbg: FormGroup) {
		if (!fbg.contains('pos_4_name')) fbg.addControl('pos_4_name', new FormControl('', Validators.required));
		if (!fbg.contains('pos_4_vorname')) fbg.addControl('pos_4_vorname', new FormControl('', Validators.required));
		if (!fbg.contains('pos_7_strabe')) fbg.addControl('pos_7_strabe  ', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_postleitzahl')) fbg.addControl('pos_9_postleitzahl', new FormControl('', Validators.required));
		if (!fbg.contains('pos_9_wohnort')) fbg.addControl('pos_9_wohnort', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummer')) fbg.addControl('pos_8_hausnummer', new FormControl('', Validators.required));
		if (!fbg.contains('pos_8_hausnummerzusats')) fbg.addControl('pos_8_hausnummerzusats', new FormControl(''));
		if (!fbg.contains('nip')) fbg.addControl('nip', new FormControl('', Validators.required));
		return fbg;
	}

	tax(fbg: FormGroup) {

		if (!fbg.contains('land')) fbg.addControl('land', new FormControl('', Validators.required));
		if (!fbg.contains('ktory_rok')) fbg.addControl('ktory_rok', new FormControl('', Validators.required));
		if (!fbg.contains('czy_posiadasz_numer_podatkowy')) fbg.addControl('czy_posiadasz_numer_podatkowy', new FormControl(''));
		if (!fbg.contains('numer_podatkowy')) fbg.addControl('numer_podatkowy', new FormControl(''));
		if (!fbg.contains('numer_id')) fbg.addControl('numer_id', new FormControl(''));
		if (!fbg.contains('czy_gewerbe_de')) fbg.addControl('czy_gewerbe_de', new FormControl(''));
		if (!fbg.contains('urzad_skarbowy_de')) fbg.addControl('urzad_skarbowy_de', new FormControl('', Validators.required));
		if (!fbg.contains('co_bedzie_rozliczone')) fbg.addControl('co_bedzie_rozliczone', new FormControl('', Validators.required));
		// if (!fbg.contains('zameldowanie_w_us')) fbg.addControl('zameldowanie_w_us', new FormControl('', Validators.required));
		if (!fbg.contains('bylo_juz_rozliczenie')) fbg.addControl('bylo_juz_rozliczenie', new FormControl('', Validators.required));
		if (!fbg.contains('otrzymalem_rozlicznie')) fbg.addControl('otrzymalem_rozlicznie', new FormControl('', Validators.required));
		if (!fbg.contains('pobierasz_zasilek_rodzinny')) fbg.addControl('pobierasz_zasilek_rodzinny', new FormControl('', Validators.required));
		if (!fbg.contains('posiadasz_juz_zaswiadczenie_eog_ewr')) fbg.addControl('posiadasz_juz_zaswiadczenie_eog_ewr', new FormControl('', Validators.required));
		if (!fbg.contains('eog_ewr_zalatwie_same_zlecam')) fbg.addControl('eog_ewr_zalatwie_same_zlecam', new FormControl('', Validators.required));
		if (!fbg.contains('dzieci')) fbg.addControl('dzieci', new FormArray([]));
		if (!fbg.contains('masz_niesprawnosci')) fbg.addControl('masz_niesprawnosci', new FormControl(''));
		if (!fbg.contains('prowadzisz_gospodarstwo_domowe_w_pl')) fbg.addControl('prowadzisz_gospodarstwo_domowe_w_pl', new FormControl('', Validators.required));
		if (!fbg.contains('zawod_wykonywany')) fbg.addControl('zawod_wykonywany', new FormControl('', Validators.required));
		if (!fbg.contains('prowadzisz_gospodarstwo_domowe_w_de')) fbg.addControl('prowadzisz_gospodarstwo_domowe_w_de', new FormControl('', Validators.required));
		if (!fbg.contains('gospodarstwo_domowe_ulica_de')) fbg.addControl('gospodarstwo_domowe_ulica_de', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_ulica_numer_de')) fbg.addControl('gospodarstwo_domowe_ulica_numer_de', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_kod_de')) fbg.addControl('gospodarstwo_domowe_kod_de', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_miejscowosc_de')) fbg.addControl('gospodarstwo_domowe_miejscowosc_de', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_ulica_pl')) fbg.addControl('gospodarstwo_domowe_ulica_pl', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_ulica_numer_pl')) fbg.addControl('gospodarstwo_domowe_ulica_numer_pl', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_kod_pl')) fbg.addControl('gospodarstwo_domowe_kod_pl', new FormControl(''));
		if (!fbg.contains('gospodarstwo_domowe_miejscowosc_pl')) fbg.addControl('gospodarstwo_domowe_miejscowosc_pl', new FormControl(''));
		if (!fbg.contains('adres_zamieszkania_w_niemczech')) fbg.addControl('adres_zamieszkania_w_niemczech', new FormArray([]));
		if (!fbg.contains('ile_dni_pracujesz_w_tygodniu')) fbg.addControl('ile_dni_pracujesz_w_tygodniu', new FormControl(''));
		if (!fbg.contains('ile_masz_urlopu_w_roku')) fbg.addControl('ile_masz_urlopu_w_roku', new FormControl(''));
		if (!fbg.contains('ile_km_masz_do_pracy')) fbg.addControl('ile_km_masz_do_pracy', new FormControl(''));
		if (!fbg.contains('pracodawcy')) fbg.addControl('pracodawcy', new FormArray([]));
		if (!fbg.contains('telefon')) fbg.addControl('telefon', new FormControl('', Validators.required));
		if (!fbg.contains('email')) fbg.addControl('email', new FormControl('', Validators.required));
		if (!fbg.contains('stan_cywilny')) fbg.addControl('stan_cywilny', new FormControl('', Validators.required));
		if (!fbg.contains('religia')) fbg.addControl('religia', new FormControl(''));
		if (!fbg.contains('religia_malzonka')) fbg.addControl('religia_malzonka', new FormControl(''));
		if (!fbg.contains('data_slubu')) fbg.addControl('data_slubu', new FormControl(''));
		if (!fbg.contains('data_rozwodu')) fbg.addControl('data_rozwodu', new FormControl(''));
		if (!fbg.contains('data_owdowienia')) fbg.addControl('data_owdowienia', new FormControl(''));
		if (!fbg.contains('rozliczenie_z_malzonkiem')) fbg.addControl('rozliczenie_z_malzonkiem', new FormControl('', Validators.required));
		if (!fbg.contains('imie_malzonka')) fbg.addControl('imie_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('nazwisko_malzonka')) fbg.addControl('nazwisko_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('nazwisko_panienskie_malzonka')) fbg.addControl('nazwisko_panienskie_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('koszty_alimentow')) fbg.addControl('koszty_alimentow', new FormControl(''));
		if (!fbg.contains('praca_w_innym_kraju_niz_de')) fbg.addControl('praca_w_innym_kraju_niz_de', new FormControl('', Validators.required));
		if (!fbg.contains('praca_w_innym_kraju_niz_de_opis')) fbg.addControl('praca_w_innym_kraju_niz_de_opis', new FormControl(''));
		if (!fbg.contains('czy_byly_darowizny')) fbg.addControl('czy_byly_darowizny', new FormControl('', Validators.required));
		if (!fbg.contains('czy_byly_darowizny_koszt_roczny')) fbg.addControl('czy_byly_darowizny_koszt_roczny', new FormControl(''));
		if (!fbg.contains('czy_izba_zemieslnicza')) fbg.addControl('czy_izba_zemieslnicza', new FormControl('', Validators.required));
		if (!fbg.contains('czy_izba_zemieslnicza_koszt_rocny')) fbg.addControl('czy_izba_zemieslnicza_koszt_rocny', new FormControl(''));
		if (!fbg.contains('przeprowadzka')) fbg.addControl('przeprowadzka', new FormControl(''));
		if (!fbg.contains('zakup_odziezy')) fbg.addControl('zakup_odziezy', new FormControl(''));
		if (!fbg.contains('szukanie_pracy_honorarium')) fbg.addControl('szukanie_pracy_honorarium', new FormControl(''));
		if (!fbg.contains('koszty_leczenia')) fbg.addControl('koszty_leczenia', new FormControl(''));
		if (!fbg.contains('doradztwo_podatkowe')) fbg.addControl('doradztwo_podatkowe', new FormControl(''));
		if (!fbg.contains('koszty_biura_gewerbe')) fbg.addControl('koszty_biura_gewerbe', new FormControl(''));
		if (!fbg.contains('zwrot_kosztow_pracy_de')) fbg.addControl('zwrot_kosztow_pracy_de', new FormControl(''));
		if (!fbg.contains('zwrot_kosztow_pracy_de_za_co')) fbg.addControl('zwrot_kosztow_pracy_de_za_co', new FormControl(''));
		if (!fbg.contains('koszty_ubezpieczenia_zdrowotnego')) fbg.addControl('koszty_ubezpieczenia_zdrowotnego', new FormControl(''));

		if (!fbg.contains('adres_zameldowania_w_polsce')) fbg.addControl('adres_zameldowania_w_polsce', new FormControl(''));
		if (!fbg.contains('adres_zameldowania_w_niemczech')) fbg.addControl('adres_zameldowania_w_niemczech', new FormControl(''));
		if (!fbg.contains('czy_podatek_koscielny_de')) fbg.addControl('czy_podatek_koscielny_de', new FormControl(''));
		if (!fbg.contains('data_urodzenia_malzonka')) fbg.addControl('data_urodzenia_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('adres_malzonka')) fbg.addControl('adres_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('czy_malzonek_przebywa_i_pracuje_w_de')) fbg.addControl('czy_malzonek_przebywa_i_pracuje_w_de', new FormControl('', Validators.required));
		if (!fbg.contains('dochod_malzonka')) fbg.addControl('dochod_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('zaswoadczenie_ue_eog_malzonka')) fbg.addControl('zaswoadczenie_ue_eog_malzonka', new FormControl('', Validators.required));
		if (!fbg.contains('rozdzielnosc_majatkowa')) fbg.addControl('rozdzielnosc_majatkowa', new FormControl('', Validators.required));
		if (!fbg.contains('numer_konta')) fbg.addControl('numer_konta', new FormControl('', Validators.required));
		if (!fbg.contains('swift')) fbg.addControl('swift', new FormControl('', Validators.required));
		if (!fbg.contains('bank')) fbg.addControl('bank', new FormControl('', Validators.required));
		if (!fbg.contains('miejscowosc_banku')) fbg.addControl('miejscowosc_banku', new FormControl('', Validators.required));
		if (!fbg.contains('wlasciciel_konta')) fbg.addControl('wlasciciel_konta', new FormControl('', Validators.required));
		if (!fbg.contains('dodatkowe_koszty_z_praca_w_de')) fbg.addControl('dodatkowe_koszty_z_praca_w_de', new FormControl(''));
		if (!fbg.contains('rachunki')) fbg.addControl('rachunki', new FormArray([]));
		if (!fbg.contains('zakup_sprzetu')) fbg.addControl('zakup_sprzetu', new FormControl(''));
		if (!fbg.contains('doksztalcanie')) fbg.addControl('doksztalcanie', new FormControl(''));
		if (!fbg.contains('czy_chcesz_wsparcia')) fbg.addControl('czy_chcesz_wsparcia', new FormControl('', Validators.required));

		return fbg;
	}

}
