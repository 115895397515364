<ng-container *ngIf="contentArt">

    <div class="flex flex-col justify-center bg-gray-100 py-10">
        <div class="mx-auto w-full" style="max-width: 1200px; min-height: 80vh;">


            <h1>{{ contentArt.data[0].attributes.tytul }}</h1>

            <div [innerHTML]="contentArt.data[0].attributes.tresc">

            </div>


        </div>
    </div>

</ng-container>

<app-stopka></app-stopka>