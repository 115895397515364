import { PolicyComponent } from './policy/policy.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { ProfileComponent } from './profile/profile.component';
import { CheckerComponent } from './checker/checker.component';
import { MailboxComponent } from './mailbox/mailbox.component';
import { DocumentsComponent } from './documents/documents.component';
import { FilesComponent } from './files/files.component';
import { CognitoGuard } from './cognito.guard';
import { ConsoleComponent } from './console/console.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatedocComponent } from './createdoc/createdoc.component';
import { RegulaminComponent } from './regulamin/regulamin.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, },
	{
		path: 'console', component: ConsoleComponent, canActivate: [CognitoGuard],		
		children: [
			{	path: '', component: DocumentsComponent, canActivate: [CognitoGuard] },
			{	path: 'profile', component: ProfileComponent, canActivate: [CognitoGuard] },
			{	path: 'files', component: FilesComponent, canActivate: [CognitoGuard] },
			{	path: 'documents', component: DocumentsComponent, canActivate: [CognitoGuard] },
			{	path: 'mailbox', component: MailboxComponent, canActivate: [CognitoGuard] },
			{	path: 'checker', component: CheckerComponent, canActivate: [CognitoGuard] },
		],
	},
	{	path: 'aboutus', component: AboutusComponent },
	{	path: 'policy', component: PolicyComponent },
	{	path: 'regulamin', component: RegulaminComponent },
	{ path: 'blog/:slug', component: BlogComponent},
	{ path: 'blog', component: BlogComponent},
	{ path: 'contact', component: ContactComponent},

	{ path: 'createdoc', component: CreatedocComponent, canActivate: [CognitoGuard]},				//TODO: tutaj można zastosować nową funkcję z NG15, routing do komponentu w zależnosci od logiki
	{ path: 'editdoc', component: CreatedocComponent, canActivate: [CognitoGuard]},				//TODO: tutaj można zastosować nową funkcję z NG15, routing do komponentu w zależnosci od logiki
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
