import { createAction, props } from '@ngrx/store';
import { CbbState } from '../cbbmodels/cbbstate';
import { UserConfig } from '../cbbmodels/user-config';
import { CbbActionType } from './cbbactiontypes';
import { DokToSig } from '../cbbmodels/doktosig';
import { Report } from '../cbbmodels/report';
import { File } from '../cbbmodels/file';



// [CONSOLE, LOGIN] getCbbState action
export const getCbbStateAction = createAction(
  CbbActionType.getCbbState,
  props<{cognitoUsername: string}>()
);

// [EFFECTS] getCbbState action SUCCESS
export const getCbbStateActionSuccess = createAction(
  CbbActionType.getCbbStateSuccess,
  props<{cbbState: CbbState}>()
);

// getCbbFilesState = '[SIGN] getCbbFilesState action',
export const getCbbFilesStateAction = createAction(
  CbbActionType.getCbbFilesState,
  props<{cognitoUsername: string}>()
);

// [EFFECTS] getCbbFilesState SUCCESS action
export const getCbbFilesStateActionSuccess = createAction(
  CbbActionType.getCbbFilesStateSuccess,
  props<{files: File[]}>()
);

// [PROFILE] setUserConfig action
export const setUserConfigAction = createAction(
  CbbActionType.setUserConfig,
  props<{userConfig: UserConfig}>()
);

// setReport = '[UMOWA] setReport action',
export const setReport = createAction(
  CbbActionType.setReport,
  props<{rep: Report}>()
);

// [AUTH, LOGOUT] clearCbbState action
export const clearCbbState = createAction(
  CbbActionType.clearCbbState
);

// prepareDokFromReportAndGeneratePDF = '[SIGN] prepare Dok From Report And GeneratePDF action',
export const prepareDokFromReportAndGeneratePDFAction = createAction(
	CbbActionType.prepareDokFromReportAndGeneratePDF,
	props<{dok: DokToSig, file: File, rep: Report, lang: string}>()
)

// signDokFromReport = '[SIGN] signing PDF action',
export const signDokFromReport = createAction(
	CbbActionType.signDokFromReport,
	props<{dok: DokToSig, rep: Report, signFile: string, signedFile: File, lang: string}>()
)

//sendReport = '[SIGN] wysyła podpisane zgłoszenie action',
export const sendReport = createAction(
	CbbActionType.sendReport,
	props<{rep: Report}>()
)

// [SIGN] sendEmailToClient action
export const sendEmailToClient = createAction(
	CbbActionType.sendEmailToClient,
	props<{rep: Report}>()
)
// '[SIGN] sendEmailToClient SUCCESS action'
export const sendEmailToClientSuccess = createAction(
	CbbActionType.sendEmailToClientSuccess,
	props<{rep: Report}>()
)

// signDokFromReportSuccess = '[SIGN] signing PDF SUCCESS action',
export const signDokFromReportSuccess = createAction(
	CbbActionType.signDokFromReportSuccess,
	// props<{dok: DokToSig, rep: Report, signFileName: string}>()
	props<{files: File[]}>()
)

// reportToEdt = '[DOCUMENTS, CHECKER] ustaw raport do edycji action',
export const reportToEdit = createAction(
	CbbActionType.reportToEdt,
	props<{rep: Report}>()
)

// [CHECKER] nowy raport do edycji action
export const newReportToEdt = createAction(
	CbbActionType.newReportToEdt,
	props<{rep: Report}>()
)

// cancellAllFileByReport = '[UMOWA] ustawia statusy na plikach po edycji formularza'
export const cancellAllFileByReport = createAction(
	CbbActionType.cancellAllFileByReport,
	props<{rep: Report}>()
)

// autopodpis = '[SIGN] autopodpis action',
export const autopodpis = createAction(
	CbbActionType.autopodpis,
	props<{dok: DokToSig, rep: Report}>()
)