import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { BehaviorSubject, catchError, from, map, Observable, of, Subject, tap, toArray } from 'rxjs';
import { AuthCognitoConfig } from './auth-cognito-config';
import { UserCard } from './DTOs/userCard';

@Injectable({
	providedIn: 'root'
})
export class AuthCognitoService {

	apiUrl: string;
	tokenJWT: string | null;
	tokenData: any;
	userInfo: any[] = []; 		//TODO: typować
	userEmail: string = '';
	phoneNumber: string = '';
	countryCode: string = '';
	cognitoUsername: string = '';
	pamietamyhaslo: string = '';

	public changeViewSub = new BehaviorSubject('login');
	public onLoged$ = new BehaviorSubject(false);
	public message$ = new BehaviorSubject('');
	public userInfo$ = new BehaviorSubject<any[]>([]);
	public _isLoged: boolean;

	public rememberPhone: string;
	public rememberCountryCode: string;
	public rememberEmail: string;
	public sposobLogowania: string;

	@Output() onTogglePanel = new EventEmitter<string>();
	@Output() onChangeAuth = new EventEmitter<boolean>();
	@Output() onChangeMethodSignIn = new EventEmitter<string>();

	constructor(
		@Inject('config') private config: AuthCognitoConfig,
		private http: HttpClient
	) {
		this.apiUrl = config.ApiUrl;
		this.tokenJWT = '';
	}

	ChangeMethodSignIn(method: string) {
		let sl = localStorage.getItem('spsblg');
		if(method == 'start') {
			if(!sl || sl == 'null') method = 'email';
			else
				method = sl;
		}
		localStorage.setItem('spsblg', method);
		this.sposobLogowania = method;
		this.onChangeMethodSignIn.next(method);
	}

	togglePanel(view: string) {
		this.onTogglePanel.next(view);
	}

	changeView(view: string) {
		this.changeViewSub.next(view);
	}

	getApiUrl() {
		return this.apiUrl;
	}

	saveTokenStorage(accessToken: string, cognitoUsername: string) {
		this.tokenJWT = accessToken;
		if (this.tokenJWT)
			localStorage.setItem('cognitoToken', accessToken);
		if (cognitoUsername)
			localStorage.setItem('cognitoUsername', cognitoUsername);
	}

	isLogedLocal() {
		this.tokenJWT = localStorage.getItem('cognitoToken');
		this._isLoged = (this.tokenJWT && this.tokenJWT.length > 10);
		return (this.tokenJWT && this.tokenJWT.length > 10);
	}

	isLoged() {
		return this._isLoged
	}

	setMessage(message: string) {
		this.message$.next(message);
		console.log('message wysłane:', message);
		setTimeout(() => this.message$.next(''), 15000);
	}

	login(payload: any) {
		return this.http.post(`${this.apiUrl}/auth/signin`, payload)
			.pipe(
				catchError(err => {
					this.setMessage(err?.error.message);
					this.changeView('login');
					this._isLoged = false;
					throw new Error('login problem');
				}),
				tap((res: any) => {
					if (res && res?.accessToken) {
						this.saveTokenStorage(res.accessToken, res.cognitoUsername);
						this.changeAuthenticated(true);
						this.userEmail = res.email ?? '';
						this.phoneNumber = res.phoneNumber ?? '';
						this.countryCode = res.countryCode ?? '';
						this.cognitoUsername = res.cognitoUsername ?? '';
						this._isLoged = true;
					}
				}),
			)
	}

	register(userCard: UserCard): void {
		this.pamietamyhaslo = userCard.password;
		this.http.post(`${this.apiUrl}/auth/signup`, userCard)
			.pipe(
				catchError(err => {
					console.log('błąd w register', err);
					let ms: string = err?.error.message;
					if (!ms) ms = err?.message;
					if (ms == 'Forbidden') ms = 'Incoret password';
					if (ms == 'LimitExceededException') ms = 'Limit Exceeded Exception';
					this.setMessage(ms);
					this.changeView('register');
					throw new Error('register problem');
				})
			)
			.subscribe((x: any) => {
				this.setMessage('verify your email to confirm code');
				this.changeView('confirm');
			})
	}

	confirm(payload: any) {
		let username: string = '';
		if(payload?.email?.length>0) username = payload.email;
		if(!payload.countryCode) payload.countryCode = '';
		if(payload?.phoneNumber?.length>0) username = payload.countryCode+payload.phoneNumber;

		return this.http.post(`${this.apiUrl}/auth/confirmsignup`, { username: username,countryCode: payload.countryCode, phoneNumber: payload.phoneNumber, confirmationCode: payload.confirmcode })
			.pipe(
				catchError(err => {
					console.log('błąd w confirm', err);
					let ms: string = err?.error.message;
					if (!ms) ms = err?.message;
					if (ms == 'Forbidden') ms = 'Incoret password';
					if (ms == 'LimitExceededException') ms = 'Limit Exceeded Exception';
					this.setMessage(ms);
					// this.changeView('confirm');
					throw new Error('confirm problem');
				}),
				tap((res: any) => {
					this.changeView('login');
				}),
			)
	}

	reSendConfirmCode(payload:any) {
		return this.http.post(`${this.apiUrl}/auth/resendconfirmcode`, payload)
			.pipe(
				catchError(err => {
					console.log('błąd w resend confirm', err);
					let ms: string = err?.error.message;
					if (!ms) ms = err?.message;
					if (ms == 'Forbidden') ms = 'Incoret password';
					if (ms == 'LimitExceededException') ms = 'Limit Exceeded Exception';
					this.setMessage(ms);
					// this.changeView('confirm');
					throw new Error('reSendConfirmCode problem');
				}),
				tap((res: any) => {
				}),
			)
	}

	getName() {
		let name = 'todo Pobrać name z tokenu';
		if (name) return name;
		return 'no name';
	}

	changeAuthenticated(isAuth: boolean) {
		this.onChangeAuth.next(isAuth);
	}

	getUserEmail() {
		return this.userEmail;
	}

	isAuthenticatedUser() {
		this.tokenJWT = localStorage.getItem('cognitoToken')

		let hdr = {
			'Content-Type': 'application/json',
			'accept': '*/*',
			'Authorization': `Bearer ${this.tokenJWT}`
		};

		return this.http.get(`${this.apiUrl}/auth/isauthenticateduser`, { headers: hdr })
			.pipe(
				catchError(err => {
					console.log('błąd w getUserInfo', err);
					//localStorage.removeItem('cognitoToken');
					this.logout();
					return of(`${err?.error?.message || 'błąd przy pobieraniu informacji isAuthenticatedUser'}`)
				}),
				map((x: { isAuthenticatedUser: boolean }) => x.isAuthenticatedUser),
				tap((res: boolean) => {
					this.changeAuthenticated(res);
					if (res == false) {
						//localStorage.removeItem('cognitoToken');
						this.logout();
					}
				}),
			)
	}

	getUserInfo() {
		// if(this.userInfo.length > 0) return;

		this.tokenJWT = localStorage.getItem('cognitoToken')

		if(!this.tokenJWT) {
			console.log('brak tokena');
			this.setUserInfo([]);
			return;
		};

		let hdr = {
			'Content-Type': 'application/json',
			'accept': '*/*',
			'Authorization': `Bearer ${this.tokenJWT}`
		};

		this.http.get(`${this.apiUrl}/auth/getinfo`, { headers: hdr })
			.subscribe((res: any | string) => {
				if (res) {
					this.setUserInfo(res);
					this.userInfo = res.userInfo.UserAttributes;
				}
			},
				(err: any) => {
					console.log('błąd w getUserInfo', err);
					this.logout();
				}
			)
	}

	changePassword(oldPassword: string, newPassword: string) {
		let hdr = {
			'Content-Type': 'application/json',
			'accept': '*/*',
			'Authorization': `Bearer ${this.tokenJWT}`
		};

		this.http.post(`${this.apiUrl}/auth/changepassword`, { oldPassword: oldPassword, newPassword: newPassword }, { headers: hdr })
			.pipe(
				catchError(err => {
					let ms: string = err?.error.message;
					if (ms == 'Forbidden') ms = 'Incoret password';
					if (ms == 'LimitExceededException') ms = 'Limit Exceeded Exception';
					console.log('błąd w changePassword', err);
					this.setMessage(ms);
					this.changeView('changePassword');
					throw new Error('changePassword problem');
				}),
			)
			.subscribe((x: any) => {
				this.setMessage('Success');
				this.changeView('profile');
			})
	}

	forgotPassword1(payload:any) {
		this.http.post(`${this.apiUrl}/auth/forgotpassword1`, payload)
			.pipe(
				catchError(err => {
					let ms: string = err?.error.message;
					if (ms == 'Forbidden') ms = 'Incoret password';
					if (ms == 'LimitExceededException') ms = 'Limit Exceeded Exception';
					console.log('błąd w forgotPassword1', err);
					this.setMessage(ms);
					this.changeView('forgot1');
					throw new Error('forgotPassword1 problem');
				}),
				tap((res: any) => {
				}),
			)
			.subscribe((x: any) => {
				console.log('forgotPassword1 result', x);
				this.setMessage('see your email');
				this.changeView('forgot2');
			})
	}

	forgotPassword2(payload:any) {
		this.http.post(`${this.apiUrl}/auth/forgotpassword2`, payload)
			.pipe(
				catchError(err => {
					let ms: string = err?.error.message;
					if (ms == 'LimitExceededException') ms = 'Limit Exceeded Exception';
					console.log('błąd w forgotPassword2', err);
					this.setMessage(ms);
					this.changeView('forgot2');
					throw new Error('forgotPassword2 problem');
				}),
				tap((res: any) => {
				}),
			)
			.subscribe((x: any) => {
				this.setMessage('you changed your password');
				this.changeView('login');
			})
	}

	logout() {
		this.tokenJWT = localStorage.getItem('cognitoToken')
		if (this.tokenJWT) {
			let hdr = {
				'Content-Type': 'application/json',
				'accept': '*/*',
				'Authorization': `Bearer ${this.tokenJWT}`
			};

			this.http.post(`${this.apiUrl}/auth/signout`, {}, { headers: hdr })
				.pipe(
					catchError(err => {
						console.log('błąd w logOut:', err);
						return of(`${err?.error?.message || 'błąd przy wylogowaniu'}`)
					}),
				)
				.subscribe((res: any) => {
					localStorage.removeItem('cognitoToken');
					this.setUserInfo([]);
					this.setMessage('you are sign out');
					this.changeAuthenticated(false);
					this.changeView('login');
					this._isLoged = false;
				})
		}
	}

	setUserInfo(userInfo: any[]) {								//TODO: typować
		this.userInfo$.next(userInfo);
	}

}
