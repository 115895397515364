import { CbbState } from '../cbbmodels/cbbstate'
import { createReducer, on } from '@ngrx/store';
import * as cbbActions from '../cbboffice-rx-store/cbbactions'
import { cloneDeep } from 'lodash';
import { File } from '../cbbmodels/file'
import { Report } from '../cbbmodels/report';

const initialState: CbbState = new CbbState();

export const cbbReducer = createReducer(
	initialState,

	on(cbbActions.setUserConfigAction, (state, { userConfig }) => {
		let newState = cloneDeep(state);
		newState.userConfig = userConfig;
		return newState;
	}),

	on(cbbActions.getCbbStateAction, (state, { cognitoUsername }) => {
		let newState = cloneDeep(state);																		// <--- używaj clodeDeep(state)
		if(newState?.cognitoUsername?.length == 0) newState.cognitoUsername = cognitoUsername;
		return newState;
	}),

	on(cbbActions.getCbbStateActionSuccess, (state, { cbbState }) => {
		let stateFromDB = cloneDeep(cbbState);
		// if(stateFromDB.email.length == 0) stateFromDB.email = state.email;  //to nie powinno się zdarzyć

		// TU TRZEBA MĄDRZE MERGOWAĆ TO CO PRZYCHODZI Z BD ZE STANEM OBECNYM

		//może tak być, że edytujemy nowy report, a w bazie go jeszcze nie ma
		if(!state.reports) state.reports = [];
		let reports =  state.reports.concat(stateFromDB.reports.filter(ab => state.reports.findIndex(a => a.uid == ab.uid) < 0))			
		if(state.uidEditedReport) stateFromDB.uidEditedReport = state.uidEditedReport;
		stateFromDB.reports = reports;

		if(!state.files) state.files = [];
		let files =  state.files.concat(stateFromDB.files.filter(ab => state.files.findIndex(a => a.fileName == ab.fileName) < 0))			
		stateFromDB.files = files;

		return stateFromDB;
	}), 

	on(cbbActions.getCbbFilesStateActionSuccess, (state, { files }) => {
		let newState = cloneDeep(state);
		newState.files = files;
		return newState;
	}),

	on(cbbActions.signDokFromReportSuccess, (state, { files }) => {
		let newState = cloneDeep(state);
		newState.files = files;
		return newState;
	}),

	on(cbbActions.sendReport, (state, { rep }) => {
		let newState = cloneDeep(state);
		let idx = newState.reports.findIndex(x => x.uid == rep.uid);
		if(idx >= 0) {
			newState.reports[idx].status = 'signed';
		}
		return newState;
	}),

	on(cbbActions.clearCbbState, (state) => {
		return new CbbState();
	}),

	on(cbbActions.setReport, (state, { rep }) => {
		let newState = cloneDeep(state);
		let newReport:Report = cloneDeep(rep);
		let idx = newState.reports.findIndex((r:Report) => r.reportName == rep.reportName && r.position == rep.position);
		if(idx >= 0) newState.reports[idx] = newReport;
		else newState.reports.push(newReport);
		newState.uidEditedReport = newReport.uid;

		if(!newState.userConfig) newState.userConfig = { cognitoUsername: state.cognitoUsername, email: '' };

		// jeżeli w profilu ORAZ w danych cognito brakuje adresu email, a jest w raporcie, to go uzupełniamy w profilu
		if(
				(!newState.userConfig.email || newState.userConfig.email.length == 0)
				&&
				(!newState.email || newState.email.length == 0)
			) {
				let emailField = newReport.fields.find(f => f.fieldName == 'pkt_8_email' || f.fieldName == 'email' || f.fieldName == 'pos_23_mail');
				console.log('emailField',emailField);
				if(emailField && emailField.fieldValue.length > 5) newState.userConfig.email = emailField.fieldValue;
				console.log('>> newState.userConfig.email',newState.userConfig.email);
		}

		return newState;
	}),

	on(cbbActions.reportToEdit, (state, { rep }) => {
		let newState = cloneDeep(state);
		newState.uidEditedReport = rep.uid;
		return newState;
	}),

	on(cbbActions.newReportToEdt, (state, { rep }) => {
		let newState = cloneDeep(state);
		let newRep = cloneDeep(rep);
		let lpi: number = 0;
		if(newState.reports.length == 0) 	lpi = 0;

		// if(newState.reports[mod.modulName].length > 0) 	lpi = Math.max(...newState.reports[mod.modulName].map(x => x.position)) + 1;		// ustalam numer koleny modułu w obrębie raportu
		if(newState.reports.length > 0) lpi = newState.reports.length;													//TODO: zbadać, czy dobrze działa

		newRep.cognitoUsername = state.cognitoUsername;
		newRep.position = lpi;
		newState.uidEditedReport = newRep.uid;
		newState.reports.push(newRep)
		return newState;
	}),

	on(cbbActions.cancellAllFileByReport, (state, { rep }) => {
		let newState = cloneDeep(state);
		let files: File[] = cloneDeep(newState.files);
		files.forEach((f:File) => {
			if(f.uidReport == rep.uid) {
				f.status = 'cancelled';
			}
		})
		newState.files = files;
		return newState;
	}),

);

