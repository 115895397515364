<p-card>
<app-info slug="kwestionariusz"></app-info>
</p-card>


<ng-container *ngIf="form">
	<form class="w-full my-6" [formGroup]="form" (ngSubmit)="send()">
		<div class="formwrapper mt-6">

			<div class="formwrapperrow33x"></div>

			<div class="formwrapperrow33x">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>
			<div class="formwrapperrow33x">
				<!-- <button (click)="turn('on')" *ngIf="disabled" pButton label="Edytuj" icon="pi pi-user-edit"></button> -->
				<button (click)="reset()" *ngIf="!disabled" pButton [label]="Anuluj" icon="pi pi-undo"></button>
			</div>

			<!-- <div class="formwrappermessagex">
				<h1 translate>Zgoda RODO</h1>
			</div>
			<div class="mt-0">
				<div class="flex">
					<div class="flex-1">
						<div class="formwrapperrow justify-center">
							<label for="administrator_danych_osobowych" class="text-center" translate>Wyrażam zgodę</label>
							<p-checkbox formControlName="administrator_danych_osobowych" [binary]="true" inputId="administrator_danych_osobowych" [ngClass]="{'dirtyinput': form.controls.administrator_danych_osobowych.invalid}"></p-checkbox>
						</div>
					</div>
					<div class="flex-3 pl-4">
						<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w niniejszym kwestionariuszu osobowym dla potrzeb niezbędnych do realizacji usługi przez CBBOffice (zgodnie z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych</p>
						<p translate>osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)).</p>
					</div>
				</div>
			</div> -->

			<div class="formwrappermessage">
				<h1 translate>Podstawowe dane osobowe</h1>
			</div>

			<div class="formwrapperrow33">
				<label for="pos_4_name" translate>Nazwisko:</label>
				<input type="text" id="pos_4_name" pInputText formControlName="pos_4_name" [ngClass]="{'dirtyinput': form.controls.pos_4_name.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_4_vorname" translate>Imię:</label>
				<input type="text" id="pos_4_vorname" pInputText formControlName="pos_4_vorname" [ngClass]="{'dirtyinput': form.controls.pos_4_vorname.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_6_geburtsdatum" translate>Data urodzenia: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="pos_6_geburtsdatum" pInputText formControlName="pos_6_geburtsdatum" [ngClass]="{'dirtyinput': form.controls.pos_6_geburtsdatum.invalid}"></p-inputMask>
			</div>

			<div class="formwrapperrow50">
				<label for="pos_7_strabe" translate>Ulica zamieszkania:</label>
				<input type="text" id="pos_7_strabe" pInputText formControlName="pos_7_strabe" [ngClass]="{'dirtyinput': form.controls.pos_7_strabe.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_8_hausnummer" translate>Nr domu:</label>
				<input type="text" id="pos_8_hausnummer" pInputText formControlName="pos_8_hausnummer" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummer.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_8_hausnummerzusats" translate>Nr mieszkania:</label>
				<input type="text" id="pos_8_hausnummerzusats" pInputText formControlName="pos_8_hausnummerzusats" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummerzusats.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="wojewodztwo" translate>Województwo:</label>
				<p-dropdown [options]="wojewodztwa" formControlName="wojewodztwo" [ngClass]="{'dirtyinput': form.controls.wojewodztwo.invalid}">
					<option *ngFor="let wojewodztwo of wojewodztwa" [value]="wojewodztwo">{{wojewodztwo}}</option>
				</p-dropdown>
			</div>

			<div class="formwrapperrow33">
				<label for="powiat" translate>Powiat:</label>
				<input type="text" id="powiat" pInputText formControlName="powiat" [ngClass]="{'dirtyinput': form.controls.powiat.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="gmina" translate>Gmina:</label>
				<input type="text" id="gmina" pInputText formControlName="gmina" [ngClass]="{'dirtyinput': form.controls.gmina.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="poczta" translate>Miejscowość poczty:</label>
				<input type="text" id="poczta" pInputText formControlName="poczta" [ngClass]="{'dirtyinput': form.controls.poczta.invalid}">
			</div>


			<div class="formwrapperrow25">
				<label for="pos_9_postleitzahl" translate>Kod pocztowy PNA:</label>
				<input type="text" id="pos_9_postleitzahl" pInputText formControlName="pos_9_postleitzahl" [ngClass]="{'dirtyinput': form.controls.pos_9_postleitzahl.invalid}">
			</div>

			<div class="formwrapperrow25"></div>
			
			<div class="formwrapperrow25">
				<label for="pos_9_wohnort" translate>Miejscowosć:</label>
				<input type="text" id="pos_9_wohnort" pInputText formControlName="pos_9_wohnort" [ngClass]="{'dirtyinput': form.controls.pos_9_wohnort.invalid}">
			</div>
			
			<div class="formwrapperrow25">
				<label for="land" translate>Kraj:</label>
				<input type="text" id="land" pInputText formControlName="land" [ngClass]="{'dirtyinput': form.controls.land.invalid}">
			</div>

			<div class="formwrapperrow25"></div>


			<div class="formwrappermessage">
				<h1 translate>Dane urzędu skarbowego</h1>
				<p translate>Dane potrzebne do kontaktu z Urzędem Skarbowym</p>
			</div>

			<!-- miejscowosc_urzedu_skarbowego -->
			<div class="formwrapperrow33">
				<!-- <label for="miejscowosc_urzedu_skarbowego" translate>Miejscowość Urzędu Skarbowego:</label> -->
				<label for="miejscowosc_urzedu_skarbowego" translate>Lokalizacja Urzędu Skarbowego:</label>
				<input type="text" id="miejscowosc_urzedu_skarbowego" pInputText formControlName="miejscowosc_urzedu_skarbowego" [ngClass]="{'dirtyinput': form.controls.miejscowosc_urzedu_skarbowego.invalid}">
			</div>

			<!-- nip -->
			<div class="formwrapperrow33">
				<label for="nip" translate>NIP:</label>
				<input type="text" id="nip" pInputText formControlName="nip" [ngClass]="{'dirtyinput': form.controls.nip.invalid}">
			</div>

			<!-- osoba_fizyczna -->
			<div class="formwrapperrow50">
				<label for="osoba_fizyczna" translate>Osoba fizyczna</label>
				<p-checkbox (onChange)="kontra('osoba_fizyczna')" formControlName="osoba_fizyczna" [binary]="true" inputId="osoba_fizyczna" [ngClass]="{'dirtyinput': form.controls.osoba_fizyczna.invalid}"></p-checkbox>
			</div>

			<!-- osoba_nie_fizyczna -->
			<div class="formwrapperrow50">
				<label for="osoba_nie_fizyczna" translate>Osoba nie fizyczna</label>
				<p-checkbox (onChange)="kontra('osoba_nie_fizyczna')" formControlName="osoba_nie_fizyczna" [binary]="true" inputId="osoba_nie_fizyczna" [ngClass]="{'dirtyinput': form.controls.osoba_nie_fizyczna.invalid}"></p-checkbox>
			</div>



			<div class="formwrappermessagex">
				<h1 translate>Zgody RODO</h1>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_akceptacja_regulaminu" [binary]="true" inputId="rodo_akceptacja_regulaminu" [ngClass]="{'dirtyinput': form.controls.rodo_akceptacja_regulaminu.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate><span translate>Akceptuję</span> <a class="underline" [routerLink]="['/regulamin']" translate>regulamin</a><span translate>(POLE OBOWIĄZKOWE)</span></p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_przetwarzanie_danych" [binary]="true" inputId="rodo_przetwarzanie_danych" [ngClass]="{'dirtyinput': form.controls.rodo_przetwarzanie_danych.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie danych szczególnych kategoriizawartych w przesłanych dokumentach w celu dokonania wyceny oraz wykonania usługi. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposów uniemożliwiający nam przetwarzenia danych szczególnych kategorii (POLE OBOWIĄZKOWE)</p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_dane_marketingowe" [binary]="true" inputId="rodo_dane_marketingowe" [ngClass]="{'dirtyinput': form.controls.rodo_dane_marketingowe.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymania na ten adres email materiałów ofertowych dotyczących usług oferowanych przez CBB-Office GmbH</p>
				</div>
			</div>



			<div class="formwrapperrow">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>

		</div>
	</form>
</ng-container>

<!-- <pre>
	brakuje:
	land: ""
	podpis: ""
</pre> -->
