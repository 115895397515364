<!-- <p-button (click)="showBasicDialog()" icon="pi pi-external-link" label="Long Content"></p-button> -->

<p-dialog header="Auth Panel" [(visible)]="panelVisible" class="dialogClass w-1/4"  [modal]="true"   [contentStyle]="{'background-color': '#E5E7EB'}">

		<login *ngIf="actualView == 'login'"></login>
		<register *ngIf="actualView == 'register'"></register>
		<profile *ngIf="actualView == 'profile'"></profile>
		<confirm *ngIf="actualView == 'confirm'"></confirm>
		<logout *ngIf="actualView == 'logout'"></logout>
		<forgot-password-step1 *ngIf="actualView == 'forgot1'"></forgot-password-step1>
		<forgot-password-step2 *ngIf="actualView == 'forgot2'"></forgot-password-step2>
		<change-password *ngIf="actualView == 'changePassword'"></change-password>

		<div class="vz-message" style="width: 400px; color: brown; word-wrap: word-break; padding-top: 10px;">
			<p>{{ message }}</p>
		</div>

	<ng-template pTemplate="footer">
		<p-button icon="pi pi-angle-double-left" (click)="panelVisible=false" label="Cancel" styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog>
