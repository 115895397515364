<div class="flex flex-col w-full h-full bg-red-400">
	<ng-container *ngIf="progressValue > 1 && progressValue < 99">
		<div class="flex h-[100%-200px] mb-auto">
			<p-progressBar [value]="progressValue"></p-progressBar>
			<p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
			{{progressValue | json}}
		</div>
	</ng-container>
	<ng-container *ngIf="!(progressValue > 1 && progressValue < 99)">
		<div class="flex w-full bg-blue-200" style="flex-grow: 1;">
			<pdf-viewer [src]="osrc" [render-text]="true" [original-size]="false" [zoom]="0.9" (on-progress)="onProgress($event)" style="flex-grow: 1; min-height: 100px;"></pdf-viewer>
		</div>
		<div class="flex flex-wrap lg:flex-row min-h-[200px] w-full bg-white p-1" style="box-shadow: 5px 0px 20px black; z-index: 9999">
			<div class="w-full md:w-1/3 p-3 md:p-5">



				<div class="group relative w-full overflow-hidden rounded-[16px] bg-gray-300 p-[1px] transition-all duration-300 ease-in-out hover:bg-gradient-to-r hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500">
					<div class="relative rounded-[15px] bg-white p-6">
						<div class="flex flex-row space-y-4 mt-2">
							<img src="/assets/ai/penonyellow1.png" alt="" class="h-[100px]" />
							<p class="text-lg font-semibold text-slate-800" translate>Podpisz dokument</p> 
							<p class="font-md text-slate-500" translate>Przeczytaj uważnie dokument. Jeżeli wszystko jest OK, złóż podpis na żółtym panelu używając myszki, lub dotykowo.</p>
						</div>
					</div>
				</div>



			</div>
			<div class="w-full lg:w-1/3 mx-auto">
				<ng-container *ngIf="!podpistryb"><signature-pad #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad></ng-container>
				<ng-container *ngIf="podpistryb"><div class="canvas" #canvasContainer>
					<span translate>wprowadź swój podpis z klawiatury:</span>
					<input #podp class="podp" type="text" [(ngModel)]="podpis" (blur)="podpisklaw()">
					<div class="fanpodpis">{{podpis}}</div></div>
				</ng-container>
				<div class="w-full flex flex-col">
					<div class="w-full flex flex-row justify-between items-center pt-5"><span translate>Podpis ręczny (myszką lub piórem)</span><span><p-inputSwitch [(ngModel)]="podpistryb"></p-inputSwitch></span><span translate>Podpis z klawiatury</span></div>
					<div class="w-full text-xl mt-3 text-green-800 font-bold" translate>UWAGA: Możesz podpisać klawiaturą lub myszką lub piórem, do tego służy powyższy przełącznik.</div>
				</div>
			</div>
			<div class="w-full lg:w-1/4 mx-auto">
				<button (click)="signed()" pButton type="button" class="mybutton flex align-items-center justify-content-center h-4rem bg-red-500 border-round w-full m-4 text-center hover:bg-gray-200" translate>Podpisz</button>
				<button (click)="cancel()" pButton type="button" class="mybutton flex align-items-center justify-content-center h-3rem bg-gray-300 text-blue-500 border-round m-4 text-center hover:bg-gray-200" translate>Wróć</button>
			</div>
		</div>
	</ng-container>
</div>