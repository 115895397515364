import { IUserConfig } from './interfaces/iuser-config';

export class UserConfig implements IUserConfig {
	lang: string = 'PL';
	email: string = '';
	firstName: string = '';
	lastName: string = '';
	telNo: string = '';
	address: string = '';
	local: string = '';
	city: string = '';
	country: string = '';
	zipCode: string = '';
	possession: string = '';
	documentNo: string = '';
	birthDate: string = '';
}