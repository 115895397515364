export class Inbox {
	_id: any | string = '';
	cognitoUsername: string = '';
	email: string = '';
	title: string	= '';
	sender: string = '';
	receiver: string = '';
	content: string = '';
	date: Date = new Date();
	status: string = 'new';
	
	constructor(cognitoUsername: string = '', email: string = '', title: string = '', sender: string = '', receiver: string = '', content: string = '', date: Date = new Date(), status: string = '') {
		this.cognitoUsername = cognitoUsername;
		this.email = email;
		this.title = title;
		this.sender = sender;
		this.receiver = receiver;
		this.content = content;
		this.date = date;
		this.status = status;
	}

}