import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApperanceService } from './../apperance.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
	@Input() slug: string = '';

	strapiUrl: string = '';
	aSub;
	lSub;
	info: any = '';

	constructor(
		private apperanceService: ApperanceService
	) { }

	ngOnInit(): void {
		this.strapiUrl = environment.StrapiUrl;
		this.lSub = this.apperanceService.currentLanguage$
			.subscribe(x => {
				this.pobierzArtykul()
			})
	}

	pobierzArtykul() {
		if (!this.slug) return;
		this.aSub = this.apperanceService.getInfoBySlug(this.slug)
			.subscribe(info => {
				this.info = info;
			})
	}

	ngOnChanges(changes: SimpleChanges) {
		this.slug = changes.slug.currentValue;
		this.pobierzArtykul();
	}

	ngOnDestroy(): void {
		if (this.aSub) this.aSub.unsubscribe();
		if (this.lSub) this.lSub.unsubscribe();
	}



}
