import { CbbformService } from './../cbbform.service';
import { getUserProfile } from 'src/cbboffice-rx-store/selectors';
import { setUserConfigAction } from './../../cbboffice-rx-store/cbbactions';
import { Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CbbState } from '../../cbbmodels/cbbstate';
import { UserConfig } from '../../cbbmodels/user-config';
import { formatDate } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';

export const DATE_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ProfileComponent),
	multi: true
};

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	providers: [DATE_VALUE_ACCESSOR]
})
export class ProfileComponent implements OnInit, OnDestroy {

	userConfig: UserConfig = new UserConfig();
	SubUserConfig;
	disabled: boolean = false;
	Anuluj: string;
	Edytuj: string;
	profileForm: FormGroup;
	Zapisz: string = 'Zapisz'

	constructor(
		private fb: FormBuilder,
		private cbbform: CbbformService,
		private translate: TranslateService,
		private state: Store<CbbState>) { 
			this.Anuluj = this.translate.instant('Anuluj');
			this.Edytuj = this.translate.instant('Edytuj');
			this.Zapisz = this.translate.instant('Zapisz');
		}

	turn(swit: string) {
		switch (swit) {
			case 'on':
				this.profileForm.enable();
				this.disabled = false;
				return;
			case 'off':
				this.profileForm.disable();
				this.disabled = true;
				return;
		}
	}

	ngOnInit(): void {		
		this.profileForm = this.fb.group({
			lang: [''],
			email: [''],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			telNo: ['', Validators.required],
			address: ['', Validators.required],
			local: ['', Validators.required],
			city: ['', Validators.required],
			country: ['', Validators.required],
			zipCode: ['', Validators.required],
			possession: ['', Validators.required],
			documentNo: ['', Validators.required],
			birthDate: ['', Validators.required],
		})

		this.SubUserConfig = this.state.select(getUserProfile())
			.subscribe(x => {
			this.userConfig = x;			
			this.profileForm.setValue(this.userConfig);
			if(x.birthDate != '') this.profileForm.get('birthDate')?.setValue(this.cbbform.formatujDate( new Date(x.birthDate)));
			this.turn('off');
		})
	}

	ngOnDestroy(): void {
		this.SubUserConfig.unsubscribe();
	}

	reset() {
		this.profileForm.reset(this.userConfig);
	}

	send() {
		// let d:string = this.cbbform.formatujDate(this.profileForm.get('birthDate').value)
		// 	if(d.length == 10) this.userConfig = {...this.userConfig, birthDate: d}

		this.state.dispatch(setUserConfigAction({ userConfig: { ...this.userConfig, ...this.profileForm.getRawValue() } }));
		this.turn('off');
	}

}
