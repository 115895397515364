import { AuthCognitoService } from './auth-cognito.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { AuthPanelComponent } from './auth-panel/auth-panel.component';
import { AuthCognitoConfig } from './auth-cognito-config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegisterComponent } from './register/register.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordStep1Component } from './forgot-password-step1/forgot-password-step1.component';
import { ForgotPasswordStep2Component } from './forgot-password-step2/forgot-password-step2.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RightPanelComponent } from './right-panel/right-panel.component';
import { CognitoJwtInterceptor } from './cognito-jwt.interceptor';

import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropdownModule } from 'primeng/dropdown';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

@NgModule({
	providers: [
		AuthCognitoService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CognitoJwtInterceptor,
			multi: true
		}
	],
	declarations: [
		LoginComponent,
		LogoutComponent,
		ProfileComponent,
		ConfirmComponent,
		AuthPanelComponent,
		RegisterComponent,
		ChangePasswordComponent,
		ForgotPasswordStep1Component,
		ForgotPasswordStep2Component,
		RightPanelComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		MatSidenavModule,
		TabMenuModule,
		MenubarModule,
		OverlayPanelModule,
		TableModule,
    ButtonModule,
    ToastModule,
		DialogModule,
		DropdownModule,
		
		TranslateModule.forRoot({
			loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient]
			}
		})	
	],
	exports: [
		AuthPanelComponent,
		RightPanelComponent
	]	
})
export class AuthCognitoModule {
	static forRoot(config: AuthCognitoConfig): ModuleWithProviders<AuthCognitoModule> {
		// Konfiguracja przekazana z app.module.ts
		return {
			ngModule: AuthCognitoModule,
			providers: [AuthCognitoService, { provide: 'config', useValue: config }]
		};
	}
}
