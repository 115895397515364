export enum CbbActionType {
  TEST = '[TEST] test action',
  setUserConfig = '[PROFILE] setUserConfig action',
  getCbbState = '[CONSOLE, LOGIN] getCbbState action',
  getCbbFilesState = '[SIGN] getCbbFilesState action',
  getCbbFilesStateSuccess = '[EFFECTS] getCbbFilesState SUCCESS action',
  getCbbReport = '[ANY] getCbbReport action',
	getCbbStateSuccess = '[EFFECTS] getCbbState action SUCCESS',
	clearCbbState = '[AUTH, LOGOUT] clearCbbState action',
	setReport = '[UMOWA] setReport action',
	prepareDokFromReportAndGeneratePDF = '[SIGN] prepare Dok From Report And GeneratePDF action',
	signDokFromReport = '[SIGN] signing PDF action',
	signDokFromReportSuccess = '[SIGN] signing PDF SUCCESS action',
	reportToEdt = '[DOCUMENTS, CHECKER] ustaw raport do edycji action',
	newReportToEdt = '[CHECKER] nowy raport do edycji action',
	sendReport = '[SIGN] wysyła podpisane zgłoszenie action',
	sendEmailToClient = '[SIGN] sendEmailToClient action',
	sendEmailToClientSuccess = '[SIGN] sendEmailToClient SUCCESS action',
	cancellAllFileByReport = '[UMOWA] ustawia statusy na plikach po edycji formularza',
	autopodpis = '[SIGN] autopodpis action',
}
