import { getCbbStateAction } from './../../../cbboffice-rx-store/cbbactions';
import { AuthCognitoService } from './../auth-cognito.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { ApperanceService } from 'src/app/apperance.service';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['../scss/styles.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

	login: string = '';

	sposobLogowania: string = 'email';
	singinMethod$: any;
	zalogowany: boolean = false;
	countryCodes: any[] = [];

  phoneNumber: string = '';

	fg: FormGroup = new FormGroup({
		email: new FormControl(''),
		phoneNumber: new FormControl(''),
		countryCode: new FormControl('+48'),
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
	})

	constructor(
		private authService: AuthCognitoService,
		private router: Router,
		private state: Store<CbbState>,
		private apperanceService: ApperanceService,
	) {
		this.authService.ChangeMethodSignIn('start');
	}

	url: string = '';

	ngOnInit(): void {
		this.url = this.authService.getApiUrl();

		this.apperanceService.getNumeryKierunkowe()
			.subscribe((x:any[]) => {
				this.countryCodes = x;
			})

	  this.sposobLogowania = this.authService.sposobLogowania;
		this.singinMethod$ = this.authService.onChangeMethodSignIn
			.subscribe(x => {
				this.onSwitchLoginMethod(x);
			})

		if(this.authService.pamietamyhaslo.length > 0) {
			this.fg.get('password').setValue(this.authService.pamietamyhaslo);
		}
		if(this.authService.rememberPhone) {
			this.fg.get('phoneNumber').setValue(this.authService.rememberPhone);
		}
		if(this.authService.rememberCountryCode) {
			this.fg.get('countryCode').setValue(this.authService.rememberCountryCode);
		}
		if(this.authService.rememberEmail) {
			this.fg.get('email').setValue(this.authService.rememberEmail);
		}
		if(this.fg.get('countryCode').value.length <= 0) {
			this.fg.get('countryCode').setValue('+48');
		}

		// if password is not empty then login
		if(this.fg.get('password').value.length > 0 && ( this.fg.get('email').value.length > 0 || this.fg.get('phoneNumber').value.length > 0)) {
				this.logIn();
		}
	}

	onSwitchLoginMethod(method: string) {
		this.sposobLogowania = method;
		if(this.sposobLogowania === 'phoneNumber'){
			this.fg.get('email').disable();
			this.fg.get('phoneNumber').enable();
			this.fg.get('countryCode').enable();
		}else{
			this.fg.get('email').enable();
			this.fg.get('phoneNumber').disable();
			this.fg.get('countryCode').disable();
		}
	}

	ngOnDestroy(): void {
		if(this.singinMethod$) this.singinMethod$.unsubscribe();
	}

	logIn(): void {
		if(this.zalogowany) return;
		this.authService.login(this.fg.value)
			.subscribe(x => {
						this.authService.pamietamyhaslo = '';
						this.authService.rememberPhone = '';
						this.authService.rememberCountryCode = '';
						this.authService.rememberEmail = '';
						this.zalogowany = true;
						this.state.dispatch(getCbbStateAction({cognitoUsername: x.cognitoUsername}));
						this.authService.changeView('profile');
						this.router.navigate(['/console'])
			})
	}

	logout() {
		this.authService.logout();
	}
}
