<div class="card" *ngIf="inbox.length >= 0">

	<h5 class="text-2xl py-5" translate>Skrzynka wiadomości</h5>
	<button (click)="newPanel = true" pButton pRipple type="button" [label]="nowawiadomosc" class="p-button-outlined p-button-rounded mb-5"></button>

	<p-table [value]="inbox" [paginator]="true" [rows]="rows" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
		<ng-template pTemplate="header">
			<tr>
				<th style="width:25%" translate>wysłano</th>
				<th style="width:25%" translate>nadawca</th>
				<th style="width:25%" translate>odbiorca</th>
				<th style="width:25%" translate>temat</th>
				<th style="width:25%" translate>status</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-inbox>
			<tr (click)="showModalDialog(inbox)" class="curosr-pointer" [ngClass]="{'newm':inbox.status == 'new'}">
				<td class="curosr-pointer">{{inbox.date | date:'yyyy-MM-dd hh:mm' }}</td>
				<td class="curosr-pointer">{{inbox.sender}}</td>
				<td class="curosr-pointer">{{inbox.receiver}}</td>
				<td class="curosr-pointer">{{inbox.title}}</td>
				<td class="curosr-pointer">{{inbox.status}}</td>
			</tr>
		</ng-template>
	</p-table>
</div>


<ng-container *ngIf="wybranyInbox">
	<p-dialog [showHeader]="true" [(visible)]="visibleModal" [modal]="true" [style]="{'width': '70%', 'height': '75vh', 'max-height': '95vh', 'display':'flex', 'flex-direction': 'column'}">
		<p-panel [header]="wybranyInbox.title" styleClass="pb-3">
			<div class="min-h-[150px]">
				{{wybranyInbox.content}}
			</div>
		</p-panel>
		<h2 translate>Odpowiedz:</h2>
		<p-panel header="Napisz odpowiedź" styleClass="pb-3 min-h-[200px]">
			<textarea rows="5" class="min-h-[150px]" [(ngModel)]="odpowiedz" pInputTextarea></textarea>
		</p-panel>
		<button (click)="reply()" pButton pRipple type="button" [label]="Wyslij" class="p-button-outlined p-button-rounded"></button>
	</p-dialog>
</ng-container>

<p-dialog [showHeader]="true" [(visible)]="newPanel" [modal]="true" [style]="{'width': '70%', 'height': '75vh', 'max-height': '95vh', 'display':'flex', 'flex-direction': 'column'}">
	
	<!-- input kontrolka zmienna title -->
	<h2 translate>Tytuł:</h2>
	<input type="text" [(ngModel)]="title" pInputText class="border border-2xl border-black p-2 w-1/2" />

	<h2 translate>Odpowiedz:</h2>
	<p-panel header="Napisz odpowiedź" styleClass="pb-3 min-h-[200px]">
		<textarea rows="5" class="min-h-[150px]" [(ngModel)]="newContent" pInputTextarea></textarea>
	</p-panel>
	<button (click)="newSend()" pButton pRipple type="button" [label]="Wyslij" class="p-button-outlined p-button-rounded"></button>
</p-dialog>