<p-card>
	<app-info slug="kwestionariusz"></app-info>
</p-card>


<ng-container *ngIf="form">
	<form class="w-full my-6" [formGroup]="form" (ngSubmit)="send()">
		<div class="formwrapper mt-6">

			<div class="formwrapperrow33x"></div>

			<div class="formwrapperrow33x">
				<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
			</div>
			<div class="formwrapperrow33x">
				<!-- <button (click)="turn('on')" *ngIf="disabled" pButton label="Edytuj" icon="pi pi-user-edit"></button> -->
				<button (click)="reset()" *ngIf="!disabled" pButton [label]="Anuluj" icon="pi pi-undo"></button>
			</div>

			
			<div class="formwrappermessage">
				<h1 translate>Podstawowe dane osobowe.</h1>
			</div>

			<div class="formwrapperrow33">
				<label for="pos_4_name" translate>Nazwisko:</label>
				<input type="text" id="pos_4_name" pInputText formControlName="pos_4_name" [ngClass]="{'dirtyinput': form.controls.pos_4_name.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_4_vorname" translate>Imię / Imiona:</label>
				<input type="text" id="pos_4_vorname" pInputText formControlName="pos_4_vorname" [ngClass]="{'dirtyinput': form.controls.pos_4_vorname.invalid}">
			</div>

			<div class="formwrapperrow33">
				<label for="pos_6_geburtsdatum" translate>Data urodzenia: [dd.mm.rrrr]</label>
				<p-inputMask mask="99.99.9999" type="text" id="pos_6_geburtsdatum" pInputText formControlName="pos_6_geburtsdatum" [ngClass]="{'dirtyinput': form.controls.pos_6_geburtsdatum.invalid}"></p-inputMask>
			</div>
		
			<div class="formwrapperrow50">
				<label for="pos_7_strabe" translate>Ulica zamieszkania:</label>
				<input type="text" id="pos_7_strabe" pInputText formControlName="pos_7_strabe" [ngClass]="{'dirtyinput': form.controls.pos_7_strabe.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_8_hausnummer" translate>Nr domu:</label>
				<input type="text" id="pos_8_hausnummer" pInputText formControlName="pos_8_hausnummer" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummer.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_8_hausnummerzusats" translate>Nr mieszkania:</label>
				<input type="text" id="pos_8_hausnummerzusats" pInputText formControlName="pos_8_hausnummerzusats" [ngClass]="{'dirtyinput': form.controls.pos_8_hausnummerzusats.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_9_postleitzahl" translate>Kod pocztowy PNA:</label>
				<input type="text" id="pos_9_postleitzahl" pInputText formControlName="pos_9_postleitzahl" [ngClass]="{'dirtyinput': form.controls.pos_9_postleitzahl.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="pos_9_wohnort" translate>Miejscowoć:</label>
				<input type="text" id="pos_9_wohnort" pInputText formControlName="pos_9_wohnort" [ngClass]="{'dirtyinput': form.controls.pos_9_wohnort.invalid}">
			</div>

			<div class="formwrapperrow25">
				<label for="land" translate>Kraj:</label>
				<input type="text" id="land" pInputText formControlName="land" [ngClass]="{'dirtyinput': form.controls.land.invalid}">
			</div>

			<div class="formwrapperrow25"></div>

			<div class="formwrappermessage">
				<h1 translate>Dane dotyczące firmy:</h1>
			</div>

			<!-- zoo_3_nazwa_firmy -->
			<div class="formwrapperrow50">
				<label for="zoo_3_nazwa_firmy" translate>Nazwa firmy:</label>
				<input type="text" id="zoo_3_nazwa_firmy" pInputText formControlName="zoo_3_nazwa_firmy" [ngClass]="{'dirtyinput': form.controls.zoo_3_nazwa_firmy.invalid}">
			</div>

			<div class="formwrapperrow25"></div>

			<!-- zoo_12_telefon -->
			<div class="formwrapperrow33">
				<label for="zoo_12_telefon" translate>Numer telefonu:</label>
				<input type="text" id="zoo_12_telefon" pInputText formControlName="zoo_12_telefon" [ngClass]="{'dirtyinput': form.controls.zoo_12_telefon.invalid}">
			</div>

			<!-- zoo_19_www_w_pl -->
			<div class="formwrapperrow33">
				<label for="zoo_14_www_w_pl" translate>Strona WWW:</label>
				<input type="text" id="zoo_14_www_w_pl" pInputText formControlName="zoo_14_www_w_pl" [ngClass]="{'dirtyinput': form.controls.zoo_14_www_w_pl.invalid}">
			</div>

			<!-- zoo_13_mail -->
			<div class="formwrapperrow25">
				<label for="zoo_13_mail" translate>Mail spółki:</label>
				<input type="text" id="zoo_13_mail" pInputText formControlName="zoo_13_mail" [ngClass]="{'dirtyinput': form.controls.zoo_13_mail.invalid}">
			</div>

			<!-- zoo_4_ul_spolki -->
			<div class="formwrapperrow50">
				<label for="zoo_4_ul_spolki" translate>Ulica spółki:</label>
				<input type="text" id="zoo_4_ul_spolki" pInputText formControlName="zoo_4_ul_spolki" [ngClass]="{'dirtyinput': form.controls.zoo_4_ul_spolki.invalid}">
			</div>

			<!-- zoo_5_nr_domu_spolki -->
			<div class="formwrapperrow25">
				<label for="zoo_5_nr_domu_spolki" translate>Numer domu:</label>
				<input type="text" id="zoo_5_nr_domu_spolki" pInputText formControlName="zoo_5_nr_domu_spolki" [ngClass]="{'dirtyinput': form.controls.zoo_5_nr_domu_spolki.invalid}">
			</div>

			<!-- zoo_5_nr_lokal_spolki -->
			<div class="formwrapperrow25">
				<label for="zoo_5_nr_lokal_spolki" translate>Numer lokalu:</label>
				<input type="text" id="zoo_5_nr_lokal_spolki" pInputText formControlName="zoo_5_nr_lokal_spolki" [ngClass]="{'dirtyinput': form.controls.zoo_5_nr_lokal_spolki.invalid}">
			</div>

			<!-- zoo_6_kod_pocztowy -->
			<div class="formwrapperrow25">
				<label for="zoo_6_kod_pocztowy" translate>Kod pocztowy:</label>
				<input type="text" id="zoo_6_kod_pocztowy" pInputText formControlName="zoo_6_kod_pocztowy" [ngClass]="{'dirtyinput': form.controls.zoo_6_kod_pocztowy.invalid}">
			</div>

			<!-- zoo_6_miejscowosc_spolki -->
			<div class="formwrapperrow50">
				<label for="zoo_6_miejscowosc_spolki" translate>Miejscowość:</label>
				<input type="text" id="zoo_6_miejscowosc_spolki" pInputText formControlName="zoo_6_miejscowosc_spolki" [ngClass]="{'dirtyinput': form.controls.zoo_6_miejscowosc_spolki.invalid}">
			</div>

			<div class="formwrappermessage">
				<h1 translate>Informacje do rozliczenia podatku</h1>
				<p translate>Informacje niezbędne do rozliczenia podatku dochodowego od osób fizycznych, w tym dane małżonka/małżonki</p>
			</div>

			<div class="formwrapperrow25">
				<label for="nip" translate>NIP:</label>
				<input type="text" id="nip" pInputText formControlName="nip" [ngClass]="{'dirtyinput': form.controls.nip.invalid}">
			</div>


			<div class="formwrapperrow25">
				<label for="zoo_62_swift" translate>numer SWIFT</label>
				<input type="text" id="zoo_62_swift" pInputText formControlName="zoo_62_swift" [ngClass]="{'dirtyinput': form.controls.zoo_62_swift.invalid}">
			</div>

			<div class="formwrapperrow50">
				<label for="zoo_61_iban_konto" translate>Numer konta bankowego</label>
				<input type="text" id="zoo_61_iban_konto" pInputText formControlName="zoo_61_iban_konto" [ngClass]="{'dirtyinput': form.controls.zoo_61_iban_konto.invalid}">
			</div>


			<div class="formwrappermessage w-full flex flex-col justify-center">
				<h1 translate>Informacje o wspólnikach</h1>
				<div translate>Uzupełnij dane wspólników spółki</div>
				<div class="mess" *ngIf="!jestWspolnik" translate>Dodanie wspólnika lub właściciela jest obowiązkowe</div>
				<div class="w-full flex justify-center"><button [disabled]="disabled" class="p-element btn btn-blue-200 text-2xl align-center p-button p-component p-5" pButton (click)="dodajWspolnika(); $event.preventDefault();" translate>Dodaj wspólnika</button></div>
			</div>

			<ng-container formArrayName="udzialowcy" *ngFor="let udzialowiec of udzialowcy.controls; let i=index;">

				<div class="formwrappermessage2" translate>
					<div><span translate>Informacje dotyczące wspólnika nr</span> {{i+1}}</div>
					<button [disabled]="disabled" class="btn btn-blue-200 ml-auto" pButton (click)="usunWspolnika(i); $event.preventDefault();">usuń</button>
				</div>

				<!-- kontrolka zoo_82_firma -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_82_firma_'+i" translate>Firma:</label>
					<input type="text" [id]="'zoo_82_firma_'+i" formControlName="zoo_82_firma" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_82_firma.invalid}">
				</div>

				<!-- kontrolka zoo_83_imie -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_83_imie'+i" translate>Imię:</label>
					<input type="text" [id]="'zoo_83_imie_'+i" formControlName="zoo_83_imie" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_83_imie.invalid}">
				</div>

				<!-- kontrolka zoo_83_nazwisko -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_83_nazwisko_'+i" translate>Nazwisko:</label>
					<input type="text" [id]="'zoo_83_nazwisko_'+i" formControlName="zoo_83_nazwisko" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_83_nazwisko.invalid}">
				</div>

				<!-- kontrolka zoo_84_ulica -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_84_ulica_'+i" translate>Ulica:</label>
					<input type="text" [id]="'zoo_84_ulica_'+i" formControlName="zoo_84_ulica" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_84_ulica.invalid}">
				</div>

				<!-- kontrolka zoo_85_nr_domu -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_85_nr_domu_'+i" translate>Nr domu:</label>
					<input type="text" [id]="'zoo_85_nr_domu_'+i" formControlName="zoo_85_nr_domu" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_85_nr_domu.invalid}">
				</div>

				<!-- kontrolka zoo_86_nr_lokalu -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_86_nr_lokalu_'+i" translate>Nr lokalu:</label>
					<input type="text" [id]="'zoo_86_nr_lokalu_'+i" formControlName="zoo_86_nr_lokalu" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_86_nr_lokalu.invalid}">
				</div>

				<!-- kontrolka zoo_87_data_urodzenia -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_87_data_urodzenia_'+i" translate>Data urodzenia:</label>
					<p-inputMask mask="99.99.9999" type="text" [id]="'zoo_87_data_urodzenia_'+i" formControlName="zoo_87_data_urodzenia" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_87_data_urodzenia.invalid}"></p-inputMask>
				</div>

				<!-- kontrolka zoo_89_urzad_skarbowy -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_89_urzad_skarbowy_'+i" translate>Urzad skarbowy:</label>
					<input type="text" [id]="'zoo_89_urzad_skarbowy_'+i" formControlName="zoo_89_urzad_skarbowy" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_89_urzad_skarbowy.invalid}">
				</div>

				<!-- kontrolka zoo_90_nip -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_90_nip_'+i" translate>NIP:</label>
					<input type="text" [id]="'zoo_90_nip_'+i" formControlName="zoo_90_nip" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_90_nip.invalid}">
				</div>

				<!-- kontrolka zoo_91_kwota_udzialu -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_91_kwota_udzialu_'+i" translate>Kwota udzialu:</label>
					<input type="text" [id]="'zoo_91_kwota_udzialu_'+i" formControlName="zoo_91_kwota_udzialu" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_91_kwota_udzialu.invalid}">
				</div>

				<!-- kontrolka zoo_91_procent_udzialu -->
				<div class="formwrapperrow25" [formGroupName]="i">
					<label [for]="'zoo_91_procent_udzialu_'+i" translate>Procent udzialu:</label>
					<input type="text" [id]="'zoo_91_procent_udzialu_'+i" formControlName="zoo_91_procent_udzialu" [ngClass]="{'dirtyinput': udzialowiec.controls.zoo_91_procent_udzialu.invalid}">
				</div>

			</ng-container>




			<div class="formwrappermessagex">
				<h1 translate>Zgody RODO</h1>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_akceptacja_regulaminu" [binary]="true" inputId="rodo_akceptacja_regulaminu" [ngClass]="{'dirtyinput': form.controls.rodo_akceptacja_regulaminu.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate><span translate>Akceptuję</span> <a class="underline" [routerLink]="['/regulamin']" translate>regulamin</a><span translate>(POLE OBOWIĄZKOWE)</span></p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_przetwarzanie_danych" [binary]="true" inputId="rodo_przetwarzanie_danych" [ngClass]="{'dirtyinput': form.controls.rodo_przetwarzanie_danych.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie danych szczególnych kategoriizawartych w przesłanych dokumentach w celu dokonania wyceny oraz wykonania usługi. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposów uniemożliwiający nam przetwarzenia danych szczególnych kategorii (POLE OBOWIĄZKOWE)</p>
				</div>
			</div>
			<div class="flex flex-nowrap w-full">
				<div class="flex items-start justify-left w-50 pr-4">
					<p-checkbox formControlName="rodo_dane_marketingowe" [binary]="true" inputId="rodo_dane_marketingowe" [ngClass]="{'dirtyinput': form.controls.rodo_dane_marketingowe.invalid}"></p-checkbox>
				</div>
				<div class="flex items-start flex-grow">
					<p translate>Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymania na ten adres email materiałów ofertowych dotyczących usług oferowanych przez CBB-Office GmbH</p>
				</div>
			</div>




		</div>


		<div class="formwrapperrow">
			<button *ngIf="!disabled" pButton [label]="Zapisz" icon="pi pi-check"></button>
		</div>

	</form>
</ng-container>



<!-- <pre>
	brakuje:
</pre> -->