import { MongoService } from './../app/mongo.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from, Observable, of } from 'rxjs';
import { map, mergeMap, catchError, tap, withLatestFrom, filter, flatMap } from 'rxjs/operators';
import { CbbState } from '../cbbmodels/cbbstate';
import { Store } from '@ngrx/store';
import { UserConfig } from '../cbbmodels/user-config';
import { CbbActionType } from './cbbactiontypes';

@Injectable()
export class CBBEffects {

	constructor(
		private actions$: Actions,
		private mongoService: MongoService,
		private state$: Store<CbbState>
	) { }

	// ZAPISUJE CAŁOŚĆ STANU
	setUserConfig$ = createEffect(
		() => this.actions$.pipe(
			ofType(
				CbbActionType.setUserConfig,
				CbbActionType.setReport,
				CbbActionType.sendReport
			),
			withLatestFrom(this.state$),
			flatMap(([moduly, cbbState]) => {
				return this.mongoService.saveCbbState(cbbState)
				.pipe(
					map(cbbState => ({ type: CbbActionType.getCbbStateSuccess, cbbState })),
					catchError(() => EMPTY)																											//TODO: obsłużyć błędy
				)
			}
			)
			// tap((x) => console.log('EFEKT', x))
		),
		{ dispatch: false }
	);

	// POBIERA CAŁOŚĆ STANU
	getCbbState$ = createEffect(() => this.actions$.pipe(
		ofType(CbbActionType.getCbbState),
		mergeMap((payload) => this.mongoService.getCbbStateByCognitoUserName(payload)
			.pipe(
				map(cbbState => ({ type: CbbActionType.getCbbStateSuccess, cbbState })),
				catchError(() => EMPTY)																											//TODO: obsłużyć błędy
			))
	));

	// POBIERA PLIKI
	getCbbFilesState$ = createEffect(() => this.actions$.pipe(
		ofType(CbbActionType.getCbbFilesState),
		mergeMap((payload) => this.mongoService.getCbbStateFilesByCognitoUsername(payload)
			.pipe(
				map(files => ({ type: CbbActionType.getCbbFilesStateSuccess, files })),
				catchError(() => EMPTY)																											//TODO: obsłużyć błędy
			))
	));

	// GENERUJE I ZAPISUJE PLIK PDF Z REPORTERA
	prepareDokFromReportAndGeneratePDFEffect$ = createEffect(
		() => this.actions$.pipe(
			ofType(CbbActionType.prepareDokFromReportAndGeneratePDF),
			mergeMap((payload) => {
				return this.mongoService.prepareDokFromReportAndGeneratePDF(payload)
					.pipe(
						map(files => ({ type: CbbActionType.getCbbFilesStateSuccess, files })),
						catchError((err) => {
							// console.log('prepareDokFromReportAndGeneratePDF PORAŻKA - TODO: zwróć taką akcje !!!!', err);
							return EMPTY;																																							//TODO: obsłużyć błędy
						})
					)
			}))
	);

	// PODPISUJE PLIK PDF I ZWRCA PNG
	signDokFromReportEffect$ = createEffect(
		() => this.actions$.pipe(
			ofType(CbbActionType.signDokFromReport),
			mergeMap((payload) => {
				return this.mongoService.signDokFromReport(payload)
					.pipe(
						map(files => ({ type: CbbActionType.signDokFromReportSuccess, files })),
						catchError((err) => {
							// console.log('signDokFromReport PORAŻKA - TODO: zwróć taką akcje !!!!', err);
							return EMPTY;																																							//TODO: obsłużyć błędy
						})
					)
			}))
	);

	// GENERUJE I PODPISUJE AUTOMATYCZNIE
	autopodpisEffect$ = createEffect(
		() => this.actions$.pipe(
			ofType(CbbActionType.autopodpis),
			mergeMap((payload) => {
				return this.mongoService.autopodpis(payload)
					.pipe(
						map(files => ({ type: CbbActionType.signDokFromReportSuccess, files })),
						catchError((err) => {
							// console.log('autopodpis PORAŻKA - TODO: zwróć taką akcje !!!!', err);
							return EMPTY;																																							//TODO: obsłużyć błędy
						})
					)
			}))
	);

	// WYSYŁA MAILA DO KLIENTA Z RAPORTEM
	sendReportEffect$ = createEffect(
		() => this.actions$.pipe(
			ofType(CbbActionType.sendEmailToClient),
			mergeMap((payload) => {
				return this.mongoService.sendEmailToClient(payload)
					.pipe(
						map(files => ({ type: CbbActionType.sendEmailToClientSuccess, files })),
						catchError((err) => {
							// console.log('sendEmailToClient PORAŻKA - TODO: zwróć taką akcje !!!!', err);
							return EMPTY;																																							//TODO: obsłużyć błędy
						})
					)
			}))
	);

}