import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { File } from '../../cbbmodels/file';
// import * as html2canvas from 'html2canvas';
import html2canvas from 'html2canvas';

// Podpis:  https://www.npmjs.com/package/@almothafar/angular-signature-pad
// API takie jak:   https://www.npmjs.com/package/signature_pad

export class Osrc {
	constructor(uri:string, cors:boolean) {
		this.url = uri;
		this.withCredentials = cors;
	}
	url: string;
	withCredentials: boolean;
}


@Component({
	selector: 'app-doctosign',
	templateUrl: './doctosign.component.html',
	styleUrls: ['./doctosign.component.scss']
})
export class DoctosignComponent {

	@Input() fileToSign: File;
	@Output('onendsign') onEndSign = new EventEmitter<string>();
	@Output('cancel') onCancel = new EventEmitter<string>();
	@Output('signed') onSigned = new EventEmitter<string>();
	@ViewChild('signature')
  public signaturePad: SignaturePadComponent;

	@ViewChild('myCanvas') myCanvas: ElementRef<HTMLCanvasElement>;
	
	path: string = '';
	html: string = '';
	uri: string = '';
	progressValue: number = 0;
	osrc: Osrc;
	// visibleModal2:boolean = false;
	bearerToken:string = 'ssss';
	podpistryb:boolean = false;
	podpis:string = '';
	plikpodpisanyzklawiatury:string = '';
	
	public signaturePadOptions: NgSignaturePadOptions = { // opcje: https://github.com/szimek/signature_pad
    minWidth: 40,
    canvasWidth: 500,
    canvasHeight: 100,
		backgroundColor: 'rgba(0,0,0,0)',
		penColor: 'blue',
		dotSize: 1,
  };

	constructor(
	) { }

	ngOnChanges(changes: SimpleChanges) {
		if(this.signaturePad) this.signaturePad.clear();				//TODO: !!! Wdrożyć zabezpieczenie, żeby się nie dało pobrać nie swojego
		this.progressValue = 0;
		if(!changes.fileToSign.currentValue) return;
		if(!changes.fileToSign.currentValue.fileName) return;
		this.uri = environment.ApiUrl + '/document/pdf/getfile?filename=' + changes.fileToSign.currentValue.fileName + '&cognitoUsername=' + changes.fileToSign.currentValue.cognitoUsername;
		this.osrc = new Osrc(this.uri, false);
	}

	ngAfterViewInit() {
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

	onProgress($event) {
		// this.progressValue = ($event.loaded / $event.total) * 100.00;
		this.progressValue = 100;
	}

	modalPodpisz(){
		// this.visibleModal2 = true;
	}

	drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
		if(!this.signaturePad.isEmpty()) {
			// this.visibleModal2 = false;
			this.onEndSign.next(this.signaturePad.toDataURL());
		} else {
		}
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
  }

	cancel() {
		this.onCancel.next('cancel');
	}

	async podpisklaw() {

		const divElement = document.querySelector('.fanpodpis') as HTMLElement;

    try {
      const canvas = await html2canvas(divElement);
      const imgDataUrl = await canvas.toDataURL('image/png'); // Zmień 'image/png' na 'image/jpeg', jeśli chcesz obraz w formacie JPEG.
			this.plikpodpisanyzklawiatury = imgDataUrl;
    } catch (error) {
      console.error('Błąd podczas renderowania grafiki:', error);
    }

  }

	signed() {

		if(!this.podpistryb) {
			if(!this.signaturePad.isEmpty()) {
				this.onSigned.next('signed');
			} else {
				alert('Podpisz dokument');
			}
		} else {
			setTimeout(() => {
				this.onEndSign.next(this.plikpodpisanyzklawiatury);
				this.onSigned.next('signed');
			}, 1000);
		}

	}
}
