import { CbbformService } from './../cbbform.service';
import { ApperanceService } from './../apperance.service';
import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-article',
	templateUrl: './article.component.html',
	styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {

	@Input() wariant: string = 'prosty';
	@Input() artykul: string = '';
	@Input() headerClass: string = 'headerArticleClass';
	@Input() textClass: string = 'textArticleClass';
	@Input() dividerClass: string = 'dividerArticleClass';

	slug: string = '';
	artykul$;
	aSub;
	lSub;
	contentArt;
	strapiUrl: string = '';
	przejdzDalej: string = 'Przejdź dalej';

	constructor(
		private apperanceService: ApperanceService,
		private cbbform: CbbformService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.strapiUrl = environment.StrapiUrl;
		this.lSub = this.apperanceService.currentLanguage$
			.subscribe(x => {
				this.pobierzArtykul()
			})

		this.przejdzDalej = this.translate.instant('Przejdź dalej');
	}

	pobierzArtykul() {
		if (!this.slug) return;
		this.aSub = this.apperanceService.getStaticArticleBySlug(this.slug)
			.subscribe(art => {
				this.contentArt = art;

				
				if (!this.contentArt || this.contentArt.data.length == 0) {
					this.contentArt = {
						data: [{
							attributes: {
								tytul: 'Uzupełnij artykuł dla języka: ' + this.apperanceService.currentLanguage$.value,
								tresc: 'Brak artykułu'
							}
						}]
					}
				}
			})
	}

	ngOnChanges(changes: SimpleChanges) {
		this.slug = changes.artykul.currentValue;
		this.pobierzArtykul();
	}

	ngOnDestroy(): void {
		if (this.aSub) this.aSub.unsubscribe();
		if (this.lSub) this.lSub.unsubscribe();
	}

	gotoumowa() {
		this.cbbform.idzdoumowy();
	}
}


