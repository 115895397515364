import { CbbformService } from './../../cbbform.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CbbState } from '../../../cbbmodels/cbbstate';
import { getEditedReport, getUserProfile } from '../../../cbboffice-rx-store/selectors';
import { Report } from '../../../cbbmodels/report';
import { map, mergeMap } from 'rxjs';
import { UserConfig } from '../../../cbbmodels/user-config';
import { Field } from '../../../cbbmodels/field';
import { cancellAllFileByReport, setReport } from '../../../cbboffice-rx-store/cbbactions';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-gewerbe',
	templateUrl: './gewerbe.component.html',
	styleUrls: ['./gewerbe.component.scss']
})
export class GewerbeComponent implements OnInit, OnDestroy {

	editedReport: Report;
	storeOk: boolean = false;
	subUser$
	SubMod;
	form: FormGroup
	disabled: boolean = true;
	jestformularz: boolean = false;
	user: UserConfig;
	formularzPrzetwarzany: boolean = false;
	Zapisz: string;
	Anuluj: string;
	religie: any[] = [];
	wybierz:string;
	
	constructor(
		private fb: FormBuilder,
		private router: Router,
		private state: Store<CbbState>,
		private cbbform: CbbformService,
		private translate: TranslateService
	) {
		this.form = this.cbbform.getForm('gewerbeOgolna');
		this.Zapisz = this.translate.instant('Zapisz');
		this.Anuluj = this.translate.instant('Anuluj');
		let lang = this.translate.currentLang;
		this.religie = this.cbbform.getReligie(lang)
		this.wybierz = this.translate.instant('wybierz');
	}

	ngOnInit(): void {
		this.storeOk = false;

		this.subUser$ = this.state.select(getUserProfile())
		this.SubMod = this.state.select(getEditedReport())
			.pipe(
				mergeMap(rep => {
					return this.subUser$.pipe(
						map(user => { return { user: user, report: rep } })
					)
				})
			)
			.subscribe((wsad: { user, report }) => {
				if (!this.formularzPrzetwarzany) {
					if (wsad.report?.reportName == '') { this.router.navigate(['console/documents']); return; }
					this.editedReport = wsad.report;

					if (this.editedReport.status == 'signed' || this.editedReport.status == 'archive' || this.editedReport.status == 'confirmed') {
						this.turn('off');
					} else this.turn('on');

					this.user = wsad.user;
					this.pobierzDane()
				}
			})
	}

	pobierzDane() {
		this.form = this.cbbform.setDefaultFormValue(this.form, this.user);
		this.editedReport.fields.forEach(f => {
			this.form.get(f.fieldName)?.setValue((f.fieldValue == 'true') ? true : f.fieldValue);
		});

		this.form.get('pos_6_geburtsdatum')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_6_geburtsdatum')?.value));
		this.form.get('pkt_11_dzialalnosc_od')?.setValue(this.cbbform.zepsujDate(this.form.get('pkt_11_dzialalnosc_od')?.value));
		this.form.get('pos_12_verheiratet')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_12_verheiratet')?.value));
		this.form.get('pos_12_verwitwet')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_12_verwitwet')?.value));
		this.form.get('pos_16_geburtsdatum')?.setValue(this.cbbform.zepsujDate(this.form.get('pos_16_geburtsdatum')?.value));

		this.isValid();
	}

	ngOnDestroy(): void {
		if (this.SubMod) this.SubMod.unsubscribe();
	}

	turn(swit: string) {
		switch (swit) {
			case 'on':
				if (this?.editedReport?.status == 'edited' || this.editedReport.status == 'new') {
					this.form.enable();
					this.disabled = false;
					this.formularzPrzetwarzany = false;
				} else {
					console.log('Nie można edytować', this.editedReport.status);
				}
				return;
			case 'off':
				this.form.disable();
				this.disabled = true;
				return;
		}
	}

	reset() {
		this.form.reset();
		this.pobierzDane();
		this.formularzPrzetwarzany = false;
	}

	setValue() {
	}

	zdublowanepole(zktorego:string, doktorego:string) {
		this.form.get(doktorego)?.setValue(this.form.get(zktorego)?.value);
		let test = this.form.get(doktorego)?.value;
	}

	zgody: string = '';
	kontakt: string = '';
	isValid() {		

		this.cbbform.czyMonzaIscDalej(false);
		if(!this.form.valid) return false;

		this.zgody = '';
		this.kontakt = '';

		//dodatkowe walidacje
		let z1 = this.form.get('zgoda_na_przystapienie_do_cbboffice_pole_1')?.value ?? false;
		let z2 = this.form.get('zgoda_na_przystapienie_do_cbboffice_pole_2')?.value ?? false;
		if (!z1) z1 = false;
		if (!z2) z2 = false;

		// musi być podane jedno z pól pkt_8_email lub pkt_9_telefon
		let email = this.form.get('pkt_8_email')?.value ?? '';
		let telefon = this.form.get('pkt_9_telefon')?.value ?? '';
		if (email == '' && telefon == '') {
			this.kontakt = 'Podaj email lub telefon';
			return false;
		}


		if (z1 === z2) {
			this.zgody = this.translate.instant('Jedną ze zgód należy wybrać');
			return false;
		}

		this.cbbform.czyMonzaIscDalej(true);
		return true;
	}

	send() {
		this.formularzPrzetwarzany = true;
		let fields: Field[] = [];
		for (let [key, value] of Object.entries(this.form.getRawValue())) {
			if(!value) value = '';
			
			// powielenie powtarzających się pól, które są potrzebne do wygenerowania raportu ale nie ma ich w formularzu
			if (key == 'pkt_10_co_bedzie_wykonywane') fields.push({ fieldName: 'pos_1_finanzamt', fieldValue: value.toString(), children: [] });
			
			let normalnePole:boolean = true;
			// zmiana formatu daty
			if(key == 'pos_6_geburtsdatum' || key == 'pkt_11_dzialalnosc_od' || key == 'pos_12_verheiratet' || key == 'pos_12_verwitwet' || key == 'pos_16_geburtsdatum')
			{
				normalnePole = false;
				fields.push({ fieldName: key, fieldValue: this.cbbform.naprawDate(value.toString()), children: [] });
			}
			
			if(normalnePole) fields.push({ fieldName: key, fieldValue: value.toString(), children: [] });
		}
		if (this?.editedReport?.status == 'new' || this?.editedReport?.status == 'edited') {
			this.editedReport = { ...this.editedReport, fields: fields, status: 'edited' };
			this.state.dispatch(cancellAllFileByReport({ rep: this.editedReport }));
			this.state.dispatch(setReport({ rep: this.editedReport }));

			if (this.isValid()) {
				this.cbbform.idzdalej();
			}
		}
		// this.turn('off');
	}
}
