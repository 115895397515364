import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, first, map, tap } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApperanceService implements OnInit {

	apiUrl: string;
	tokenJWT: string | null;
	hdr: any;
	hdrpub: any;
	lang: string;

	public currentLanguage$ = new BehaviorSubject('pl');

	constructor(
		private http: HttpClient
	) {
		this.apiUrl = environment.StrapiUrl;
		this.tokenJWT = environment.StrapiToken;
	}

	changeLocale(lang: string) {
		this.lang = lang;
		localStorage.setItem('cbblang', this.lang);
		this.currentLanguage$.next(this.lang)
	}

	ngOnInit(): void {
		this.getHdr();
	}

	getHdr() {
		this.hdr = {
			'Content-Type': 'application/json',
			'accept': '*/*',
			'Authorization': `Bearer ${this.tokenJWT}`
		};
	}

	getPublicHdr() {
		this.hdrpub = {
			'Content-Type': 'application/json',
			'accept': '*/*'
		};
	}

	getStaticArticleBySlug(slug: string) {
		this.getHdr()

		let lang:string = this.lang;
		if(lang == 'cz') lang = 'cs';
		if(lang == 'ua') lang = 'uk-UA';

		return this.http.get(`${this.apiUrl}/api/artykuly-statycznes?filters[slug][$eqi]=${slug}&locale=${lang}&populate=*`, { headers: this.hdr })
	}

	getStopki() {
		this.getPublicHdr()
		return this.http.get(`${this.apiUrl}/api/stopkas`, { headers: this.hdrpub })
			.pipe(
				map((res: any) => {
					return res.data.map((item: any) => ({
						id: item.id,
						tytul: item.attributes.tytul,
						tresc: item.attributes.tresc,
						slug: item.attributes.slug,
						locale: item.attributes.locale,
						createdAt: item.attributes.createdAt,
						updatedAt: item.attributes.updatedAt
					}));
				})
			)
	}

	getZalety() {
		this.getPublicHdr()
		return this.http.get(`${this.apiUrl}/api/zaleties`, { headers: this.hdrpub })
			.pipe(
				map((res: any) => {
					return res.data.map((item: any) => ({
						id: item.id,
						tytul: item.attributes.tytul,
						tresc: item.attributes.tresc,
						slug: item.attributes.slug,
						locale: item.attributes.locale,
						ikona: item.attributes.ikona,
					}));
				})
			)
	}

	getStopkaBySlug(slug: string) {
		this.getPublicHdr()

		let lang:string = this.lang;
		if(lang == 'cz') lang = 'cs';
		if(lang == 'ua') lang = 'uk-UA';

		console.log('biore', this.apiUrl, slug, lang);
		return this.http.get(`${this.apiUrl}/api/stopkas?filters[slug][$eqi]=${slug}&locale=${lang}&populate=*`, { headers: this.hdrpub })
	}

	getInfoBySlug(slug: string) {
		let lang:string = this.lang;
		if(lang == 'cz') lang = 'cs';
		if(lang == 'ua') lang = 'uk-UA';

		this.getHdr()
		return this.http.get(`${this.apiUrl}/api/static-infos?filters[slug][$eqi]=${slug}&locale=${lang}&populate=*`, { headers: this.hdr })
			.pipe(
				map((res: any) => {
					return res?.data[0]?.attributes?.content || 'uzupełnij artykuł "'+slug+'" w CMS dla języka: '+lang;
				})
			)
	}

	getNumeryKierunkowe() {
		this.getHdr()
		return this.http.get(`${this.apiUrl}/api/numery-kierunkowes`)
			.pipe(
				map((res: any) => {
					return res.data.map((item: any) => ({
						label: item.attributes.kraj,
						value: item.attributes.kierunkowy
					}));
				}),	
			)
	}
}
